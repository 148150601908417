// import { authHeader } from '../_helpers';
import { mainConfig } from "../config/mainConfig";
import { authHeader, handleResponse } from "./auth.header";

export const scoreService = {
    getScore,
    importScore,
    editScore,
    deleteScore,
    getStatusImport,
    viewStatusImport
};

function getScore(page, limit, search, categoryId, phaseId, userId) {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${mainConfig.apiUrl}/score?page=${page}&per_page=${limit}&search=${search}&category_id=${categoryId}&phase_id=${phaseId}&user_id=${userId}`,
    reqOpt
  ).then(handleResponse);
}
function importScore(data) {
  const reqOpt = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${mainConfig.apiUrl}/score/import`, reqOpt).then(
    handleResponse
  );
}
function viewStatusImport(data) {
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${mainConfig.apiUrl}/score/import-status`, reqOpt).then(handleResponse)
}

function getStatusImport(page, limit) {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${mainConfig.apiUrl}/score/import-status?page=${page}&per_page=${limit}`,
    reqOpt
  ).then(handleResponse);
}

function editScore(id, data) {
  const reqOpt = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${mainConfig.apiUrl}/score/${id}`, reqOpt).then(handleResponse);
}
function deleteScore(id) {
  const reqOpt = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/score/${id}`, reqOpt).then(handleResponse);
}
