import { Card, Col, Row, Button, Input } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { announcementService } from "../../../services/announcement.service";
import { participantService } from "../../../services/participant.service";
import { paymenttService } from "../../../services/payment.service";
const { TextArea } = Input;

class Ringkasan extends React.Component {
  state = {
    title: "",
    content: "",
    totalParticipant: 0,
    totalPaid: 0,
    totalWaiting: 0,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ showLoading: true });
    announcementService
      .addAnnouncement({ content: this.state.content, title: this.state.title })
      .then(
        (resp) => {
          toast.success(resp.message, {
            position: toast.POSITION.TOP_CENTER,
            pauseOnFocusLoss: false,
            autoClose: 1000,
          });
          setTimeout(function () {
            window.location.assign("/profile/admin/pengumumanAdmin");
          }, 3000);
          this.setState({ content: "", title: "" });
        },
        (error) => {
          this.setState({ content: "", title: "" });
          toast.error(error, {
            position: toast.POSITION.TOP_CENTER,
            pauseOnFocusLoss: false,
            autoClose: 2000,
          });
        }
      );
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    participantService.getParticipant(1, 1000, -1, "").then(
      (resp) => {
        if (resp.status) {
          this.setState({
            totalParticipant: resp.total,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
    paymenttService.getPayment(1, 1000, "", "paid", "", "").then(
      (resp) => {
        if (resp.status) {
          this.setState({
            totalPaid: resp.total,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
    paymenttService.getPayment(1, 1000, "", "waiting", "", "").then(
      (resp) => {
        if (resp.status) {
          this.setState({
            totalWaiting: resp.total,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
  }

  render() {
    return (
      <React.Fragment>
        <Card
          headStyle
          style={{
            backgroundColor: "#e8e8e8",
            minHeight: window.innerHeight,
            display: "fixed",
          }}
        >
          <Row gutter={[16]}>
            <Col style={{ marginTop: 24 }} xs={24} sm={24} md={24} lg={8}>
              <Card
                style={{
                  borderRadius: "5px",
                  textAlign: "left",
                  height: "100%",
                  overflow: "hidden",
                  paddingLeft: "5%",
                  paddingRight: "5%",
                  marginTop: "3%",
                }}
              >
                <p className="font-normal">Peserta</p>
                <p
                  className="font-large-1 text-bold-700"
                  style={{ textAlign: "center" }}
                >
                  {this.state.totalParticipant}
                </p>

                <Link to="/profile/admin/participants">
                  <Button type="link" style={{ float: "right" }}>
                    Selengkapnya
                  </Button>
                </Link>
              </Card>
            </Col>
            <Col style={{ marginTop: 24 }} xs={24} sm={24} md={24} lg={8}>
              <Card
                style={{
                  borderRadius: "5px",
                  textAlign: "left",
                  height: "100%",
                  overflow: "hidden",
                  paddingLeft: "5%",
                  paddingRight: "5%",
                  marginTop: "3%",
                }}
              >
                <p className="font-normal">Pembayaran Dikonfirmasi</p>
                <p
                  className="font-large-1 text-bold-700"
                  style={{ textAlign: "center" }}
                >
                  {this.state.totalPaid}
                </p>
                <Link to="/profile/admin/paymentAdmin">
                  <Button type="link" style={{ float: "right" }}>
                    Selengkapnya
                  </Button>
                </Link>
              </Card>
            </Col>
            <Col style={{ marginTop: 24 }} xs={24} sm={24} md={24} lg={8}>
              <Card
                style={{
                  borderRadius: "5px",
                  textAlign: "left",
                  height: "100%",
                  overflow: "hidden",
                  paddingLeft: "5%",
                  paddingRight: "5%",
                  marginTop: "3%",
                }}
              >
                <p className="font-normal">Pembayaran Perlu Konfirmasi</p>
                <p
                  className="font-large-1 text-bold-700"
                  style={{ textAlign: "center" }}
                >
                  {this.state.totalWaiting}
                </p>
                <Link to="/profile/admin/paymentAdmin">
                  <Button type="link" style={{ float: "right" }}>
                    Selengkapnya
                  </Button>
                </Link>
              </Card>
            </Col>
          </Row>
          <div style={{ marginTop: 40 }}>
            <div>
              <Card
                style={{
                  borderRadius: "5px",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <Row style={{ marginTop: 16 }} gutter={[16]}>
                  <Col xs={16} sm={19} md={19} lg={19}>
                    <p className="font-medium-1">Pengumuman</p>
                  </Col>
                  <Col xs={8} sm={5} md={5} lg={5}>
                    <Link to="/profile/admin/pengumumanAdmin">
                      <Button type="link">Selengkapnya</Button>
                    </Link>
                  </Col>
                </Row>

                <Input
                  style={{ marginTop: "2%" }}
                  onChange={(e) => this.setState({ title: e.target.value })}
                  value={this.state.title}
                ></Input>
                <TextArea
                  style={{ marginTop: "3%" }}
                  autoSize={{ minRows: 11, maxRows: 10 }}
                  onChange={(e) => this.setState({ content: e.target.value })}
                  value={this.state.content}
                ></TextArea>
                <Button
                  type="primary"
                  style={{ marginTop: "2%", float: "right" }}
                  onClick={this.handleSubmit}
                >
                  Tambah Pengumuman
                </Button>
              </Card>
            </div>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}
export default Ringkasan;
