import React from "react";
import { Modal, Form, Input, Button, Row, Col, Select } from "antd";
import { scoreService } from "../../../../services/score.service";
import { categoryService } from "../../../../services/category.services";
import { scheduleService } from "../../../../services/schedule.services";

const { Option } = Select;

function onBlur() {}

function onFocus() {}

function onSearch(val) {}

class ManageModal extends React.Component {
  state = {
    data: this.props.score,
    isOpen: false,
    phase: [],
    categories: [],
    user_id: 0,
    phase_id: -1,
    answer_right: 0,
    answer_wrong: 0,
    not_answered: 0,
    score: 0,
    rank: 0,
    category_id: -1,
    description: "",
    isLoading: false,
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.score !== this.props.score) {
      if (this.props.score)
        this.setState({
          isLoading: false,
          data: this.props.score,
          user_id: this.props.score.user_id,
          phase_id: this.props.score.phase_id,
          answer_right: this.props.score.answer_right,
          answer_wrong: this.props.score.answer_wrong,
          not_answered: this.props.score.not_answered,
          score: this.props.score.score,
          rank: this.props.score.rank,
          category_id: this.props.score.category_id,
          description: this.props.score.description,
        });
      else
        this.setState({
          isLoading: false,
          data: null,
          name: "",
          content: "",
        });
    }
  }

  componentDidMount() {
    categoryService.getCategory().then((resp) => {
      let data = resp.data.map((item) => {
        return { name: "categoryId", label: item.name, value: item.id };
      });
      data.unshift({ name: "categoryId", label: "Semua Kategori", value: -1 });
      this.setState({ categories: data });
    });

    scheduleService.getScheduleScore(1, 1000, "competition").then((resp) => {
      let data = resp.data.map((item) => {
        return { name: "phaseId", label: item.name, value: item.id };
      });
      data.unshift({ name: "phaseId", label: "Semua Jadwal", value: -1 });
      this.setState({ phase: data });
    });
  }

  handleSubmit = (e) => {
    this.setState({ isLoading: true });
    e.preventDefault();
    scoreService
      .editScore(this.state.data.id, {
        user_id: this.state.user_id,
        phase_id: this.state.phase_id,
        answer_right: this.state.answer_right,
        answer_wrong: this.state.answer_wrong,
        not_answered: this.state.not_answered,
        score: this.state.score,
        rank: this.state.rank,
        category_id: this.state.category_id,
        description: this.state.description,
      })
      .then(
        (resp) => {
          this.toggleModal();
          this.setState({
            isLoading: false,
            data: null,
            phase_id: "",
            answer_right: "",
            answer_wrong: "",
            not_answered: "",
            score: "",
            rank: "",
            category_id: "",
            description: "",
          });
          this.props.onUpdated(resp);
        },
        (error) => {
          this.toggleModal();
          this.setState({
            isLoading: false,
            data: null,
            phase_id: "",
            answer_right: "",
            answer_wrong: "",
            not_answered: "",
            score: "",
            rank: "",
            category_id: "",
            description: "",
          });
          this.props.onUpdated({
            isUpdated: false,
            status: false,
            message: error,
            data: null,
          });
        }
      );
  };

  render() {
    return (
      <Modal
        title="Edit Score"
        centered
        visible={this.state.isOpen}
        toggle={this.toggleModal}
        onCancel={this.handleCancel}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Batalkan
          </Button>,
          <Button
            loading={this.state.isLoading}
            key="submit"
            type="primary"
            onClick={(e) => this.handleSubmit(e)}
          >
            Edit Score
          </Button>,
        ]}
      >
        <Form labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
          <Row style={{ marginLeft: "5%" }}>
            <Col span={5}>
              <label>Tahap</label>
            </Col>
            <Col span={18}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Tahap Pelaksanaan"
                optionFilterProp="children"
                onChange={(e) => {
                  this.setState({ phase_id: e });
                }}
                value={this.state.phase_id}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
              >
                {this.state &&
                  this.state.phase.map((item) => (
                    <Option value={item.value}>{item.label}</Option>
                  ))}
              </Select>
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%", marginTop: "5%" }}>
            <Col span={5}>
              <label>Benar</label>
            </Col>
            <Col span={18}>
              <Input
                placeholder="Jawaban Benar"
                type="text"
                value={this.state.answer_right}
                id="answer_right"
                onChange={(e) =>
                  this.setState({ answer_right: e.target.value })
                }
              />
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%", marginTop: "5%" }}>
            <Col span={5}>
              <label>Salah</label>
            </Col>
            <Col span={18}>
              <Input
                placeholder="Jawaban Salah"
                value={this.state.answer_wrong}
                id="answer_wrong"
                onChange={(e) =>
                  this.setState({ answer_wrong: e.target.value })
                }
              />
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%", marginTop: "5%" }}>
            <Col span={5}>
              <label>Tidak dijawab</label>
            </Col>
            <Col span={18}>
              <Input
                placeholder="Tidak dijawab"
                value={this.state.not_answered}
                id="not_answered"
                onChange={(e) =>
                  this.setState({ not_answered: e.target.value })
                }
              />
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%", marginTop: "5%" }}>
            <Col span={5}>
              <label>Nilai</label>
            </Col>
            <Col span={18}>
              <Input
                placeholder="Nilai"
                type="text"
                value={this.state.score}
                id="score"
                onChange={(e) => this.setState({ score: e.target.value })}
              />
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%", marginTop: "5%" }}>
            <Col span={5}>
              <label>Peringkat</label>
            </Col>
            <Col span={18}>
              <Input
                placeholder="Peringkat"
                type="text"
                value={this.state.rank}
                id="rank"
                onChange={(e) => this.setState({ rank: e.target.value })}
              />
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%", marginTop: "5%" }}>
            <Col span={5}>
              <label>Kategori</label>
            </Col>
            <Col span={18}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Kategori"
                optionFilterProp="children"
                value={this.state.category_id}
                onChange={(e) => {
                  this.setState({ category_id: e });
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
              >
                {this.state &&
                  this.state.categories.map((item) => (
                    <Option value={item.value}>{item.label}</Option>
                  ))}
              </Select>
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%", marginTop: "5%" }}>
            <Col span={5}>
              <label>Deskripssi</label>
            </Col>
            <Col span={18}>
              <Input
                placeholder="Deskripsi"
                type="text"
                value={this.state.description}
                id="description"
                onChange={(e) => this.setState({ description: e.target.value })}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default ManageModal;
