import React from "react";
import { Modal, Form, Input, Button, Row, Col } from "antd";
import { announcementService } from "../../../../services/announcement.service";
const { TextArea } = Input;

class ManageModal extends React.Component {
  state = {
    data: this.props.announcement,
    isOpen: false,
    isLoading: false,
    title: "",
    content: "",
  };
  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    if (this.state.data) {
      announcementService
        .editAnnouncement(this.state.data.id, {
          title: this.state.title,
          content: this.state.content,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              title: "",
              content: "",
            });
            this.props.onUpdated(resp);
          },
          (error) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              title: "",
              content: "",
            });
            this.props.onUpdated({
              isUpdated: true,
              status: false,
              message: error,
              data: null,
            });
          }
        );
    } else {
      announcementService
        .addAnnouncement({
          title: this.state.title,
          content: this.state.content,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              title: "",
              content: "",
            });
            this.props.onUpdated(resp);
          },
          (error) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              title: "",
              content: "",
            });
            this.props.onUpdated({
              isUpdated: false,
              status: false,
              message: error,
              data: null,
            });
          }
        );
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.announcement !== this.props.announcement) {
      if (this.props.announcement)
        this.setState({
          isLoading: false,
          data: this.props.announcement,
          title: this.props.announcement.title,
          content: this.props.announcement.content,
        });
      else
        this.setState({
          isLoading: false,
          data: null,
          title: "",
          content: "",
        });
    }
  }

  render() {
    return (
      <Modal
        title={this.state.data ? "Edit Pengumuman" : "Tambah Pengumuman"}
        centered
        visible={this.state.isOpen}
        toggle={this.toggleModal}
        onCancel={this.handleCancel}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Batalkan
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={(e) => this.handleSubmit(e)}
          >
            {this.state.data ? "Edit Pengumuman" : "Tambah Pengumuman"}
          </Button>,
        ]}
      >
        <Form>
          <Row style={{ marginTop: 24 }} gutter={[12]}>
            <Col xs={24} sm={5} md={5} lg={5}>
              <label>
                Judul <span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <Input
                placeholder="Judul"
                type="text"
                style={{ width: "100%" }}
                value={this.state.title}
                onChange={(e) => this.setState({ title: e.target.value })}
                id="title"
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 24 }} gutter={[12]}>
            <Col xs={24} sm={5} md={5} lg={5}>
              <label>
                Keterangan <span style={{ color: "red" }}> *</span>{" "}
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <TextArea
                autoSize={{ minRows: 11, maxRows: 15 }}
                placeholder="Keterangan"
                type="text"
                value={this.state.content}
                id="description"
                onChange={(e) => this.setState({ content: e.target.value })}
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default ManageModal;
