// import { authHeader } from '../_helpers';
import { mainConfig } from "../config/mainConfig";
import { authHeader, handleResponse } from "./auth.header";

export const categoryService = {
  getCategory,
  addCategory,
  editCategory,
  deleteCategory,
  getLineLinkParticipants,
  addLineLinks,
  editLineLinks,
  deleteLineLinks,
  getLineLinkStudents,
};

function getCategory() {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/category`, reqOpt).then(handleResponse);
}

function addCategory(data) {
  const reqOpt = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${mainConfig.apiUrl}/category`, reqOpt).then(handleResponse);
}
function editCategory(id, data) {
  const reqOpt = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${mainConfig.apiUrl}/category/${id}`, reqOpt).then(
    handleResponse
  );
}
function deleteCategory(id) {
  const reqOpt = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/category/${id}`, reqOpt).then(
    handleResponse
  );
}
function getLineLinkParticipants(id) {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${mainConfig.apiUrl}/category-wag?category_id=${id}`,
    reqOpt
  ).then(handleResponse);
}
function getLineLinkStudents(all = 0) {
  const reqOpt = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${mainConfig.apiUrl}/category-wag?line_id=${all}&type=student`,
    reqOpt
  ).then(handleResponse);
}
function addLineLinks(data) {
  const reqOpt = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${mainConfig.apiUrl}/category-wag`, reqOpt).then(
    handleResponse
  );
}
function editLineLinks(id, data) {
  const reqOpt = {
    method: "PUT",
    headers: authHeader(),
    body: JSON.stringify(data),
  };

  return fetch(`${mainConfig.apiUrl}/category-wag/${id}`, reqOpt).then(
    handleResponse
  );
}
function deleteLineLinks(id) {
  const reqOpt = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/category-wag/${id}`, reqOpt).then(
    handleResponse
  );
}
