import { mainConfig } from '../config/mainConfig';
import { authHeader, handleResponse } from './auth.header';

export const participantService = {
    getProgress,
    getParticipant,
    getBiodata,
    getUser,
    getProfileImage,
    editUser,
    addParticipant,
    editParticipant,
    deleteParticipant,
    getSchool,
    addSchool,
    deleteSchool,
    editSchool,
};
function getProgress(id) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/user/progress/${id}`, reqOpt).then(handleResponse)
}
function getProfileImage(id) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/user/image/${id}`, reqOpt).then(handleResponse)
}
function getParticipant(page = 1, limit = 0, categoryId = -1, search) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/user?page=${page}&per_page=${limit}&category_id=${categoryId}&search=${search}`, reqOpt).then(handleResponse)
}

function getUser(id) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/user/${id}`, reqOpt).then(handleResponse)
}
function addParticipant(data, file) {

    const formData = new FormData()
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    if (file) formData.append('profile_image', file)

    const reqOpt = {
        method: 'POST',
        headers: authHeader(true),
        body: formData
    }

    return fetch(`${mainConfig.apiUrl}/user`, reqOpt).then(handleResponse)
}
function editParticipant(id, data, file) {
    const formData = new FormData()
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    if (file) formData.append('profile_image', file)

    const reqOpt = {
        method: 'PUT',
        headers: authHeader(true),
        body: formData
    }

    return fetch(`${mainConfig.apiUrl}/user/${id}`, reqOpt).then(handleResponse)
}
function getBiodata(id) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/user/${id}`, reqOpt).then(handleResponse)
}

// function addContact(data){
//     const reqOpt = {
//         method: 'POST',
//         headers: authHeader(),
//         body: JSON.stringify(data)
//     }

//     return fetch(`${mainConfig.apiUrl}/contact`, reqOpt).then(handleResponse)
// }
function editUser(data, file, id) {
    delete data.category
    delete data.updated_at
    delete data.school
    delete data.province
    delete data.city
    delete data.district
    delete data.olympiad_id
    delete data.id
    delete data.profile_image
    delete data.registration_number
    delete data.username
    delete data.created_at
    const formData = new FormData()
    Object.keys(data).forEach(key => formData.append(key, data[key]));
    if (file) formData.append('profile_image', file)

    const reqOpt = {
        method: 'PUT',
        headers: authHeader(true),
        body: formData
    }

    return fetch(`${mainConfig.apiUrl}/user/${id}`, reqOpt).then(handleResponse)
}
function deleteParticipant(id) {
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/user/${id}`, reqOpt).then(handleResponse)
}

function getSchool(page, limit, value) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/school?page=${page}&per_page=${limit}&search=${value}`, reqOpt).then(handleResponse)
}
function addSchool(data) {
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${mainConfig.apiUrl}/school`, reqOpt).then(handleResponse)
}
function deleteSchool(id) {
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/school/${id}`, reqOpt).then(handleResponse)
}
function editSchool(id, data) {
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${mainConfig.apiUrl}/school/${id}`, reqOpt).then(handleResponse)
}