import React from "react";
import { Modal, Form, Input, Button, Row, Col, Collapse } from "antd";
import { categoryService } from "../../../../../services/category.services";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";

const { TextArea } = Input;

class ManageModal extends React.Component {
  state = {
    data: this.props.category,
    isOpen: false,
    isLoading: false,
    name: "",
    description: "",
    participantLinks: [],
  };
  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };
  addCategory = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    const participantLinksSubmit = this.state.participantLinks.map(
      (item) => item.link
    );
    if (this.state.data) {
      categoryService
        .editCategory(this.state.data.id, {
          name: this.state.name,
          description: this.state.description,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              description: "",
            });
            this.props.onUpdated(resp);
          },
          (error) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              description: "",
            });
            this.props.onUpdated({
              isUpdated: true,
              status: false,
              message: error,
              data: null,
            });
          }
        );
    } else {
      categoryService
        .addCategory({
          name: this.state.name,
          description: this.state.description,
          participant_wag_link: participantLinksSubmit,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              description: "",
              participantLinks: [],
            });
            this.props.onUpdated(resp);
          },
          (error) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              description: "",
              participantLinks: [],
            });
            this.props.onUpdated({
              isUpdated: false,
              status: false,
              message: error,
              data: null,
            });
          }
        );
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.category !== this.props.category) {
      if (this.props.category) {
        this.setState({
          isLoading: false,
          data: this.props.category,
          name: this.props.category.name,
          description: this.props.category.description,
        });
        categoryService
          .getLineLinkParticipants(this.props.category.id)
          .then((resp) => {
            let participantDefault = resp?.data.map((o) => {
              return { id: o.id, link: o.link };
            });
            this.setState({ participantLinks: participantDefault });
          });
      } else
        this.setState({
          isLoading: false,
          data: null,
          name: "",
          description: "",
        });
    }
  }
  addParticipantLinks() {
    const links = this.state.participantLinks;
    const link = [{ id: 0, link: "" }];
    links.push(link);
    this.setState({
      participantLinks: links,
    });
  }
  onChangeParticipants(index, value) {
    const newIds = this.state.participantLinks.slice();
    newIds[index].link = value;
    this.setState({ participantLinks: newIds });
  }
  onDeleteParticipantLinks(index, id) {
    if (this.state.data) {
      categoryService.deleteLineLinks(id.id).then(
        (resp) => {
          this.toggleModal();
          toast.success(resp.message, {
            position: toast.POSITION.TOP_CENTER,
            pauseOnFocusLoss: false,
            autoClose: 2000,
          });
        },
        (err) => {
          toast.error(err, {
            position: toast.POSITION.TOP_CENTER,
            pauseOnFocusLoss: false,
            autoClose: 2000,
          });
        }
      );
    } else {
      const dataDelete = this.state.participantLinks.filter(function (_, i) {
        return i !== index;
      });
      this.setState({ participantLinks: dataDelete });
    }
  }

  onSubmitLinksPartial(item) {
    if (item.length > 0 && item[0].id === 0) {
      categoryService
        .addLineLinks({
          category_id: this.props.category.id,

          link: item.link,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              line_category_id: "",
              additional: "",
              requirement: "",
              description: "",
            });
            this.props.onUpdated(resp);
          },
          (error) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              line_category_id: "",
              additional: "",
              requirement: "",
              description: "",
            });
            this.props.onUpdated({
              isUpdated: true,
              status: false,
              message: error,
              data: null,
            });
          }
        );
    } else {
      categoryService
        .editLineLinks(item.id, {
          category_id: this.props.category.id,

          link: item.link,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              line_category_id: "",
              additional: "",
              requirement: "",
              description: "",
            });
            this.props.onUpdated(resp);
          },
          (error) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              line_category_id: "",
              additional: "",
              requirement: "",
              description: "",
            });
            this.props.onUpdated({
              isUpdated: true,
              status: false,
              message: error,
              data: null,
            });
          }
        );
    }
  }
  render() {
    return (
      <Modal
        title={this.state.data ? "Edit Category" : "Tambah Category"}
        centered
        visible={this.state.isOpen}
        toggle={this.toggleModal}
        onCancel={this.handleCancel}
        maskClosable={false}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Batalkan
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={this.state.isLoading}
            onClick={(e) => this.addCategory(e)}
          >
            {this.state.data ? "Edit Kategori" : "Tambah Kategori"}
          </Button>,
        ]}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
          <Row style={{ marginTop: 16 }} gutter={[16]}>
            <Col xs={24} sm={5} md={5} lg={6}>
              <label>
                Nama <span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <Input
                placeholder="Nama"
                type="text"
                value={this.state.name}
                id="name"
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </Col>
          </Row>
          <Row gutter={[12]} style={{ marginTop: 12 }}>
            <Col xs={24} sm={5} md={5} lg={6}>
              <label>
                Keterangan <span style={{ color: "red" }}>*</span>
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <TextArea
                autoSize={{ minRows: 11, maxRows: 15 }}
                placeholder="Keterangan"
                type="text"
                value={this.state.description}
                id="description"
                onChange={(e) => this.setState({ description: e.target.value })}
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 16, marginLeft: -10 }} gutter={30}>
            <Collapse
              style={{ width: "100%" }}
              expandIconPosition="right"
              ghost={true}
            >
              <Collapse.Panel
                header={
                  <label>
                    Link Grup WA Peserta{" "}
                    <span style={{ color: "red" }}> *</span>
                  </label>
                }
                key="1"
              >
                <Button
                  block
                  type="dashed"
                  onClick={() => this.addParticipantLinks()}
                >
                  Tambah Link WA Peserta
                </Button>
                {this.state.participantLinks?.map((item, index) => (
                  <Row gutter={[20]}>
                    <Col md={21}>
                      <Input
                        style={{ marginTop: 16 }}
                        placeholder={`Link Grup WA ${index + 1}`}
                        type="text"
                        value={item.link}
                        id="name"
                        onChange={(e) =>
                          this.onChangeParticipants(index, e.target.value)
                        }
                      />
                    </Col>
                    {this.state.data && (
                      <Col md={1}>
                        <SaveOutlined
                          onClick={() => this.onSubmitLinksPartial(item)}
                          style={{
                            marginTop: 20,
                            fontSize: 22,
                            color: "#1890ff",
                            cursor: "pointer",
                          }}
                        />
                      </Col>
                    )}
                    <Col md={1}>
                      <DeleteOutlined
                        onClick={() =>
                          this.onDeleteParticipantLinks(index, item)
                        }
                        style={{
                          marginTop: 20,
                          fontSize: 22,
                          marginLeft: 10,
                          color: "#1890ff",
                        }}
                      />
                    </Col>
                  </Row>
                ))}
              </Collapse.Panel>
            </Collapse>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default ManageModal;
