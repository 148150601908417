import React from "react";
import "antd/dist/antd.css";
import { Row, Col, Pagination, DatePicker, Select, Table, Empty } from "antd";
import Search from "antd/lib/input/Search";
import ActionPayment from "./ActionPayment";
import { paymenttService } from "../../../../services/payment.service";
import moment from "moment-timezone";
import "moment/locale/id";
import ButtonExport from "../../../../components/ButtonExport";
moment.locale("id");

const { Option } = Select;

function onBlur() {}

function onFocus() {}

function onSearch(val) {}
const CustomHeader = (props) => {
  let csvData = props.data.map((item) => {
    return {
      "Nama Peserta": item.user_name,
      NISN: item.nisn,
      "No Telepon": item.phone_number,
      Pembayaran:
        item.payment_status === "paid"
          ? "Terbayar"
          : item.payment_status === "waiting"
          ? "Menunggu Konfirmasi"
          : "Belum Dibayar",
      "Tanggal Pembayaran": item.pay_date,
      "Nama Pengirim": item.name,
      "Nomor Referensi": item.ref_no,
    };
  });
  return (
    <React.Fragment>
      <div style={{ marginTop: "2%", marginBottom: "3%" }}>
        <Row gutter={[16]}>
          <Col style={{ marginTop: 16 }} xs={24} sm={12} md={8} lg={5}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Status Pembayaran"
              optionFilterProp="children"
              onChange={props.onPayment}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
            >
              <Option value="">Semua Pembayaran</Option>
              <Option value="waiting">Menunggu Konfirmasi</Option>
              <Option value="paid">Terbayar</Option>
              <Option value="due">Belum Dibayar</Option>
            </Select>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={12} md={8} lg={4}>
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Tanggal Mulai"
              onChange={(date) => props.changeDate(date, "startDate")}
            ></DatePicker>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={12} md={8} lg={4}>
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Tanggal Berakhir"
              onChange={(date) => props.changeDate(date)}
            ></DatePicker>
          </Col>

          <Col style={{ marginTop: 16 }} xs={24} sm={12} md={12} lg={8}>
            <div className="form-control-position pr-3">
              <Search
                placeholder="Cari nama peserta, nisn, atau nomor registrasi"
                allowClear
                size="middle"
                onChange={(e) => props.handleFilter(e)}
                style={{
                  color: "#555597",
                  height: 30,
                  width: "100%",
                }}
              />
            </div>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={24} md={12} lg={3}>
            <ButtonExport
              csvData={csvData}
              style={{ width: "100%" }}
              fileName={"Data Pembayaran " + moment().format("LL")}
            />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
class PaymentTable extends React.Component {
  state = {
    columns: [
      {
        title: "Nama",
        dataIndex: "user_name",
        key: "user_name",
      },
      {
        title: "NISN",
        dataIndex: "nisn",
        key: "nisn",
        responsive: ["md"],
      },
      {
        title: "No Telepon",
        dataIndex: "phone_number",
        key: "phone_number",
        responsive: ["md"],
      },
      {
        title: "Pembayaran",
        dataIndex: "payment_status_text",
        key: "payment_status_text",
      },
      {
        title: "Tanggal Pembayaran",
        key: "pay_date",
        dataIndex: "pay_date",
        responsive: ["md"],
      },
      {
        title: "Tindakan",
        dataIndex: "",
        key: "x",
        render: (row) => <ActionPayment payment={row} edit={this.props.edit} />,
      },
    ],
    data: [],
    startDate: "",
    endDate: "",
    paymentStatus: -1,
    page: 1,
    limit: 10,
    total: 0,
    value: "",
  };

  componentDidMount() {
    this.getData(true);
  }

  getData(showLoading = false) {
    this.setState({ isLoading: showLoading });
    paymenttService
      .getPayment(
        this.state.page,
        this.state.limit,
        this.state.value,
        this.state.paymentStatus,
        this.state.startDate,
        this.state.endDate
      )
      .then(
        (resp) => {
          if (resp.status) {
            this.setState({
              data: resp.data,
              total: resp.total,
              isLoading: false,
            });
          }
        },
        (error) => {
          this.setState({ isLoading: false });
        }
      );
  }
  componentDidUpdate(prevProps, prevState) {
    //page change call and rows count
    if (
      prevProps.reload !== this.props.reload ||
      prevState.paymentStatus !== this.state.paymentStatus ||
      prevState.page !== this.state.page ||
      prevState.value !== this.state.value ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate ||
      prevState.limit !== this.state.limit
    ) {
      this.getData(false);
    }
  }
  changeDate = (dates, type) => {
    if (type === "startDate") {
      let startDate = moment(dates._d).format("YYYY-MM-DD 00:01");
      this.setState({ startDate });
    } else {
      let endDate = moment(dates._d).format("YYYY-MM-DD 23:59");
      this.setState({ endDate });
    }
  };

  handleFilter = (e) => {
    let value = e.target.value;
    this.setState({ value });
  };

  onChange = (page, pageSize) => {
    if (isNaN(pageSize)) {
      this.setState({ page: page, limit: 0 });
    } else {
      this.setState({ page: page, limit: pageSize });
    }
  };

  onPayment = (item) => {
    this.setState({ paymentStatus: item });
  };

  render() {
    let { data, columns, value } = this.state;
    let table = {
      data: data,
      total: this.state.total,
      columns: columns,
      isLoading: this.state.isLoading,
    };

    let emptyData = {
      emptyText: (
        <Empty
          style={{ marginTop: "3%", marginBottom: "3%" }}
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 80,
          }}
          description={<span style={{ color: "black" }}>Tidak ada data</span>}
        ></Empty>
      ),
    };
    return (
      <React.Fragment>
        <CustomHeader
          data={data}
          columns={table.columns}
          //Date
          changeDate={this.changeDate}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          //Search
          handleFilter={this.handleFilter}
          value={value}
          //Payment Status
          onPayment={this.onPayment}
        />
        <Table
          columns={table.columns}
          dataSource={table.data}
          pagination={false}
          loading={table.isLoading}
          locale={emptyData}
          scroll={{ x: true }}
        />
        {table.total > 0 && (
          <Pagination
            responsive={true}
            style={{ textAlign: "center", marginTop: "5%", color: "black" }}
            defaultCurrent={1}
            total={table.total}
            showSizeChanger={true}
            onChange={this.onChange}
            pageSize={
              this.state.limit === 0 ? this.state.total : this.state.limit
            }
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} dari ${total} data`
            }
            pageSizeOptions={["10", "20", "50", "100", "semua"]}
          />
        )}
      </React.Fragment>
    );
  }
}

export default PaymentTable;
