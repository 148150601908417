import React from "react";
import { Modal, Form, Button, Row, Empty, Table } from "antd";
import { scoreService } from "../../../../../services/score.service";

class ManageModal extends React.Component {
  state = {
    columns: [
      {
        title: "Nomor Peserta",
        dataIndex: "registration_number",
        key: "registration_number",
      },
      {
        title: "Benar",
        dataIndex: "answer_right",
        key: "answer_right",
      },
      {
        title: "Salah",
        dataIndex: "answer_wrong",
        key: "answer_wrong",
      },
      {
        title: "Tidak Dijawab",
        dataIndex: "not_answered",
        key: "noot_answered",
      },
      {
        title: "Nilai",
        dataIndex: "score",
        key: "score",
      },
      {
        title: "Peringkat",
        key: "rank",
        dataIndex: "rank",
      },
      {
        title: "Deskripsi",
        key: "description",
        dataIndex: "description",
      },
      {
        title: "Detail Error",
        key: "error_detail",
        dataIndex: "error_detail",
      },
    ],

    status: this.props.status,
    isOpen: false,
    data: [],
    isLoading: true,
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.status !== this.props.status) {
      this.getData(false);
    }
  }

  getData() {
    scoreService
      .viewStatusImport({
        import_date: this.props.status && this.props.status.import_date,
      })
      .then(
        (resp) => {
          this.setState({ isLoading: false, data: resp.data });
        },
        (err) => {
          this.setState({ isLoading: false });
        }
      );
  }
  handleCancel = () => {
    this.setState({ isOpen: false });
  };

  render() {
    let { data, columns } = this.state;
    let table = {
      data: data,
      total: this.state.total,
      columns: columns,
      isLoading: this.state.isLoading,
    };
    let emptyData = {
      emptyText: (
        <Empty
          style={{ marginTop: "3%", marginBottom: "3%" }}
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 80,
          }}
          description={<span style={{ color: "black" }}>Tidak ada data</span>}
        ></Empty>
      ),
    };
    return (
      <Modal
        title="Detail Import"
        centered
        visible={this.state.isOpen}
        toggle={this.toggleModal}
        onCancel={this.handleCancel}
        width="auto"
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Tutup
          </Button>,
        ]}
      >
        <Form labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
          <Row>
            <Table
              columns={table.columns}
              dataSource={table.data}
              pagination={false}
              loading={table.isLoading}
              locale={emptyData}
              scroll={{ x: true }}
            />
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default ManageModal;
