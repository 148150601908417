import React from "react";
import { Layout, Menu } from "antd";
import AppRoute from "../config/router/router";
import { Link, Switch, Route } from "react-router-dom";

const { Content, Sider } = Layout;

class Sidebar extends React.Component {
  state = {
    user_role: "",
  };
  componentDidMount() {
    if (localStorage.length > 0) {
      let userData = localStorage.getItem("USER_DATA");
      let user_role = JSON.parse(userData);
      this.setState({ user_role: user_role.role });
    }
  }
  render() {
    return (
      <React.Fragment>
        <Layout>
          <Sider
            breakpoint="lg"
            theme="light"
            className="sider"
            width={235}
            collapsedWidth="0"
            zeroWidthTriggerStyle={{
              boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Menu theme="light" mode="inline" defaultSelectedKeys={["1"]}>
              {this.state.user_role === "admin"
                ? AppRoute.filter(
                    (it) => it.menu === "Sidebar" && it.role === "admin"
                  ).map((item) => {
                    return (
                      <Menu.Item
                        icon={item.icon}
                        className="font-normal"
                        style={{ color: "black" }}
                      >
                        {item.name}
                        <Link to={item.link}></Link>
                      </Menu.Item>
                    );
                  })
                : AppRoute.filter(
                    (it) => it.menu === "Sidebar" && it.role === "user"
                  ).map((item) => {
                    return (
                      <Menu.Item
                        icon={item.icon}
                        className="font-normal"
                        style={{ color: "black" }}
                      >
                        {item.name}
                        <Link to={item.link}></Link>
                      </Menu.Item>
                    );
                  })}
            </Menu>
          </Sider>
          <Layout>
            <Content
              style={{
                marginTop: "50px",
                // overflow: "scroll",
              }}
            >
              <Switch>
                {AppRoute.filter((it) => it.menu === "Sidebar").map(
                  (item, i) => {
                    return (
                      // <Router history={history}>
                      <Route
                        exact
                        path={item.path}
                        component={item.component}
                      />
                      // </Router>
                    );
                  }
                )}
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </React.Fragment>
    );
  }
}

export default Sidebar;
