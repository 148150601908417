import React from "react";
import "antd/dist/antd.css";
import { Menu, Dropdown, Space } from "antd";
import { DownOutlined, DeleteOutlined } from "@ant-design/icons";

class ActionParticipants extends React.Component {
  state = {
    user: this.props.user,
  };

  deleteUser = () => {
    this.props.delete(this.state.user);
  };

  editUser = () => {
    this.props.edit(this.state.user);
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user !== this.props.user) {
      this.setState({ user: this.props.user });
    }
  }

  render() {
    return (
      <Space wrap>
        <Dropdown.Button
          onClick={(e) => this.editUser(e)}
          placement="bottomCenter"
          icon={<DownOutlined />}
          overlay={
            <Menu>
              <Menu.Item
                key="1"
                icon={<DeleteOutlined />}
                onClick={(e) => this.deleteUser(e)}
              >
                Hapus
              </Menu.Item>
            </Menu>
          }
        >
          Lihat Detail
        </Dropdown.Button>
      </Space>
    );
  }
}

export default ActionParticipants;
