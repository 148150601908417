import React from "react";
import { Menu, Button, Layout } from "antd";
import { Link } from "react-router-dom";
import AppRoute from "../config/router/router";
import LoginModal from "../views/AuthModal/LoginModal";
import LogoutModal from "../views/AuthModal/LogoutModal";

const { Header } = Layout;
class AppHeader extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
    this._modalLogout = React.createRef();
  }
  state = {
    loading: false,
    visible: false,
    modal: false,
    userData: [],
    user_role: "",
  };
  showModal = () => {
    this._modalManage.current.toggleModal();
  };
  logoutModal = () => {
    this._modalLogout.current.toggleModal();
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };
  componentDidMount() {
    if (localStorage.length > 0) {
      let userData = localStorage.getItem("USER_DATA");
      let user_role = JSON.parse(userData);
      this.setState({ user_role: user_role.role });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userData !== this.props.userData) {
      this.componentDidMount(true);
    }
  }
  render() {
    return (
      <React.Fragment>
        <Layout>
          <Header
            className="header"
            style={{ position: "fixed", zIndex: 1, width: "100%" }}
          >
            <div className="logo" />

            <Menu
              mode="horizontal"
              defaultSelectedKeys={[
                window.location.pathname.search("dashboard") > -1
                  ? "1"
                  : window.location.pathname.search("score") > -1
                  ? "2"
                  : "",
              ]}
              theme="dark"
            >
              {this.state.user_role === "" || this.state.user_role === undefined
                ? AppRoute.filter(
                    (it) => it.menu === "Navbar" && it.name !== "Profil"
                  ).map((item) => {
                    return (
                      <Menu.Item
                        key={item.key}
                        style={{
                          cursor: "auto",
                        }}
                      >
                        {item.name}
                        <Link to={item.link}></Link>
                      </Menu.Item>
                    );
                  })
                : AppRoute.filter((it) => it.menu === "Navbar").map((item) => {
                    return (
                      <Menu.Item key={item.key} style={{ color: "white" }}>
                        {item.name}
                        <Link to={item.link}></Link>
                      </Menu.Item>
                    );
                  })}
              {this.state.user_role === "" ||
              this.state.user_role === undefined ? (
                <Menu.Item
                  style={{ backgroundColor: "transparent", cursor: "auto" }}
                  // disabled={true}
                >
                  <Button type="primary" onClick={(e) => this.showModal()}>
                    Masuk
                  </Button>
                </Menu.Item>
              ) : (
                <Menu.Item
                  style={{ backgroundColor: "transparent", cursor: "auto" }}
                  // disabled={true}
                >
                  <Button type="danger" onClick={(e) => this.logoutModal()}>
                    Keluar
                  </Button>
                </Menu.Item>
              )}
            </Menu>
          </Header>
        </Layout>

        <LoginModal ref={this._modalManage} />
        <LogoutModal ref={this._modalLogout} />
      </React.Fragment>
    );
  }
}
export default AppHeader;
