import React from "react";
import {
  Modal,
  Form,
  Input,
  Button,
  DatePicker,
  Row,
  Col,
  Select,
  Tooltip,
} from "antd";
import { scheduleService } from "../../../../../services/schedule.services";
import moment from "moment-timezone";

const { Option } = Select;

function onBlur() {}

function onFocus() {}

function onSearch(val) {}
class ManageModal extends React.Component {
  state = {
    data: this.props.schedule,
    isOpen: false,
    isLoading: false,
    name: "",
    type: "",
    startDate: "",
    endDate: "",
  };
  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };
  addSchedule = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    if (this.state.data) {
      scheduleService
        .editSchedule(this.state.data.id, {
          name: this.state.name,
          type: this.state.type,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              type: "",
              startDate: "",
              endDate: "",
            });
            this.props.onUpdated(resp);
          },
          (error) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              type: "",
              startDate: "",
              endDate: "",
            });
            this.props.onUpdated({
              isUpdated: true,
              status: false,
              message: error,
              data: null,
            });
          }
        );
    } else {
      scheduleService
        .addSchedule({
          name: this.state.name,
          type: this.state.type,
          start_date: this.state.startDate,
          end_date: this.state.endDate,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              type: "",
              startDate: "",
              endDate: "",
            });
            this.props.onUpdated(resp);
          },
          (error) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              type: "",
              startDate: "",
              endDate: "",
            });
            this.props.onUpdated({
              isUpdated: false,
              status: false,
              message: error,
              data: null,
            });
          }
        );
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.schedule !== this.props.schedule) {
      if (this.props.schedule)
        this.setState({
          isLoading: false,
          data: this.props.schedule,
          name: this.props.schedule.name,
          type: this.props.schedule.type,
          startDate: this.props.schedule.start_date,
          endDate: this.props.schedule.end_date,
        });
      else
        this.setState({
          isLoading: false,
          data: null,
          name: "",
          type: "",
          startDate: "",
          endDate: "",
        });
    }
  }
  onChange(date, type) {
    if (type === "startDate") {
      let startDate = moment(date._d).format("YYYY-MM-DD HH:mm:ss");
      this.setState({ startDate: startDate });
    } else {
      let endDate = moment(date._d).format("YYYY-MM-DD HH:mm:ss");
      this.setState({ endDate: endDate });
    }
  }

  render() {
    return (
      <Modal
        title={this.state.data ? "Edit Tahapan" : "Tambah Tahapan"}
        centered
        visible={this.state.isOpen}
        toggle={this.toggleModal}
        maskClosable={false}
        onCancel={this.handleCancel}
        footer={[
          <Button key="back" onClick={this.handleCancel}>
            Batalkan
          </Button>,
          <Button
            loading={this.state.isLoading}
            key="submit"
            type="primary"
            onClick={(e) => this.addSchedule(e)}
          >
            {this.state.data ? "Edit Tahapan" : "Tambah Tahapan"}
          </Button>,
        ]}
      >
        <Form>
          <Row gutter={[16]} style={{ marginTop: 16 }}>
            <Col xs={24} sm={5} md={5} lg={5}>
              <label>
                Nama <span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <Input
                placeholder="Nama"
                type="text"
                value={this.state.name}
                id="name"
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </Col>
          </Row>
          <Row gutter={[12]} style={{ marginTop: 12 }}>
            <Col xs={24} sm={5} md={5} lg={5}>
              <label>
                Tipe <span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <Select
                showSearch
                disabled={this.state.type === "registration" ? true : false}
                style={{ width: "100%" }}
                placeholder="Tipe"
                optionFilterProp="children"
                onChange={(e) => {
                  this.setState({ type: e });
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                value={this.state.type}
              >
                <Option value="">Semua Tipe</Option>
                <Option value="registration" disabled={true}>
                  Registrasi
                </Option>
                <Option value="administration">Administrasi</Option>
                <Option value="competition">Kompetisi</Option>
              </Select>
            </Col>
          </Row>
          <Row style={{ marginTop: 12 }} gutter={[12]}>
            <Col xs={24} sm={5} md={5} lg={5}>
              <label>
                Tanggal Mulai <span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <Tooltip title="isi jika ingin mengubah">
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Tanggal Mulai"
                  value={
                    this.state.startDate ? moment(this.state.startDate) : ""
                  }
                  onChange={(date) => this.onChange(date, "startDate")}
                  // disabledTime={disabledDateTime}
                  showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
                ></DatePicker>
              </Tooltip>
            </Col>
          </Row>

          <Row style={{ marginTop: 12 }} gutter={[12]}>
            <Col xs={24} sm={5} md={5} lg={5}>
              <label>
                Tanggal Selesai <span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col xs={24} sm={18} md={18} lg={18}>
              <Tooltip title="isi jika ingin mengubah">
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Tanggal Selesai"
                  value={this.state.endDate ? moment(this.state.endDate) : ""}
                  onChange={(date) => this.onChange(date)}
                  // disabledTime={disabledDateTime}
                  showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
                ></DatePicker>
              </Tooltip>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default ManageModal;
