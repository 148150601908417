// import { authHeader } from '../_helpers';
import { mainConfig } from "../config/mainConfig";
import { authHeader, handleResponse } from "./auth.header";

export const userService = {
  login,
  logout,
  register,
};

function login({ username, password }) {
  const reqOpt = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ username, password }),
  };
  return fetch(`${mainConfig.apiUrl}/auth/login`, reqOpt).then(handleResponse);
}

function register(data) {
  const reqOpt = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  };
  return fetch(`${mainConfig.apiUrl}/auth/register`, reqOpt).then(
    handleResponse
  );
}

function logout() {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(`${mainConfig.apiUrl}/auth/logout`, requestOptions).then(
    async (resp) => {
      localStorage.clear();
    }
  );
}
