import { mainConfig } from "../config/mainConfig";
import React from "react";
import "antd/dist/antd.css";
import { Button, Carousel } from "antd";
import RegisterModal from "../views/AuthModal/RegisterModal";
import LoginModal from "../views/AuthModal/LoginModal";
import { SettingService } from "../services/setting.services";
import { scheduleService } from "../services/schedule.services";
import { Link } from "react-router-dom";
import moment from "moment";

class CarouselDashboard extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
    this._modalLogin = React.createRef();
  }
  state = {
    isOpen: false,
    data: {},
    userData: {},
    user_role: "",
    schedule: []
  };

  showModal = () => {
    this._modalManage.current.toggleModal();
  };

  componentDidMount() {
    SettingService.getSetting().then(
      (resp) => {
        if (resp.status) {
          this.setState({
            data: resp.data,
          });
        }
      },
      (error) => {
      }
    );

    scheduleService.getScheduleScore(1, 1, "registration").then(
      (resp) => {
        if (resp.status) {
          this.setState({
            schedule: resp.data,
          });
        }
      }, (error) => { }
    );

    if (localStorage.length > 0) {
      let userData = localStorage.getItem("USER_DATA");
      let user_role = JSON.parse(userData);
      this.setState({ user_role: user_role.role });
    }
  }
  showModal = () => {
    this._modalLogin.current.toggleModal();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userData !== this.props.userData) {
      this.componentDidMount(true);
    }
  }
  render() {
    let data = this.props.data;
    let registerSchedule = this.state.schedule?.length > 0 ? this.state.schedule[0] : {};
    return (
      <div
        className="heroBlock"
        style={{
          backgroundImage: "url(" + mainConfig.apiUrl + "/setting/banner)",
          boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.4)",
          height: window.innerHeight,
        }}
      >
        <Carousel autoplay>
          <div className="container-fluid">
            <div className="content">
              <p className="font-large-2" style={{ textAlign: "center" }}>
                {data.banner_title}
              </p>
              <p className="font-medium-3" style={{ textAlign: "center" }}>
                {data.banner_description}
              </p>
              
              {registerSchedule.start_date ? <p style={{ textAlign: "center" }}>Pendaftaran akan dimulai pada {moment(registerSchedule.start_date).format("ll LT")}</p> : <></>}
              

              <div className="btnHolder" style={{ textAlign: "center", display: this.state.user_role === "" ? 'block' : 'none' }}>
                <Link to="/register">
                  <Button type="primary" size="large" style={{ marginTop: "5px" }}>
                    Daftar Sekarang
                  </Button>
                </Link>

                <Button
                  type="primary"
                  onClick={(e) => this.showModal()}
                  size="large"
                  style={{ marginTop: "5px" }}
                >
                  Masuk ke Akun
                </Button>
              </div>
              
            </div>
          </div>
        </Carousel>
        <RegisterModal ref={this._modalManage} />
        <LoginModal ref={this._modalLogin} />
      </div>
    );
  }
}

export default CarouselDashboard;
