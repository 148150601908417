import React from "react";
import { Card, Table, Empty } from "antd";
import { scoreService } from "../../../services/score.service";

class Result extends React.Component {
  state = {
    columns: [
      {
        title: "Tahapan",
        dataIndex: "phase",
        key: "phase",
        responsive: ["md"],
      },
      {
        title: "Benar",
        dataIndex: "answer_right",
        key: "answer_right",
        responsive: ["md"],
      },
      {
        title: "Salah",
        dataIndex: "answer_wrong",
        key: "answer_wrong",
        responsive: ["md"],
      },
      {
        title: "Tidak Dijawab",
        dataIndex: "not_answered",
        key: "noot_answered",
        responsive: ["md"],
      },
      {
        title: "Nilai",
        key: "score",
        dataIndex: "score",
      },
      {
        title: "Peringkat",
        key: "rank",
        dataIndex: "rank",
      },
      {
        title: "Keterangan",
        key: "description",
        dataIndex: "description",
      },
    ],
    data: [],
    page: 1,
    limit: 10,
    total: 0,
    value: "",
    categoryId: "",
    phaseId: "",
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getData(true);
  }

  getData(showLoading = false) {
    let userData = localStorage.getItem("USER_DATA");
    let data = JSON.parse(userData);
    let user_id = data.id;
    let category_id = data.category_id;
    this.setState({ isLoading: showLoading });
    scoreService
      .getScore(
        this.state.page,
        this.state.limit,
        this.state.value,
        category_id,
        this.state.phaseId,
        user_id
      )
      .then(
        (resp) => {
          if (resp.status) {
            this.setState({
              data: resp.data,
              total: resp.total,
              isLoading: false,
            });
          }
        },
        (error) => {
          this.setState({ isLoading: false });
        }
      );
  }
  render() {
    let { data, columns } = this.state;
    let table = {
      data: data,
      columns: columns,
      isLoading: this.state.isLoading,
    };
    let emptyData = {
      emptyText: (
        <Empty
          style={{ marginTop: "3%", marginBottom: "3%" }}
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 80,
          }}
          description={<span style={{ color: "black" }}>Tidak ada data</span>}
        ></Empty>
      ),
    };
    return (
      <React.Fragment>
        {table.data < 5 ? (
          <Card
            headStyle
            style={{
              backgroundColor: "#e8e8e8",
              minHeight: window.innerHeight,
              height: "auto",
              padding: "-5%",
              display: "fixed",
            }}
          >
            <div style={{ marginTop: 20 }}>
              <div>
                <Card
                  style={{
                    borderRadius: "5px",
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  <Table
                    columns={table.columns}
                    dataSource={table.data}
                    pagination={false}
                    loading={table.isLoading}
                    locale={emptyData}
                    scroll={{ x: true }}
                  />
                </Card>
              </div>
            </div>
          </Card>
        ) : (
          <Card
            headStyle
            style={{
              backgroundColor: "#e8e8e8",
              minHeight: window.innerHeight,
              height: "auto",
              padding: "-5%",
              display: "fixed",
            }}
          >
            <div style={{ marginTop: 20 }}>
              <div>
                <Card
                  style={{
                    borderRadius: "5px",
                    height: "100%",
                    overflow: "hidden",
                  }}
                >
                  <Table
                    columns={table.columns}
                    dataSource={table.data}
                    pagination={false}
                    loading={table.isLoading}
                    locale={emptyData}
                    scroll={{ x: true }}
                  />
                </Card>
              </div>
            </div>
          </Card>
        )}
      </React.Fragment>
    );
  }
}
export default Result;
