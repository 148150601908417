import { Divider, Card, Col, Row, Button } from "antd";
import React from "react";
import { announcementService } from "../../../../services/announcement.service";
import { scheduleService } from "../../../../services/schedule.services";
import { scoreService } from "../../../../services/score.service";
import { Link } from "react-router-dom";
import { participantService } from "../../../../services/participant.service";
import { paymenttService } from "../../../../services/payment.service";
import moment from "moment";
import { SettingService } from "../../../../services/setting.services";
import ManageModal from "./ManageModal";
import QRCode from "react-qr-code";

class Ringkasan extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
  }

  state = {
    announcement: [],
    competitionSchedule: [],
    schedule: [],
    progress: "",
    settings: [],
    user_id: 0,
    category_id: 0,
    participantLinks: "",
  };

  handleDownload = (e) => {
    window.open(this.state.settings.technical_document);
  };

  handleClick = (e) => {};

  componentDidMount() {
    window.scrollTo(0, 0);
    let userData = localStorage.getItem("USER_DATA");
    let user_id = JSON.parse(userData);
    this.setState({ user_id: user_id.id, category_id: user_id.category_id });

    participantService.getProgress(user_id.id).then(
      (resp) => {
        if (resp.status) {
          this.setState({ progress: resp.data, isLoading: false });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );

    announcementService.getAnnouncement(1, 2, "", "", "").then(
      (resp) => {
        if (resp.status) {
          this.setState({ announcement: resp.data, isLoading: false });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
    scheduleService.getSchedule(1, 1000).then(
      (resp) => {
        if (resp.status) {
          this.setState({ schedule: resp.data, isLoading: false });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
    scheduleService.getScheduleScore(1, 1000, "competition").then(
      (resp) => {
        if (resp.status) {
          this.setState({ competitionSchedule: resp.data, isLoading: false });
          for (let item of this.state.competitionSchedule) {
            scoreService
              .getScore(
                1,
                1,
                "",
                this.state.category_id,
                item.id,
                this.state.user_id
              )
              .then(
                (resp) => {
                  let rank = resp.data.map((item) => {
                    return item.rank;
                  });
                  let phase_id = resp.data.map((item) => {
                    return item.phase_id;
                  });

                  if (resp.status) {
                    let competitionSchedule = this.state.competitionSchedule;
                    for (let item of competitionSchedule) {
                      if (item.id === parseInt(phase_id)) {
                        item.rank = "Peringkat " + parseInt(rank);
                      } else {
                        item.rank = "-";
                      }
                    }

                    this.setState({ competitionSchedule, isLoading: false });
                  }
                },
                (error) => {
                  this.setState({ isLoading: false });
                }
              );
          }
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
    SettingService.getSetting().then(
      (resp) => {
        if (resp.status) {
          this.setState({
            settings: resp.data,
            total: resp.total,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
    paymenttService.getPaymentsByUser(user_id.id).then(
      (resp) => {
        if (resp.status) {
          this.setState({
            participantLinks: resp.data.participant_group_link,
            isLoading: false,
          });
        }
      },
      (error) => {
          this.setState({ isLoading: false });
      }
    );
  }

  showModal = () => {
    this._modalManage.current.toggleModal();
  };

  render() {
    return (
      <React.Fragment>
        <Card
          headStyle
          style={{
            backgroundColor: "#e8e8e8",
            minHeight: window.innerHeight,

            padding: "-5%",
            height: "auto",
            display: "fixed",
          }}
        >
          <Row gutter={[12]}>
            <Col style={{ marginTop: 24 }} xs={24} sm={24} md={24} lg={8}>
              <Card
                style={{
                  borderColor:
                    this.state.progress.progress === 1 ? "#1890ff" : "",
                  borderRadius: "5px",
                  textAlign: "left",
                  height: "100%",
                  overflow: "hidden",
                  padding: "2%",
                }}
              >
                <p
                  className="font-normal text-bold-500"
                  style={{ display: "inline-block" }}
                >
                  Lengkapi data diri
                </p>
                <p className="font-small-2">
                  Cek kembali data diri yang sudah kamu isi di menu Biodata,
                  jika ada kesalahan segera perbaiki
                </p>
                <Row align="bottom">
                  <Col xs={24} sm={12} md={24} lg={12}>
                    <p
                      className="font-large-1 text-bold-700"
                      style={{ marginBottom: 0 }}
                    >
                      1
                    </p>
                  </Col>
                  <Col xs={24} sm={12} md={24} lg={12} align="right">
                    <Link to="/profile/biodata">
                      <Button type="link">Selengkapnya</Button>
                    </Link>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col style={{ marginTop: 24 }} xs={24} sm={24} md={24} lg={8}>
              <Card
                className="font-medium-1"
                style={{
                  borderColor:
                    this.state.progress.progress === 2 ? "#1890ff" : "",
                  borderRadius: "5px",
                  textAlign: "left",
                  height: "100%",
                  overflow: "hidden",
                  padding: "2%",
                }}
              >
                <p
                  className="font-normal text-bold-500"
                  style={{ display: "inline-block" }}
                >
                  Bayar dan Konfirmasi
                  <br />
                </p>

                <p className="font-small-2">
                  Bayar biaya pendaftaran dan lakukan konfirmasi di menu
                  Pembayaran
                </p>
                <Row align="bottom">
                  <Col xs={24} sm={12} md={24} lg={12}>
                    <p
                      className="font-large-1 text-bold-700"
                      style={{ marginBottom: 0 }}
                    >
                      2
                    </p>
                  </Col>
                  <Col xs={24} sm={12} md={24} lg={12} align="right">
                    <Link to="/profile/payment">
                      <Button
                        disabled={
                          this.state.progress.progress < 2 ? true : false
                        }
                        type="link"
                      >
                        Selengkapnya
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col style={{ marginTop: 24 }} xs={24} sm={24} md={24} lg={8}>
              <Card
                className="font-medium-1"
                style={{
                  borderColor:
                    this.state.progress.progress === 3 ? "#1890ff" : "",
                  borderRadius: "5px",
                  textAlign: "left",
                  height: "100%",
                  overflow: "hidden",
                  padding: "2%",
                }}
              >
                <p
                  className="font-normal text-bold-500"
                  style={{ display: "inline-block" }}
                >
                  Kamu Siap Ikut Olimpiade
                </p>
                <p className="font-small-2">
                  Jangan lupa cetak kartu peserta di menu Pembayaran kemudian
                  kamu bisa masuk ke halaman CBT
                </p>
                <Row align="bottom">
                  <Col xs={24} sm={12} md={24} lg={12}>
                    <p
                      className="font-large-1 text-bold-700"
                      style={{ marginBottom: 0 }}
                    >
                      3
                    </p>
                  </Col>
                  <Col xs={24} sm={12} md={24} lg={12} align="right">
                    <Button
                      disabled={this.state.progress.progress < 3 ? true : false}
                      onClick={this.showModal}
                      type="link"
                    >
                      Selengkapnya
                    </Button>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          {this.state.participantLinks && this.state.participantLinks !== "-" ?
            (<Row style={{ marginTop: 24 }} gutter={[12]}>
              <Col span={24}>
                <Card
                  style={{
                    borderRadius: "5px",
                    height: "100%",
                    overflow: "hidden",
                    padding: "2%",
                  }}
                >
                  <p className="font-medium-1 text-bold-700">
                    Link Grup Whatsapp
                  </p>
                  <Divider style={{ marginTop: 0, borderColor: "#bebebe" }} />
                  <p className="font-normal">
                    Scan Untuk Bergabung Grup Whatsapp
                  </p>
                  <QRCode size={150} value={this.state.participantLinks} />
                  <br />
                  <span className="font-normal">Link: </span>{" "}
                  <a href={this.state.participantLinks} className="font-normal">
                    {this.state.participantLinks}
                  </a>
                </Card>
              </Col>
            </Row>) : (<></>)
          }
          <Row style={{ marginTop: 24 }} gutter={[12]}>
            <Col span={24}>
              <Card
                style={{
                  borderRadius: "5px",
                  height: "100%",
                  overflow: "hidden",
                  padding: "2%",
                }}
              >
                <Row>
                  <Col span={12}>
                    <p className="font-medium-1 text-bold-700">Pengumuman</p>
                  </Col>
                  <Col span={12} align="right">
                    <Link to="/profile/pengumuman">
                      <Button type="link">Selengkapnya</Button>
                    </Link>
                  </Col>
                  <Divider style={{ marginTop: 0, borderColor: "#bebebe" }} />
                </Row>
                {this.state.announcement.map((item) => {
                  return (
                    <>
                      <Row>
                        <p
                          className="font-normal text-bold-700"
                          style={{ marginBottom: 0 }}
                        >
                          {item.title}
                        </p>
                      </Row>

                      <Row>
                        <p className="font-small-2">
                          {moment(item.created_at).format("LL")}
                        </p>
                      </Row>
                      <Row>
                        <p className="font-normal">{item.content}</p>
                      </Row>
                      <Divider style={{ marginTop: 0 }} />
                    </>
                  );
                })}
              </Card>
            </Col>
          </Row>

          <Row gutter={[12]}>
            <Col style={{ marginTop: 24 }} xs={24} sm={24} md={24} lg={12}>
              <Card
                style={{
                  borderRadius: "5px",
                  height: "100%",
                  overflow: "hidden",
                  padding: "2%",
                }}
              >
                <Row>
                  <Col span={12}>
                    <p className="font-medium-1 text-bold-700">
                      Jadwal Pelaksanaan
                    </p>
                  </Col>
                  <Col span={12} align="right">
                    <Button onClick={this.handleDownload} type="link">
                      Unduh Petunjuk Teknis
                    </Button>
                  </Col>
                </Row>
                <Divider style={{ marginTop: 0, borderColor: "#bebebe" }} />
                {this.state.schedule.map((item) => {
                  return (
                    <Row>
                      <Col span={9}>
                        <p className="font-normal">{item.name}</p>
                      </Col>
                      <Col span={15}>
                        <p className="font-normal">
                          {": "}
                          {moment(item.start_date).isSame(moment(item.end_date))
                            ? moment(item.start_date).format("ll") +
                              " " +
                              moment(item.start_date).format("LT")
                            : moment(item.start_date).format("ll") +
                              " " +
                              moment(item.start_date).format("LT") +
                              " - " +
                              moment(item.end_date).format("ll") +
                              " " +
                              moment(item.end_date).format("LT")}
                        </p>
                      </Col>
                    </Row>
                  );
                })}
              </Card>
            </Col>
            <Col style={{ marginTop: 24 }} xs={24} sm={24} md={24} lg={12}>
              <Card
                style={{
                  borderRadius: "5px",
                  height: "100%",
                  overflow: "hidden",
                  padding: "2%",
                }}
              >
                <Row>
                  <Col span={12}>
                    <p className="font-medium-1 text-bold-700">
                      Hasil Olimpiade
                    </p>
                  </Col>
                  <Col span={12} align="right">
                    <Link to="/profile/result">
                      <Button type="link">Selengkapnya</Button>
                    </Link>
                  </Col>
                </Row>
                <Divider style={{ marginTop: 0, borderColor: "#bebebe" }} />
                {this.state.competitionSchedule.map((item) => (
                  <Row gutter={[12]}>
                    <Col span={24}>
                      <p className="font-medium-1">
                        {item.name}
                        {" : "}
                        {item.rank}
                      </p>
                    </Col>
                  </Row>
                ))}
              </Card>
            </Col>
          </Row>
        </Card>
        <ManageModal ref={this._modalManage} />
      </React.Fragment>
    );
  }
}
export default Ringkasan;
