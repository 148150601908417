import React from "react";
import {
  Row,
  Col,
  Card,
  Divider,
  Pagination,
  Input,
  DatePicker,
  Empty,
} from "antd";
import { announcementService } from "../../../services/announcement.service";
import moment from "moment-timezone";
import "moment/locale/id";
const { Search } = Input;
moment.locale("id");

class Pengumuman extends React.Component {
  state = {
    data: [],
    total: 0,
    page: 1,
    limit: 10,
    isLoading: true,
    startDate: "",
    endDate: "",
    value: "",
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getData(true);
  }

  getData(showLoading = false) {
    this.setState({ isLoading: showLoading });
    announcementService
      .getAnnouncement(
        this.state.page,
        this.state.limit,
        this.state.value,
        this.state.startDate,
        this.state.endDate
      )
      .then(
        (resp) => {
          if (resp.status) {
            this.setState({
              data: resp.data,
              total: resp.total,
              isLoading: false,
            });
          }
        },
        (error) => {
          this.setState({ isLoading: false });
        }
      );
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.page !== this.state.page ||
      prevState.value !== this.state.value ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate
    ) {
      this.getData(false);
    }
  }
  onChange = (page) => {
    this.setState({ page: page });
  };
  changeDate = (dates, type) => {
    if (type === "startDate") {
      let startDate = moment(dates._d).format("YYYY-MM-DD 00:01");
      this.setState({ startDate });
    } else {
      let endDate = moment(dates._d).format("YYYY-MM-DD 23:59");
      this.setState({ endDate });
    }
  };

  handleFilter = (e) => {
    let value = e.target.value;
    this.setState({ value });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.data.length < 5 ? (
          <Card
            headStyle
            style={{
              backgroundColor: "#e8e8e8",
              minHeight: window.innerHeight,
              height: "auto",
              padding: "-5%",
            }}
          >
            <div style={{ marginTop: 20 }}>
              <div>
                <Card
                  style={{
                    borderRadius: "5px",
                    height: "100%",
                    overflow: "hidden",
                  }}
                  loading={this.state.isLoading}
                >
                  <React.Fragment>
                    <Row gutter={[16]}>
                      <Col
                        style={{ marginTop: 16 }}
                        xs={24}
                        sm={12}
                        md={6}
                        lg={6}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          placeholder="Tanggal Mulai"
                          onChange={(date) =>
                            this.changeDate(date, "startDate")
                          }
                        ></DatePicker>
                      </Col>
                      <Col
                        style={{ marginTop: 16 }}
                        xs={24}
                        sm={12}
                        md={6}
                        lg={6}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          placeholder="Tanggal Berakhir"
                          onChange={(date) => this.changeDate(date)}
                        ></DatePicker>
                      </Col>
                      <Col
                        style={{ marginTop: 16 }}
                        xs={24}
                        sm={24}
                        md={12}
                        lg={12}
                      >
                        <Search
                          placeholder="Cari pengumuman"
                          allowClear
                          onChange={(e) => this.handleFilter(e)}
                          size="middle"
                          style={{
                            color: "#555597",
                            height: 30,
                            width: "100%",
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    {this.state.data.map((item) => (
                      <>
                        <div
                          style={{
                            paddingRight: "3%",
                            paddingLeft: "3%",
                            marginTop: 12,
                          }}
                        >
                          <Row>
                            <p className="font-normal text-bold-700">
                              {item.title}
                            </p>
                          </Row>
                          <Row>
                            <p className="font-small-2">
                              {moment(item.created_at).format("LL")}
                            </p>
                          </Row>
                          <Row>
                            <p className="font-normal">{item.content}</p>
                          </Row>
                          <Divider
                            style={{ margin: 0, borderColor: "#bebebe" }}
                          />
                        </div>
                      </>
                    ))}
                  </React.Fragment>
                  {this.state.data.length > 0 && (
                    <Pagination
                      responsive={true}
                      style={{ textAlign: "center", marginTop: "5%" }}
                      defaultCurrent={1}
                      total={this.state.total}
                      showSizeChanger={false}
                      onChange={this.onChange}
                    />
                  )}

                  {this.state.data.length === 0 && (
                    <Empty
                      style={{ marginTop: "3%", marginBottom: "3%" }}
                      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                      imageStyle={{
                        height: 80,
                      }}
                      description={<span>Tidak ada data</span>}
                    ></Empty>
                  )}
                </Card>
              </div>
            </div>
          </Card>
        ) : (
          <Card
            headStyle
            style={{
              backgroundColor: "#e8e8e8",
              minHeight: window.innerHeight,
              height: "auto",
              padding: "-5%",
              display: "fixed",
            }}
          >
            <div style={{ marginTop: 20 }}>
              <div>
                <Card
                  style={{
                    borderRadius: "5px",
                    height: "100%",
                    overflow: "hidden",
                  }}
                  loading={this.state.isLoading}
                >
                  <React.Fragment>
                    <Row style={{ padding: "2%" }}>
                      <Col span={7}>
                        <DatePicker
                          style={{ width: "94%" }}
                          placeholder="Tanggal Mulai"
                          onChange={(date) =>
                            this.changeDate(date, "startDate")
                          }
                        ></DatePicker>
                      </Col>
                      <Col span={7}>
                        <DatePicker
                          style={{ width: "94%" }}
                          placeholder="Tanggal Berakhir"
                          onChange={(date) => this.changeDate(date)}
                        ></DatePicker>
                      </Col>
                      <Col span={10}>
                        <Search
                          placeholder="Cari pengumuman"
                          allowClear
                          onChange={(e) => this.handleFilter(e)}
                          size="middle"
                          style={{
                            color: "#555597",
                            height: 30,
                            width: "100%",
                          }}
                        />
                      </Col>
                    </Row>
                    {this.state.data.map((item) => (
                      <>
                        <div style={{ padding: "3%" }}>
                          <Row>
                            <p className="font-normal text-bold-700">
                              {item.title}
                            </p>
                          </Row>
                          <Row>
                            <p className="font-small-2">
                              {moment(item.created_at).format("LL")}
                            </p>
                          </Row>
                          <Row>
                            <p className="font-normal">{item.content}</p>
                          </Row>
                        </div>
                      </>
                    ))}
                  </React.Fragment>
                  {this.state.data.length > 0 && (
                    <Pagination
                      responsive={true}
                      style={{ textAlign: "center", marginTop: "5%" }}
                      defaultCurrent={1}
                      total={this.state.total}
                      showSizeChanger={false}
                      onChange={this.onChange}
                    />
                  )}

                  {this.state.data.length === 0 && (
                    <Empty
                      style={{ marginTop: "3%", marginBottom: "3%" }}
                      image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                      imageStyle={{
                        height: 80,
                      }}
                      description={<span>Tidak ada data</span>}
                    ></Empty>
                  )}
                </Card>
              </div>
            </div>
          </Card>
        )}
      </React.Fragment>
    );
  }
}
export default Pengumuman;
