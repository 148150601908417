import React from "react";
import "antd/dist/antd.css";
import {
  Row,
  Col,
  Table,
  Select,
  Card,
  BackTop,
  Pagination,
  Empty,
} from "antd";
import Search from "antd/lib/input/Search";
import { scoreService } from "../../services/score.service";
import { scheduleService } from "../../services/schedule.services";
import { categoryService } from "../../services/category.services";
import { ArrowUpOutlined } from "@ant-design/icons";

const { Option } = Select;

function onBlur() {}

function onFocus() {}

function onSearch(val) {}
const CustomHeader = (props) => {
  return (
    <React.Fragment>
      <div style={{ marginTop: "2%", marginBottom: "3%" }}>
        <Row gutter={[16]}>
          <Col style={{ marginTop: 16 }} xs={24} sm={12} md={8} lg={8}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Tahap Pelaksanaan"
              optionFilterProp="children"
              onChange={props.onPhase}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
            >
              {props.phase.map((item) => (
                <Option value={item.value}>{item.label}</Option>
              ))}
            </Select>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={12} md={8} lg={8}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Kategori"
              optionFilterProp="children"
              onChange={props.onCategory}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
            >
              {props.category.map((item) => (
                <Option value={item.value}>{item.label}</Option>
              ))}
            </Select>
          </Col>

          <Col style={{ marginTop: 16 }} xs={24} sm={24} md={8} lg={8}>
            <div className="form-control-position pr-3">
              <Search
                placeholder="Cari nama peserta, nisn, atau nomor registrasi"
                allowClear
                size="middle"
                onChange={(e) => props.handleFilter(e)}
                style={{
                  color: "#555597",
                  height: 30,
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
class Score extends React.Component {
  state = {
    columns: [
      {
        title: "Nama",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "No. Peserta",
        dataIndex: "registration_number",
        key: "registration_number",
        responsive: ["md"],
      },
      {
        title: "Benar",
        dataIndex: "answer_right",
        key: "answer_right",
        responsive: ["md"],
      },
      {
        title: "Salah",
        dataIndex: "answer_wrong",
        key: "answer_wrong",
        responsive: ["md"],
      },
      {
        title: "Tidak Dijawab",
        dataIndex: "not_answered",
        key: "noot_answered",
        responsive: ["md"],
      },
      {
        title: "Nilai",
        dataIndex: "score",
        key: "score",
      },
      {
        title: "Peringkat",
        key: "rank",
        dataIndex: "rank",
      },
    ],
    data: [],
    phase: [],
    phaseId: -1,
    categories: [],
    categoryId: -1,
    value: "",
    page: 1,
    limit: 10,
    total: 0,
    isLoading: true,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    scheduleService.getScheduleScore(1, 1000, "competition").then((resp) => {
      let data = resp.data.map((item) => {
        return { name: "phaseId", label: item.name, value: item.id };
      });
      data.unshift({ name: "phaseId", label: "Semua Jadwal", value: -1 });
      this.setState({ phase: data, isLoading: false });
    });

    categoryService.getCategory().then((resp) => {
      let data = resp.data.map((item) => {
        return { name: "categoryId", label: item.name, value: item.id };
      });
      data.unshift({ name: "categoryId", label: "Semua Kategori", value: -1 });
      this.setState({ categories: data, isLoading: false });
    });
    this.getData(true);
  }

  getData(showLoading = false) {
    this.setState({ isLoading: showLoading });
    scoreService
      .getScore(
        this.state.page,
        this.state.limit,
        this.state.value,
        this.state.categoryId,
        this.state.phaseId,
        ""
      )
      .then(
        (resp) => {
          if (resp.status) {
            this.setState({
              data: resp.data,
              total: resp.total,
              isLoading: false,
            });
          }
        },
        (error) => {
          this.setState({ isLoading: false });
        }
      );
  }

  componentDidUpdate(prevProps, prevState) {
    //page change call and rows count
    if (
      prevProps.reload !== this.props.reload ||
      prevState.value !== this.state.value ||
      prevState.categoryId !== this.state.categoryId ||
      prevState.phaseId !== this.state.phaseId ||
      prevState.page !== this.state.page ||
      prevState.limit !== this.state.limit
    ) {
      this.getData(false);
    }
  }
  onPhase = (item) => {
    this.setState({ phaseId: item });
  };

  onCategory = (item) => {
    this.setState({ categoryId: item });
  };

  handleFilter = (e) => {
    let value = e.target.value;
    this.setState({ value });
  };

  onChange = (page, pageSize) => {
    this.setState({ page: page, limit: pageSize });
  };

  render() {
    let { data, columns, value } = this.state;
    let table = {
      data: data,
      total: this.state.total,
      columns: columns,
      isLoading: this.state.isLoading,
    };
    let filtering = {
      emptyText: (
        <>
          <p
            className="font-medium-1 text-bold-700"
            style={{ marginTop: "3%", color: "black" }}
          >
            Atur Filtering Terlebih Dahulu
          </p>
          <span style={{ color: "black" }}>
            Pilih Tahap Pelaksanaan dan Kategori
          </span>
        </>
      ),
    };
    let emptyData = {
      emptyText: (
        <Empty
          style={{ marginTop: "3%", marginBottom: "3%" }}
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 80,
          }}
          description={<span style={{ color: "black" }}>Tidak ada data</span>}
        ></Empty>
      ),
    };
    return (
      <React.Fragment>
        {table.data < 5 ? (
          <Card
            headStyle
            style={{
              backgroundColor: "#e8e8e8",
              minHeight: window.innerHeight,
              textAlign: "center",
              height: "auto",
            }}
          >
            <Card
              style={{
                borderRadius: "5px",
                width: "100%",
                marginTop: 64,
                textAlign: "center",
              }}
            >
              <div>
                <CustomHeader
                  //Jadwal
                  data={data}
                  columns={table.columns}
                  phase={this.state.phase}
                  onPhase={this.onPhase}
                  phases={this.state.phase.value}
                  //Kategori
                  category={this.state.categories}
                  onCategory={this.onCategory}
                  categories={this.state.categories.value}
                  //Search
                  handleFilter={this.handleFilter}
                  value={value}
                />
                <Table
                  columns={table.columns}
                  dataSource={table.data}
                  pagination={false}
                  loading={table.isLoading}
                  locale={
                    this.state.categoryId && this.state.phaseId === -1
                      ? filtering
                      : emptyData
                  }
                  scroll={{ x: true }}
                />
                {table.total > 0 && (
                  <Pagination
                    responsive={true}
                    style={{
                      textAlign: "center",
                      marginTop: "5%",
                      color: "black",
                    }}
                    defaultCurrent={1}
                    total={table.total}
                    showSizeChanger={true}
                    showTotal={(total, range) =>
                      `${range[0]}-${range[1]} dari ${total} data`
                    }
                    onChange={this.onChange}
                  />
                )}
                {/* {table.total === 0 &&
              <Empty>lakukan</Empty>
              } */}
              </div>
            </Card>
          </Card>
        ) : (
          <Card
            headStyle
            style={{
              backgroundColor: "#e8e8e8",
              minHeight: window.innerHeight,
              textAlign: "center",
              height: "auto",
            }}
          >
            <Card
              style={{
                borderRadius: "5px",
                width: "100%",
                marginTop: "7%",
                marginBottom: "12%",
                textAlign: "center",
              }}
            >
              <div>
                <CustomHeader
                  //Jadwal
                  data={data}
                  columns={table.columns}
                  phase={this.state.phase}
                  onPhase={this.onPhase}
                  phases={this.state.phase.value}
                  //Kategori
                  category={this.state.categories}
                  onCategory={this.onCategory}
                  categories={this.state.categories.value}
                  //Search
                  handleFilter={this.handleFilter}
                  value={value}
                />
                <Table
                  columns={table.columns}
                  dataSource={table.data}
                  pagination={false}
                  loading={table.isLoading}
                  locale={
                    this.state.categoryId && this.state.phaseId === -1
                      ? filtering
                      : emptyData
                  }
                  scroll={{ x: true }}
                />
                {table.total > 0 && (
                  <Pagination
                    responsive={true}
                    style={{
                      textAlign: "center",
                      marginTop: "5%",
                      color: "black",
                    }}
                    defaultCurrent={1}
                    total={table.total}
                    showSizeChanger={true}
                    showTotal={(total, range) =>
                      `${range[0]}-${range[1]} dari ${total} data`
                    }
                    onChange={this.onChange}
                  />
                )}
                {/* {table.total === 0 &&
              <Empty>lakukan</Empty>
              } */}
              </div>
            </Card>
          </Card>
        )}
        <BackTop>
          <div
            style={{
              height: 40,
              width: 40,
              lineHeight: "40px",
              backgroundColor: "#1088e9",
              color: "#fff",
              textAlign: "center",
            }}
          >
            <ArrowUpOutlined />
          </div>
        </BackTop>
      </React.Fragment>
    );
  }
}

export default Score;
