import React from "react";
import { Modal, Form, Input, Button } from "antd";
import RegisterModal from "./RegisterModal";
import { userService } from "../../services/user.services";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { Link } from "react-router-dom";

class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
  }
  state = {
    isOpen: false,
    username: "",
    password: "",
    data: null,
    type: "",
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  showModal = (type) => {
    this.setState({ type: type });
    this._modalManage.current.toggleModal();
    this.toggleModal();
  };

  handleCancel = () => {
    this.setState({ isOpen: false });
    if (this.state.type !== "") {
      this.setState({ isOpen: false });
    }
  };

  onUpdated = (resp) => {
    if (resp.status) {
      this.toggleModal();
      this.setState({
        isLoading: false,
        data: null,
        nisn: "",
        username: "",
        password: "",
      });
      localStorage.clear();
      localStorage.setItem("USER_TOKEN", resp.data);
      const userDecode = jwtDecode(resp.data);
      localStorage.setItem("USER_DATA", JSON.stringify(userDecode));
      toast.success(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: true,
        autoClose: 2000,
      });
      setTimeout(function () {
        window.location.assign(
          JSON.parse(localStorage.getItem("USER_DATA")).role === "admin"
            ? "/profile/admin/ringkasanAdmin"
            : "/profile/ringkasan"
        );
      }, 3000);
    } else {
      toast.error(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: true,
        autoClose: 2000,
      });
    }
  };

  handleSubmit = (e) => {
    userService
      .login({
        username: this.state.username,
        password: this.state.password,
      })
      .then(
        (user) => {
          this.onUpdated(user);
        },
        (error) => {
          this.onUpdated({
            isUpdated: false,
            status: false,
            message: error,
            data: null,
          });
        }
      );
  };

  render() {
    let data = this.state;
    return (
      <React.Fragment>
        <Modal
          title={null}
          centered
          visible={this.state.isOpen}
          toggle={this.toggleModal}
          footer={null}
          style={{ width: "100%", resize: "none" }}
          onCancel={this.handleCancel}
        >
          <Form onFinish={(e) => this.handleSubmit(e)}>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                textAlign: "center",
                marginBottom: "2%",
              }}
            >
              Hi, Selamat Datang!
            </p>
            <Form.Item
              style={{ marginTop: "7%" }}
              label="Username"
              name="username"
              value={data.username}
              onChange={(e) => this.setState({ username: e.target.value })}
              rules={[
                {
                  required: true,
                  message: "Masukkan NISN!",
                },
              ]}
            >
              <Input placeholder="Masukkan NISN" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              value={data.password}
              onChange={(e) => this.setState({ password: e.target.value })}
              rules={[
                {
                  required: true,
                  message: "Masukkan Password!",
                },
              ]}
            >
              <Input.Password placeholder="Masukkan Password" />
            </Form.Item>
            {/* <ButtonContainer> */}
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={(e) => this.handleSubmit()}
            >
              Login
            </Button>
            {/* </ButtonContainer> */}
            <div>
              <p
                className="font-normal"
                style={{ textAlign: "center", marginTop: "2%" }}
              >
                Belum punya akun?
                <Link to="/register">
                  <Button
                    onClick={this.handleCancel}
                    type="link"
                    style={{ textAlign: "center" }}
                  >
                    Daftar Disini
                  </Button>
                </Link>
              </p>
            </div>
          </Form>
        </Modal>
        <RegisterModal ref={this._modalManage} />
      </React.Fragment>
    );
  }
}

export default LoginModal;
