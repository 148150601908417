import React from "react";
import moment from "moment";
import {
  Card,
  Col,
  Row,
  Button,
  Input,
  Select,
  Tooltip,
  DatePicker,
  Statistic
} from "antd";
import { jwtDecode } from "jwt-decode";
import ManageModal from "../Profile/Admin/Participants/School/ManageModal";
import { toast } from "react-toastify";
import { participantService } from "../../services/participant.service";
import { categoryService } from "../../services/category.services";
import { addressService } from "../../services/address.services";
import InfoModal from "../Profile/User/Biodata/InfoModal";
import { userService } from "../../services/user.services";
import { scheduleService } from "../../services/schedule.services";

const { Option } = Select;
const { Countdown } = Statistic;

function onBlur() {}

function onFocus() {}

function onSearch(val) {}
class Register extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
    this._modalInfo = React.createRef();
  }
  state = {
    schedule: [],
    isLoading: false,
    visible: false,
    school: [],
    data: null,
    categories: [],
    listAddress: [],
    address: [],
    birth_place: [],
    page: 1,
    limit: 1000,
    value: "",
    progress: 1,
    errName: "",
    errNisn: "",
    errBirth_date: "",
    errBirth_place: "",
    errGender: "",
    errPhone: "",
    errGrade: "",
    errSchool_id: "",
    errCattegory_id: "",
    errPassword: "",
    errAddress_detail: "",
    errAddress_id: "",

    body: {
      name: "",
      nisn: "",
      birth_date: "",
      birth_place: "",
      gender: "",
      phone_number: "",
      school_id: -1,
      category_id: -1,
      password: "",
      address_detail: "",
      address_id: -1,
      grade: "",
    },
  };

  showModal = () => {
    this._modalManage.current.toggleModal();
  };

  onUpdated = (resp) => {
    if (resp.status) {
      toast.success(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: true,
        autoClose: 2000,
      });
      participantService
        .getSchool(this.state.page, this.state.limit, this.state.value)
        .then((resp) => {
          let data = resp.data.map((item) => {
            return { name: "schoolId", label: item.name, value: item.id };
          });
          data.unshift({ name: "schoolId", label: "Semua Sekolah", value: -1 });
          this.setState({ school: data, isLoading: false });
        });
    } else
      toast.error(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
  };

  onSuccessRegister = (resp) => {
    localStorage.clear();
    localStorage.setItem("USER_TOKEN", resp.data);
    const userDecode = jwtDecode(resp.data);
    localStorage.setItem("USER_DATA", JSON.stringify(userDecode));
    setTimeout(function () {
      window.location.assign(
        JSON.parse(localStorage.getItem("USER_DATA")).role === "admin"
          ? "/profile/admin/ringkasan"
          : "/profile/ringkasan"
      );
    }, 3000);
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };
  handleChange = (type, e) => {
    if (type === "city") {
      let data = this.state.body;
      data.city = e;

      this.setState({ address: [] });
      const fields = e.split(", ");
      const city = fields[0];
      const province = fields[1];
      const page = 1;
      const limit = 1000;

      addressService.getAddress(city, province, page, limit).then((resp) => {
        let data = resp.data.map((item) => {
          return { name: "addressId", label: item.district, value: item.id };
        });
        data.unshift({
          name: "addressId",
          label: "Semua Kecamatan",
          value: -1,
        });
        this.setState({ address: data });
      });
    } else if (type === "district") {
      const fields = e.split(",");
      const district = fields[1];
      const address_id = fields[0];

      let data = this.state.body;
      data.district = district;
      this.setState({ data });

      data.address_id = parseInt(address_id);
      this.setState({ data, errAddress_id: "" });
    }
  };
  onChange(date, dateString) {
    let body = this.state.body;
    body.birth_date = dateString;
    this.setState({ body, errBirth_date: "" });
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.getData(true);

    scheduleService.getScheduleScore(1, 1, "registration").then(
      (resp) => {
        if (resp.status) {
          this.setState({
            schedule: resp.data,
            isLoading: false,
          });
        }
      }, (error) => { }
    );

    categoryService.getCategory().then((resp) => {
      let data = resp.data.map((item) => {
        return { name: "categoryId", label: item.name, value: item.id };
      });
      data.unshift({ name: "categoryId", label: "Semua Kategori", value: -1 });
      this.setState({ categories: data, isLoading: false });
    });
    addressService.getListAddress(1, 1000).then((resp) => {
      let data = resp.data.map((item) => {
        return {
          name: "addressId",
          label: item.city + ", " + item.province,
          value: item.id,
        };
      });
      let birthPlace = resp.data.map((item) => {
        return { name: "birthPlace", label: item.city, value: item.city };
      });
      data.unshift({ name: "addressId", label: "Semua Kota", value: -1 });
      this.setState({
        listAddress: data,
        birth_place: birthPlace,
        isLoading: false,
      });
    });
  }

  getData() {
    participantService
      .getSchool(this.state.page, this.state.limit, this.state.value)
      .then((resp) => {
        let data = resp.data.map((item) => {
          return { name: "schoolId", label: item.name, value: item.id };
        });
        data.unshift({ name: "schoolId", label: "Semua Sekolah", value: -1 });
        this.setState({ school: data, isLoading: false });
      });
  }

  handleSubmit = (e) => {
    if (!this.state.body.name) this.setState({ errName: "Nama Wajib Diisi!" });
    if (!this.state.body.nisn) this.setState({ errNisn: "NISN Wajib Diisi!" });
    if (!this.state.body.phone_number)
      this.setState({ errPhone: "No Telepon Wajib Diisi!" });
    if (!this.state.body.grade)
      this.setState({ errGrade: "Kelas Wajib Diisi!" });
    if (!this.state.body.birth_place)
      this.setState({ errBirth_place: "Tempat Lahir Wajib Diisi!" });
    if (!this.state.body.birth_date)
      this.setState({ errBirth_date: "Tanggal Lahir Wajib Diisi!" });
    if (!this.state.body.gender)
      this.setState({ errGender: "Jenis Kelamin Wajib Diisi!" });
    if (!this.state.body.address_detail)
      this.setState({ errAddress_detail: "Alamat Lengkap Wajib Diisi!" });
    if (this.state.body.address_id === -1)
      this.setState({ errAddress_id: "Alamat harus diisi" });
    if (this.state.body.school_id === -1)
      this.setState({ errSchool_id: "Asal Sekolah Wajib Diisi!" });
    if (this.state.body.category_id === -1)
      this.setState({ errCategory_id: "Kategori Harus Diisi!" });
    if (this.state.body.password === "")
      this.setState({ errPassword: "Password Wajib Diisi!" });

    let body = this.state.body;
    delete body.district;
    delete body.city;
    userService.register(body).then(
      (resp) => {
        this.onSuccessRegister(resp);
      },

      (error) => {
        this.onUpdated({
          isUpdated: false,
          status: false,
          message: error,
          data: null,
        });
      }
    );
  };


  render() {
    let state = this.state;
    let body = this.state.body;
    let registerSchedule = this.state.schedule?.length > 0 ? this.state.schedule[0] : {};

    return (
      <React.Fragment>
        <Row
          style={{
            paddingTop: "5%",
            paddingBottom: "5%",
            backgroundColor: "#e8e8e8",
          }}
        >
          <Col xs={0} sm={0} md={5} lg={5} ></Col>
          <Col xs={24} sm={24} md={14} lg={14} style={{ 
            display: registerSchedule.start_date ? ( moment().isAfter(registerSchedule.start_date) ? "none" : "block" ) : "none",
            textAlign: "center",
            height: "70vh",
            marginTop: "30vh"
          }}>
          <div className="font-medium-1">
                <h1>Pendaftaran Akan Dibuka Dalam </h1>
              </div>
            <Countdown
              style={{ fontSize: "1000px" }}
              value={moment(registerSchedule.start_date)}
              format="D hari HH:mm:ss detik"
              onFinish={() => {
                window.location.reload();
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={14} lg={14} style={{display: moment().isAfter(registerSchedule.start_date) ? "block" : "none"}}>
            <Card
              gutter={[16]}
              style={{
                borderRadius: "5px",
                height: "100%",
                overflow: "hidden",
                paddingTop: "5%",
              }}
            >
              <Row style={{ marginTop: 12 }} gutter={[4]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    {" "}
                    NISN <span style={{ color: "red" }}> *</span>
                  </p>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18}>
                  <Input
                    type="text"
                    placeholder="Masukkan NISN"
                    name="nisn"
                    value={body.nisn}
                    onChange={(e) => {
                      body.nisn = e.target.value;
                      this.setState({ body, errNisn: "" });
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <span style={{ color: "red" }}>{this.state.errNisn}</span>
                </Col>
              </Row>
              <Row style={{ marginTop: 12 }} gutter={[4]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    {" "}
                    Nama <span style={{ color: "red" }}> *</span>{" "}
                  </p>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18}>
                  <Input
                    style={{ width: "100%" }}
                    type="text"
                    placeholder="Masukkan Nama Lengkap"
                    name="firstName"
                    value={body.name}
                    onChange={(e) => {
                      body.name = e.target.value;
                      this.setState({ body, errName: "" });
                    }}
                  />
                  <span style={{ color: "red" }}>{this.state.errName}</span>
                </Col>
              </Row>
              <Row gutter={[16]}>
                <Col style={{ marginTop: 12 }} xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    Tempat, Tanggal Lahir
                    <span style={{ color: "red" }}> *</span>
                  </p>
                </Col>
                <Col style={{ marginTop: 12 }} xs={24} sm={10} md={10} lg={10}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Semua Kota"
                    optionFilterProp="children"
                    onChange={(e) => {
                      body.birth_place = e;
                      this.setState({ body, errBirth_place: "" });
                    }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    value={body.birth_place || undefined}
                  >
                    {state &&
                      state.birth_place.map((item) => (
                        <Option value={item.label}>{item.label}</Option>
                      ))}
                  </Select>
                  <span style={{ color: "red" }}>
                    {this.state.errBirth_place}
                  </span>
                </Col>
                <Col style={{ marginTop: 12 }} xs={24} sm={8} md={8} lg={8}>
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="Tanggal Lahir"
                    value={body?.birth_date ? moment(body.birth_date) : ""}
                    onChange={(date, dateString) =>
                      this.onChange(date, dateString)
                    }
                  ></DatePicker>
                  <span style={{ color: "red" }}>
                    {this.state.errBirth_date}
                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: 12 }} gutter={[4]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    {" "}
                    No. Telepon
                    <span style={{ color: "red" }}> *</span>
                  </p>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18}>
                  <Input
                    type="number"
                    placeholder="Masukkan No Telepon"
                    name="phone"
                    value={body.phone_number}
                    onChange={(e) => {
                      body.phone_number = e.target.value;
                      this.setState({ body, errPhone: "" });
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  <span style={{ color: "red" }}>{this.state.errPhone}</span>
                </Col>
              </Row>
              <Row style={{ marginTop: 12 }} gutter={[4]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    {" "}
                    Jenis Kelamin <span style={{ color: "red" }}> *</span>{" "}
                  </p>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Jenis Kelamin"
                    optionFilterProp="children"
                    onChange={(e) => {
                      body.gender = e;
                      this.setState({ body, errGender: "" });
                    }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    value={body.gender || undefined}
                  >
                    <Option value="male">Laki-Laki</Option>
                    <Option value="female">Perempuan</Option>
                  </Select>
                  <span style={{ color: "red" }}>{this.state.errGender}</span>
                </Col>
              </Row>
              <Row gutter={[16]}>
                <Col style={{ marginTop: 12 }} xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    Asal Sekolah <span style={{ color: "red" }}> *</span>
                  </p>
                </Col>
                <Col style={{ marginTop: 12 }} xs={24} sm={12} md={12} lg={12}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Asal Sekolah"
                    optionFilterProp="children"
                    onChange={(e) => {
                      body.school_id = e;
                      this.setState({ body, errSchool_id: "" });
                    }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    value={body.school_id}
                  >
                    {state &&
                      state.school.map((item) => (
                        <Option value={item.value}>{item.label}</Option>
                      ))}
                  </Select>
                  <span style={{ color: "red" }}>
                    {this.state.errSchool_id}
                  </span>
                </Col>
                <Col style={{ marginTop: 12 }} xs={24} sm={6} md={6} lg={6}>
                  <Tooltip title="Tambah sekolah jika sekolahmu tidak ada di list">
                    <Button
                      style={{ width: "100%" }}
                      onClick={(e) => this.showModal()}
                    >
                      + Sekolah
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
              <Row style={{ marginTop: 12 }} gutter={[4]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    {" "}
                    Kelas <span style={{ color: "red" }}> *</span>{" "}
                  </p>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Kelas"
                    optionFilterProp="children"
                    onChange={(e) => {
                      body.grade = e;
                      this.setState({ body, errGrade: "" });
                    }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    value={body.grade || undefined}
                  >
                    <Option value={"4"}>4</Option>
                    <Option value={"5"}>5</Option>
                    <Option value={"6"}>6</Option>
                  </Select>
                  <span style={{ color: "red" }}>{this.state.errGrade}</span>
                </Col>
              </Row>
              <Row style={{ marginTop: 12 }} gutter={[4]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    {" "}
                    Kategori <span style={{ color: "red" }}> *</span>{" "}
                  </p>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Kategori"
                    optionFilterProp="children"
                    onChange={(e) => {
                      body.category_id = e;
                      this.setState({ body, errCategory_id: "" });
                    }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    value={body.category_id}
                  >
                    {state &&
                      state.categories.map((item) => (
                        <Option value={item.value}>{item.label}</Option>
                      ))}
                  </Select>
                  <span style={{ color: "red" }}>
                    {this.state.errCategory_id}
                  </span>
                </Col>
              </Row>

              <Row gutter={[16]}>
                <Col style={{ marginTop: 12 }} xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    {" "}
                    Password <span style={{ color: "red" }}> *</span>{" "}
                  </p>
                </Col>

                <Col style={{ marginTop: 12 }} xs={24} sm={18} md={18} lg={18}>
                  <Input.Password
                    placeholder="Masukkan Password Minimal 6 Digit"
                    name="password"
                    value={body.password}
                    onChange={(e) => {
                      body.password = e.target.value;
                      this.setState({ body, errPassword: "" });
                    }}
                  />
                  <span style={{ color: "red" }}>{this.state.errPassword}</span>
                </Col>
              </Row>
              <Row gutter={[16]}>
                <Col style={{ marginTop: 12 }} xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    {" "}
                    Alamat <span style={{ color: "red" }}> *</span>{" "}
                  </p>
                </Col>

                <Col style={{ marginTop: 12 }} xs={24} sm={9} md={9} lg={9}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Semua Kota"
                    optionFilterProp="children"
                    onChange={(e) => this.handleChange("city", e)}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    value={body.city}
                  >
                    {state &&
                      state.listAddress.map((item) => (
                        <Option value={item.label}>{item.label}</Option>
                      ))}
                  </Select>
                </Col>
                <Col style={{ marginTop: 12 }} xs={24} sm={9} md={9} lg={9}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    placeholder="Semua Kecamatan"
                    optionFilterProp="children"
                    onChange={(e) => this.handleChange("district", e)}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    onSearch={onSearch}
                    value={body.district}
                  >
                    {state &&
                      state.address.map((item) => (
                        <Option value={item.value + "," + item.label}>
                          {item.label}
                        </Option>
                      ))}
                  </Select>
                  <span style={{ color: "red" }}>
                    {this.state.errAddress_id}
                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: 12 }} gutter={[4]}>
                <Col xs={0} sm={6} md={6} lg={6}></Col>
                <Col xs={24} sm={18} md={18} lg={18}>
                  <Input
                    type="text"
                    placeholder="Masukkan alamat lengkap"
                    name="address"
                    value={body.address_detail}
                    onChange={(e) => {
                      body.address_detail = e.target.value;
                      this.setState({ body, errAddress_detail: "" });
                    }}
                  />
                  <span style={{ color: "red" }}>
                    {this.state.errAddress_detail}
                  </span>
                </Col>
              </Row>
              <div style={{ float: "right", marginTop: "4%" }}>
                <Button
                  loading={this.state.isLoading}
                  type="primary"
                  style={{ float: "right" }}
                  onClick={this.handleSubmit}
                  disabled={body === null}
                >
                  Daftar
                </Button>
              </div>
            </Card>
            <ManageModal ref={this._modalManage} onUpdated={this.onUpdated} />
            <InfoModal ref={this._modalInfo} />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
export default Register;
