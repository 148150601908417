import React from 'react';
import 'antd/dist/antd.css';
import { Menu, Dropdown, Space } from 'antd';
import { DownOutlined, DeleteOutlined } from '@ant-design/icons';

class ActionSchool extends React.Component {
  state = {
    school: this.props.school
}
  editSchool = () => {
    this
        .props
        .edit(this.state.school)
}

deleteSchool = () => {
  this
    .props
    .delete(this.state.school)
}
componentDidUpdate(prevProps, prevState) {
  if(prevProps.school !== this.props.school) {
      this.setState({school: this.props.school})
  }
}
  render() {
    return (
      <Space wrap>
        <Dropdown.Button onClick={e => this.editSchool(e)} placement="bottomCenter" icon={<DownOutlined />}
          overlay={<Menu>
            <Menu.Item key="1" icon={<DeleteOutlined />} onClick={e => this.deleteSchool(e)}>
              Hapus
          </Menu.Item>

          </Menu>}

        >
          Lihat Detail
    </Dropdown.Button>

      </Space>
    )
  }
}

export default ActionSchool;