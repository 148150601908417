import moment from "moment";
import React from "react";
import "antd/dist/antd.css";
import { Row, Col, Pagination, Input, Select, Table, Empty } from "antd";
import ActionParticipants from "./ActionParticipants";
import { participantService } from "../../../../services/participant.service";
import ButtonExport from "../../../../components/ButtonExport";

const { Option } = Select;
const { Search } = Input;

function onBlur() {}

function onFocus() {}

function onSearch(val) {}

const CustomHeader = (props) => {
  let csvData = [];

  for (let item of props.data) {
    if (item.registration_number) {
      csvData.push({
        "Nama Peserta": item.name,
        Kategori: item.category,
        "Username CBT/NISN": "CBT" + item.nisn,
        "Password CBT/No Peserta": item.registration_number,
        "Jenis Kelamin": item.gender === "male" ? "Laki-laki" : "Perempuan",
        "Tempat Lahir": item.birth_place,
        "Tanggal Lahir": item.birth_date,
        "No Telepon": item.phone_number,
        "Asal Sekolah": item.school,
        Kelas: item.grade,
        "Kota/Provinsi": item.city,
        Kecamatan: item.district,
        "Alamat Lengkap": item.address_detail,
      });
    }
  }

  return (
    <React.Fragment>
      <div style={{ marginTop: "2%", marginBottom: "3%" }}>
        <Row gutter={[16]}>
          <Col style={{ marginTop: 16 }} xs={24} sm={8} md={6} lg={6}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Semua Kategori"
              optionFilterProp="children"
              onChange={props.onCategory}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
            >
              {props.category.map((item) => (
                <Option value={item.value}>{item.label}</Option>
              ))}
            </Select>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={8} md={12} lg={14}>
            <div className="form-control-position pr-3">
              <Search
                placeholder="Cari nama peserta, nisn, atau nomor registrasi"
                allowClear
                onChange={(e) => props.handleFilter(e)}
                size="middle"
                style={{
                  color: "#555597",
                  height: 30,
                  width: "100%",
                }}
              />
            </div>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={8} md={6} lg={4}>
            <ButtonExport
              csvData={csvData}
              fileName={"Data Peserta " + moment().format("LL")}
            />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
class Score extends React.Component {
  state = {
    columns: [
      {
        title: "Nama",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "NISN",
        dataIndex: "nisn",
        key: "nisn",
        responsive: ["md"],
      },
      {
        title: "No. Peserta",
        dataIndex: "registration_number",
        key: "registration_number",
        responsive: ["md"],
      },
      {
        title: "Kategori",
        key: "category",
        dataIndex: "category",
        responsive: ["md"],
      },
      {
        title: "Tindakan",
        dataIndex: "",
        key: "x",
        render: (row) => (
          <ActionParticipants
            user={row}
            edit={this.props.edit}
            delete={this.props.delete}
          />
        ),
      },
    ],
    data: [],
    categories: -1,
    value: "",
    page: 1,
    limit: 10,
    total: 0,
  };

  componentDidMount() {
    this.getData(true);
  }
  handleTableChange = (pagination) => {
    this.getData({
      pagination,
    });
  };
  getData(showLoading = false) {
    this.setState({ isLoading: showLoading });
    participantService
      .getParticipant(
        this.state.page,
        this.state.limit,
        this.state.categories,
        this.state.value
      )
      .then(
        (resp) => {
          if (resp.status) {
            this.setState({
              data: resp.data,
              total: resp.total,
              isLoading: false,
              limit: resp.per_page,
            });
          }
        },
        (error) => {
          this.setState({ isLoading: false });
        }
      );
  }
  componentDidUpdate(prevProps, prevState) {
    //page change call and rows count
    if (
      prevProps.reload !== this.props.reload ||
      prevState.categories !== this.state.categories ||
      prevState.page !== this.state.page ||
      prevState.value !== this.state.value ||
      prevState.limit !== this.state.limit
    ) {
      this.getData(false);
    }
  }

  onCategory = (item) => {
    this.setState({ categories: item });
  };

  handleFilter = (e) => {
    let value = e.target.value;
    this.setState({ value });
  };

  onChange = (page, pageSize) => {
    if (isNaN(pageSize)) {
      this.setState({ page: page, limit: 0 });
    } else {
      this.setState({ page: page, limit: pageSize });
    }
  };

  render() {
    let { data, columns, value } = this.state;
    let table = {
      data: data,
      total: this.state.total,
      columns: columns,
      isLoading: this.state.isLoading,
    };

    let emptyData = {
      emptyText: (
        <Empty
          style={{ marginTop: "3%", marginBottom: "3%" }}
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 80,
          }}
          description={<span style={{ color: "black" }}>Tidak ada data</span>}
        ></Empty>
      ),
    };
    return (
      <React.Fragment>
        <CustomHeader
          data={data}
          columns={table.columns}
          //Kategori
          category={this.props.categories}
          onCategory={this.onCategory}
          categories={this.state.categories.value}
          //Search
          handleFilter={this.handleFilter}
          value={value}
        />
        <Table
          columns={table.columns}
          dataSource={table.data}
          pagination={false}
          locale={emptyData}
          loading={table.isLoading}
          scroll={{ x: true }}
        />
        {table.total > 0 && (
          <Pagination
            style={{ textAlign: "center", marginTop: "5%", color: "black" }}
            responsive={true}
            defaultCurrent={1}
            total={table.total}
            showSizeChanger={true}
            pageSize={
              this.state.limit === 0 ? this.state.total : this.state.limit
            }
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} dari ${total} data`
            }
            onChange={this.onChange}
            pageSizeOptions={["10", "20", "50", "100", "semua"]}
          />
        )}
      </React.Fragment>
    );
  }
}

export default Score;
