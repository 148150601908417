import React from 'react';
import 'antd/dist/antd.css';
import { Menu, Dropdown, Space } from 'antd';
import { DownOutlined, DeleteOutlined } from '@ant-design/icons';

class ActionAnnouncement extends React.Component {
  state = {
    announcement: this.props.announcement
}
  editAnnouncement = () => {
    this
        .props
        .edit(this.state.announcement)
}

deleteAnnouncement = () => {
  this
    .props
    .delete(this.state.announcement)
}

  render() {
    return (
      <Space wrap>
        <Dropdown.Button onClick={e => this.editAnnouncement(e)} placement="bottomCenter" icon={<DownOutlined />}
          overlay={<Menu>
            <Menu.Item key="1" icon={<DeleteOutlined />} onClick={e => this.deleteAnnouncement(e)}>
              Hapus
          </Menu.Item>

          </Menu>}

        >
          Lihat Detail
    </Dropdown.Button>

      </Space>
    )
  }
}

export default ActionAnnouncement;