import React from "react";
import { Layout } from "antd";

class Profile extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Layout className="mainLayout">
          {/* <Switch>
                            {AppRoute.map((item, i) => {
                                return (
                                    <Route exact path={item.path} component={item.component} exact />
                                )
                            })}

                        </Switch> */}
          {/* <CardDashboard/> */}
          {/* <Sidebar/> */}
        </Layout>
      </React.Fragment>
    );
  }
}

export default Profile;
