import React from "react";
import { Modal, Form, Button } from "antd";
import { participantService } from "../../../../services/participant.service";
class ManageModal extends React.Component {
  state = {
    isOpen: false,
    data: null,
    link: "https://exam.mtsn1lamongan.sch.id/",
    username: "",
    password: "",
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
    if (this.state.type !== "") {
      this.setState({ isOpen: false });
    }
  };

  handleClick = () => {
    window.open(this.state.link);
  };

  componentDidMount() {
    let userData = localStorage.getItem("USER_DATA");
    let data = JSON.parse(userData);
    participantService.getBiodata(data.id).then(
      (resp) => {
        if (resp.status) {
          this.setState({
            username: resp.data.nisn,
            password: resp.data.registration_number,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          title="Informasi"
          centered
          onCancel={this.handleCancel}
          visible={this.state.isOpen}
          toggle={this.toggleModal}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Batalkan
            </Button>,
            <Button key="submit" type="primary" onClick={this.handleClick}>
              Ya, lanjutkan
            </Button>,
          ]}
          style={{ width: "100%", resize: "none" }}
        >
          <Form>
            <p>
              Kamu bisa login CBT dengan menggunakan username{" "}
              <b>CBT{this.state.username}</b> dan password{" "}
              <b>{this.state.password}</b>. Selamat mengerjakan!
            </p>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ManageModal;
