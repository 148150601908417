import React, {useState, useEffect} from "react"
import {Button} from "antd"
import {useDropzone} from "react-dropzone"
import UploadFileImg from "../assets/images/uploadFile.svg"
import {FileText} from "react-feather"

function BasicDropzone(props) {
    const [files,
        setFiles] = useState([])
    const {getRootProps, getInputProps} = useDropzone({
        accept: "text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument." +
                "spreadsheetml.sheet",
        maxFiles: 1,
        init: function () {
            this
                .on("maxfilesexceeded", function (file) {
                    this.removeAllFiles();
                    this.addFile(file);
                });
                this.on("sending", function(file, xhr, data) {
                });
        },
        onDrop: acceptedFiles => {
            props.onDropped(acceptedFiles)
            setFiles(acceptedFiles.map(file => Object.assign(file)))
        }
    })

    const thumbs = files.length > 0
        ? files.map(file => (
            <p key={file.name} className="black mt-1"><FileText size={40}/>
                {file.name}</p>
        ))
        : ""

    useEffect(() => () => {
        // Make sure to revoke the data uris to avoid memory leaks
        // files.forEach(file => URL.revokeObjectURL(file.preview))
    }, [files])

    return (
        <section>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()}/> {thumbs
                    ? thumbs
                    : <div>
                        <img className="center" src={UploadFileImg} alt=""></img>
                        <Button outline className="font-normal text-bold-500 center" color="primary">
                            Pilih & Upload File
                        </Button>
                    </div>}
            </div>
            {/* <aside className="thumb-container">{thumbs}</aside> */}
        </section>
    )
}

class DropzoneBasic extends React.Component {
    render() {
        return (<BasicDropzone onDropped={this.props.onDropped}/>)
    }
}

export default DropzoneBasic
