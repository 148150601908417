import React from "react";
import "antd/dist/antd.css";
import { Menu, Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";

class ActionStatus extends React.Component {
  state = {
    status: this.props.status,
  };
  viewStatus = () => {
    this.props.edit(this.state.status);
  };

  render() {
    return (
      <Space wrap>
        <Dropdown.Button
          disabled={this.state.status && this.state.status.error === 0}
          onClick={(e) => this.viewStatus(e)}
          placement="bottomCenter"
          icon={<DownOutlined />}
          overlay={<Menu></Menu>}
        >
          Lihat Detail
        </Dropdown.Button>
      </Space>
    );
  }
}

export default ActionStatus;
