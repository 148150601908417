import React from "react";
import { Tabs, Card } from "antd";
import Import from "./Import/Import";
import Result from "./Result";
import Status from "./Status/Status";

const { TabPane } = Tabs;

function callback(key) {}

class Index extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Card
          headStyle
          style={{
            backgroundColor: "#e8e8e8",
            minHeight: window.innerHeight,

            padding: "-5%",
            display: "fixed",
          }}
        >
          <div style={{ marginTop: 20 }}>
            <Card
              style={{
                borderRadius: "5px",
                height: "100%",
                overflow: "hidden",
              }}
            >
              <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Nilai" key="1">
                  <Result />
                </TabPane>
                <TabPane tab="Impor Nilai" key="2">
                  <Import />
                </TabPane>
                <TabPane tab="Status Impor" key="3">
                  <Status />
                </TabPane>
              </Tabs>
            </Card>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}
export default Index;
