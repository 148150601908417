import React from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Divider,
  DatePicker,
  Input,
  Image,
  Tooltip,
} from "antd";
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import ImageDropzone from "../../../../components/ImageDropzone";
import moment from "moment-timezone";
import ReactToPrint from "react-to-print";
import "moment/locale/id";
import { mainConfig } from "../../../../config/mainConfig";
import { paymenttService } from "../../../../services/payment.service";
import {
  CloseCircleOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { participantService } from "../../../../services/participant.service";
import logo from "../../../../assets/images/logo-olimpiade.png";
import { SettingService } from "../../../../services/setting.services";

export class PrintComponent extends React.Component {
  constructor(props) {
    super(props);
    this._infoModal = React.createRef();
  }

  state = {
    user_id: 0,
    data: [],
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let userData = localStorage.getItem("USER_DATA");
    let user_id = JSON.parse(userData);
    this.setState({ user_id: user_id.id });
    participantService.getUser(user_id.id).then(
      (resp) => {
        if (resp.status) {
          this.setState({
            data: resp.data,
            total: resp.total,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
  }
  render() {
    return (
      <React.Fragment>
        <div className="print-source">
          <Card>
            <Row align="middle" gutter={[16]}>
              <Col span={4} style={{ textAlign: "right", marginBottom: 16 }}>
                <img
                  src={logo}
                  style={{ width: "45%" }}
                  alt="Logo Olimpiade Matsanela"
                />
              </Col>
              <Col span={14}>
                <p
                  style={{
                    textAlign: "left",
                    fontSize: "9pt",
                    fontWeight: "bold",
                    marginLeft: "3%",
                  }}
                >
                  KARTU PESERTA OLIMPIADE MIPAS <br />
                  MTs Negeri 1 Lamongan <br />
                  {moment().format("YYYY")}
                </p>
              </Col>
              <Col span={6} style={{ textAlign: "left" }}>
                {/* <Barcode
                  width={1}
                  height={40}
                  displayValue={false}
                  value={this.state.data.registration_number}
                /> */}
              </Col>
            </Row>
            <Divider
              style={{ borderWidth: "3px", borderColor: "#000000", margin: 0 }}
            ></Divider>
            <Row>
              <Col
                span={20}
                style={{
                  textAlign: "left",
                  marginTop: "48px",
                }}
              >
                <Row>
                  <Col span={6}>
                    <label>NISN</label>
                  </Col>
                  <Col span={17}>
                    <Input value={this.state && this.state.data.nisn} />
                  </Col>
                </Row>
                <Row style={{ marginTop: "2%" }}>
                  <Col span={6}>
                    <label>Nama</label>
                  </Col>
                  <Col span={17}>
                    <Input value={this.state && this.state.data.name} />
                  </Col>
                </Row>
                <Row style={{ marginTop: "2%" }}>
                  <Col span={6}>
                    <label>Nomor Peserta</label>
                  </Col>
                  <Col span={17}>
                    <Input
                      value={this.state && this.state.data.registration_number}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={3} style={{ marginTop: "4%" }}>
                <img
                  style={{ width: "113.38582677px", height: "113.38582677px" }}
                  src={`${mainConfig.apiUrl}/user/image/${this.state.user_id}`}
                  alt="Foto Profil Peserta"
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "2%", textAlign: "left" }}>
              <Col span={5}>
                <label>Asal Sekolah</label>
              </Col>
              <Col span={19}>
                <Input value={this.state && this.state.data.school} />
              </Col>
            </Row>
            <Row style={{ marginTop: "2%", textAlign: "left" }}>
              <Col span={5}>
                <label>Kelas</label>
              </Col>
              <Col span={19}>
                <Input value={this.state && this.state.data.grade} />
              </Col>
            </Row>
            <Row style={{ marginTop: "2%", textAlign: "left" }}>
              <Col span={5}>
                <label>Kategori</label>
              </Col>
              <Col span={19}>
                <Input value={this.state && this.state.data.category} />
              </Col>
            </Row>
            <Row style={{ marginTop: "2%", textAlign: "left" }}>
              <Col span={5}>
                <label>Alamat</label>
              </Col>
              <Col span={19}>
                <Input
                  value={
                    this.state &&
                    this.state.data.district + ", " + this.state.data.city
                  }
                />
              </Col>
            </Row>
            <Row style={{ marginTop: "4%", textAlign: "left" }}>
              <Col>
                <p className="font-small-2" padding="0" margin="0">
                  Gunakan username <b>CBT{this.state.data.nisn}</b> dan password{" "}
                  <b>{this.state.data.registration_number}</b> untuk login CBT.
                </p>
              </Col>
            </Row>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}
class Payment extends React.Component {
  constructor(props) {
    super(props);
    this._infoModal = React.createRef();
  }

  state = {
    pay_date: "",
    name: "",
    noRef: "",
    body: {
      image: "",
      user_id: 0,
    },
    user_id: 0,
    payment_status: "",
    payment: [],
    image: [],
    settings: [],
    isLoading: false,
    user: [],
  };
  copyLink = (event) => {
    let account = this.state.settings.payment_account;
    copy(account);
    toast.success("Nomor rekening berhasil disalin!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  onChange(date, dateString) {
    let startDate = moment(date[0]).format("YYYY-MM-DD 00:01");
    this.setState({ pay_date: startDate });
  }

  dropImage = (files) => {
    let body = this.state.body;
    body.image = files[0];
    this.setState({ body });
  };
  handleSubmit = (e) => {
    this.setState({ isLoading: true });
    let user_id = this.state.user_id;
    let body = this.state.body;
    body["user_id"] = user_id;
    body["pay_date"] = this.state.pay_date;
    body["name"] = this.state.name;
    body["ref_no"] = this.state.noRef;
    e.preventDefault();
    paymenttService.createPayment(body).then(
      (resp) => {
        this.setState({ description: "", title: "", isLoading: false });
        toast.success(
          "Konfirmasi Pembayaran Berhasil, harap cek kembali status pembayaran 1x24 jam, jika terkonfirmasi kamu bisa cetak kartu peserta disini",
          {
            position: toast.POSITION.TOP_CENTER,
            pauseOnFocusLoss: false,
            autoClose: 7000,
          }
        );
        setTimeout(function () {
          window.location.reload();
        }, 7000);
      },
      (error) => {
        this.setState({ description: "", title: "", isLoading: false });
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
        });
      }
    );
  };

  componentDidMount() {
    let userData = localStorage.getItem("USER_DATA");
    let user_id = JSON.parse(userData);
    this.setState({ user_id: user_id.id });

    paymenttService.getPaymentsByUser(user_id.id).then(
      (resp) => {
        if (resp.status) {
          this.setState({
            payment: resp.data,
            payment_status: resp.data.payment_status,
            name: resp.data.name,
            noRef: resp.data.ref_no,
            pay_date: resp.data.pay_date,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );

    SettingService.getSetting().then(
      (resp) => {
        if (resp.status) {
          this.setState({
            settings: resp.data,
            total: resp.total,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );

    participantService.getUser(user_id.id).then(
      (resp) => {
        if (resp.status) {
          this.setState({
            user: resp.data,
            total: resp.total,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
  }

  render() {
    let { body } = this.state;
    return (
      <React.Fragment>
        <Card
          // loading={this.state.isLoading}
          headStyle
          style={{
            backgroundColor: "#e8e8e8",
            minHeight: window.innerHeight,
            padding: "-5%",
            display: "fixed",
          }}
        >
          <Row gutter={[24]}>
            <Col style={{ marginTop: 16 }} xs={24} sm={8} md={8} lg={8}>
              <Card
                className="font-medium-1"
                style={{
                  borderRadius: "5px",
                  borderColor:
                    this.state.payment_status === "due" ||
                    !this.state.payment_status
                      ? "#ec4646"
                      : "",
                  color:
                    this.state.payment_status === "due" ||
                    !this.state.payment_status
                      ? "#ec4646"
                      : "black",
                  textAlign: "center",
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                  padding: "4% 10% 4% 10%",
                }}
              >
                Belum Dibayar
                <CloseCircleOutlined
                  style={{ marginLeft: "6%", verticalAlign: "middle" }}
                />
              </Card>
            </Col>
            <Col style={{ marginTop: 16 }} xs={24} sm={8} md={8} lg={8}>
              <Card
                className="font-medium-1"
                style={{
                  borderRadius: "5px",
                  borderColor:
                    this.state.payment_status === "waiting" ? "#ffcc29" : "",
                  color:
                    this.state.payment_status === "waiting"
                      ? "#ffcc29"
                      : "black",
                  textAlign: "center",
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                  padding: "4% 10% 4% 10%",
                }}
              >
                Menunggu Konfirmasi
                <InfoCircleOutlined
                  style={{ marginLeft: "6%", verticalAlign: "middle" }}
                />
              </Card>
            </Col>
            <Col style={{ marginTop: 16 }} xs={24} sm={8} md={8} lg={8}>
              <Card
                className="font-medium-1"
                style={{
                  borderRadius: "5px",
                  borderColor:
                    this.state.payment_status === "paid" ? "#00af91" : "",
                  color:
                    this.state.payment_status === "paid" ? "#00af91" : "black",
                  textAlign: "center",
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                  padding: "4% 12% 4% 10%",
                }}
              >
                Terbayar
                <CheckCircleOutlined
                  style={{ marginLeft: "6%", verticalAlign: "middle" }}
                />
              </Card>
            </Col>
          </Row>

          <div>
            <Card
              style={{
                marginTop: 24,
                borderRadius: "5px",
                height: "100%",
                overflow: "hidden",
              }}
            >
              <Row style={{ marginTop: 16 }} gutter={[16]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal"> Biaya Pendaftaran </p>
                </Col>
                <Col xs={24} sm={18} md={18} lg={18}>
                  <p className="font-normal">
                    {this.state.settings.payment_amount
                      ? "Rp. " +
                        parseInt(
                          this.state.settings.payment_amount
                        ).toLocaleString()
                      : "-"}
                  </p>
                </Col>
              </Row>
              <Row style={{ marginTop: 16 }} gutter={[16]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal"> Transfer ke </p>
                </Col>
                <Col xs={16} sm={12} md={12} lg={6}>
                  {this.state.settings.payment_bank ? (
                    <p
                      className="font-normal text-bold-700"
                      style={{ display: "inline-block", textAlign: "left" }}
                    >
                      {this.state.settings.payment_bank +
                        "  " +
                        this.state.settings.payment_account}
                      <br /> {"atas nama " + this.state.settings.payment_name}
                    </p>
                  ) : (
                    "-"
                  )}
                </Col>
                <Col xs={8} sm={6} md={6} lg={12}>
                  <Button
                    onClick={this.copyLink}
                    type="primary"
                    style={{
                      display: "inline-block",
                      verticalAlign: "top",
                      marginLeft: "5%",
                      borderRadius: "5%",
                    }}
                  >
                    Salin
                  </Button>
                </Col>
              </Row>
              <Row style={{ marginTop: 16 }} gutter={[16]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    {" "}
                    Tanggal Pembayaran <span style={{ color: "red" }}>
                      {" "}
                      *
                    </span>{" "}
                  </p>
                </Col>
                <Col xs={24} sm={12} md={12} lg={6}>
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="Tanggal Pembayaran"
                    onChange={(date, dateString) =>
                      this.onChange(date, dateString)
                    }
                    value={
                      this.state.pay_date ? moment(this.state.pay_date) : ""
                    }
                    disabled={
                      this.state.payment_status === "due" ||
                      !this.state.payment_status
                        ? false
                        : true
                    }
                  ></DatePicker>
                </Col>
              </Row>
              <Row style={{ marginTop: 16 }} gutter={[16]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal"> Nama Pengirim</p>
                </Col>
                <Col xs={24} sm={12} md={12} lg={6}>
                  <Input
                    name="name"
                    type="text"
                    placeholder="Nama Pengirim"
                    onChange={(e) => this.setState({ name: e.target.value })}
                    value={this.state.name}
                    disabled={
                      this.state.payment_status === "due" ||
                      !this.state.payment_status
                        ? false
                        : true
                    }
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 16 }} gutter={[16]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal"> Nomor Referensi</p>
                </Col>
                <Col xs={24} sm={12} md={12} lg={6}>
                  <Input
                    name="noRef"
                    type="text"
                    placeholder="Nomor Referensi"
                    onChange={(e) => this.setState({ noRef: e.target.value })}
                    value={this.state.noRef}
                    disabled={
                      this.state.payment_status === "due" ||
                      !this.state.payment_status
                        ? false
                        : true
                    }
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 16 }} gutter={[16]}>
                <Col xs={24} sm={6} md={6} lg={6}>
                  <p className="font-normal">
                    {" "}
                    Bukti Transfer <span style={{ color: "red" }}> *</span>{" "}
                  </p>
                </Col>
                {this.state.payment_status === "" ? (
                  <>
                    <Col>
                      <ImageDropzone onDropped={this.dropImage} item={body} />
                      <br />
                      <p
                        className="font-small-2"
                        style={{
                          color: "red",
                          marginLeft: "2%",
                        }}
                      >
                        Ukuran Foto maks 500 KB
                      </p>
                    </Col>
                  </>
                ) : this.state.payment.offline_approve ? (
                  <Col>Diverifikasi Offline</Col>
                ) : (
                  <Col>
                    <Image
                      style={{ width: 160, height: 160, padding: "0.8rem" }}
                      src={`${mainConfig.apiUrl}/payment/image/${this.state.payment.id}`}
                    />
                  </Col>
                )}
              </Row>
              <Row style={{ marginTop: 16, float: "right" }} gutter={[16]}>
                <Col>
                  <ReactToPrint
                    pageStyle="
                      @page {
                        size: a5 landscape;
                        margin: 0;
                      }
                      "
                    trigger={() => (
                      <Tooltip title="Harap cetak menggunakan ukuran kertas A5 dan posisi landscape">
                        <Button
                          type="primary"
                          disabled={this.state.payment_status !== "paid"}
                          style={{
                            marginLeft: "2%",
                            borderRadius: "5%",
                          }}
                        >
                          Cetak Kartu Peserta
                        </Button>
                      </Tooltip>
                    )}
                    content={() => this.componentRef}
                  />
                  <PrintComponent ref={(el) => (this.componentRef = el)} />
                </Col>
                <Col>
                  <Tooltip
                    title={
                      this.state.user.grade < 2
                        ? "Kamu harus melengkapi biodata!"
                        : this.state.payment_status === "paid"
                        ? "Kamu sudah melakukan pembayaran!"
                        : ""
                    }
                  >
                    <Button
                      loading={this.state.isLoading}
                      type="primary"
                      disabled={
                        this.state.payment_status === "paid" ||
                        this.state.user.grade < 2 ||
                        !this.state.body.image ||
                        !this.state.pay_date
                      }
                      style={{
                        marginLeft: "2%",
                        borderRadius: "5%",
                      }}
                      onClick={this.handleSubmit}
                    >
                      Konfirmasi Pembayaran
                    </Button>
                  </Tooltip>
                </Col>
              </Row>
            </Card>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}
export default Payment;
