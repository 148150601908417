import React from "react";
import "antd/dist/antd.css";
import { Row, Col, Pagination, Empty, Select, Table } from "antd";
import Search from "antd/lib/input/Search";
import { scoreService } from "../../../../services/score.service";
import ActionResult from "./ActionResult";
import ButtonExport from "../../../../components/ButtonExport";
import moment from "moment";

const { Option } = Select;
function onBlur() {}

function onFocus() {}

function onSearch(val) {}
const CustomHeader = (props) => {
  let csvData =
    props.data &&
    props.data.map((item) => {
      return {
        nama_peserta: item.name,
        asal_sekolah: item.school,
        benar: item.answer_right,
        salah: item.answer_wrong,
        tidak_dijawab: item.not_answered,
        nilai: item.score,
        peringkat: item.rank,
        keterangan: item.description,
        tahapan: item.phase,
        kategori: item.category,
      };
    });
  return (
    <React.Fragment>
      <div style={{ marginTop: "2%", marginBottom: "3%" }}>
        <Row gutter={[16]}>
          <Col style={{ marginTop: 16 }} xs={24} sm={12} md={5} lg={5}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Tahap Pelaksanaan"
              optionFilterProp="children"
              onChange={props.onPhase}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
            >
              {props.phase.map((item) => (
                <Option value={item.value}>{item.label}</Option>
              ))}
            </Select>
          </Col>

          <Col style={{ marginTop: 16 }} xs={24} sm={12} md={5} lg={5}>
            <Select
              showSearch
              style={{ width: "100%" }}
              placeholder="Kategori"
              optionFilterProp="children"
              onChange={props.onCategory}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
            >
              {props.category.map((item) => (
                <Option value={item.value}>{item.label}</Option>
              ))}
            </Select>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={12} md={11} lg={11}>
            <div className="form-control-position pr-3">
              <Search
                placeholder="Cari nama peserta, nisn, atau nomor registrasi"
                allowClear
                onChange={(e) => props.handleFilter(e)}
                size="middle"
                style={{
                  color: "#555597",
                  width: "100%",
                }}
              />
            </div>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={12} md={3} lg={3}>
            <ButtonExport
              csvData={csvData}
              fileName={"Data Hasil Olimpiade " + moment().format("LL")}
            />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
class ResultTable extends React.Component {
  state = {
    columns: [
      {
        title: "Nama",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Asal Sekolah",
        dataIndex: "school",
        key: "school",
        responsive: ["md"],
      },
      {
        title: "Benar",
        dataIndex: "answer_right",
        key: "answer_right",
        responsive: ["md"],
      },
      {
        title: "Salah",
        dataIndex: "answer_wrong",
        key: "answer_wrong",
        responsive: ["md"],
      },
      {
        title: "Tidak Dijawab",
        dataIndex: "not_answered",
        key: "noot_answered",
        responsive: ["md"],
      },
      {
        title: "Nilai",
        dataIndex: "score",
        key: "score",
      },
      {
        title: "Peringkat",
        key: "rank",
        dataIndex: "rank",
      },
      {
        title: "Keterangan",
        key: "description",
        dataIndex: "description",
        responsive: ["md"],
      },
      {
        title: "Kategori",
        key: "category",
        dataIndex: "category",
        responsive: ["md"],
      },
      {
        title: "Tahapan",
        key: "phase",
        dataIndex: "phase",
        responsive: ["md"],
      },
      {
        title: "Tindakan",
        dataIndex: "",
        key: "x",
        render: (row) => (
          <ActionResult
            score={row}
            edit={this.props.edit}
            delete={this.props.delete}
          />
        ),
      },
    ],
    data: [],
    categories: -1,
    phase: -1,
    page: 1,
    limit: 10,
    total: 0,
    value: "",
  };
  componentDidMount() {
    this.getData(true);
  }

  getData(showLoading = false) {
    this.setState({ isLoading: showLoading });
    scoreService
      .getScore(
        this.state.page,
        this.state.limit,
        this.state.value,
        this.state.categories,
        this.state.phase,
        ""
      )
      .then(
        (resp) => {
          if (resp.status) {
            this.setState({
              data: resp.data,
              total: resp.total,
              isLoading: false,
            });
          }
        },
        (error) => {
          this.setState({ isLoading: false });
        }
      );
  }
  componentDidUpdate(prevProps, prevState) {
    //page change call and rows count
    if (
      prevProps.reload !== this.props.reload ||
      prevState.page !== this.state.page ||
      prevState.limit !== this.state.limit ||
      prevState.value !== this.state.value ||
      prevState.categories !== this.state.categories ||
      prevState.phase !== this.state.phase
    ) {
      this.getData(false);
    }
  }
  onCategory = (item) => {
    this.setState({ categories: item });
  };
  onPhase = (item) => {
    this.setState({ phase: item });
  };
  handleFilter = (e) => {
    let value = e.target.value;
    this.setState({ value });
  };
  onChange = (page, pageSize) => {
    if (isNaN(pageSize)) {
      this.setState({ page: page, limit: 0 });
    } else {
      this.setState({ page: page, limit: pageSize });
    }
  };
  render() {
    let { data, columns, value } = this.state;
    let table = {
      data: data,
      total: this.state.total,
      columns: columns,
      isLoading: this.state.isLoading,
    };
    let emptyData = {
      emptyText: (
        <Empty
          style={{ marginTop: "3%", marginBottom: "3%" }}
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 80,
          }}
          description={<span style={{ color: "black" }}>Tidak ada data</span>}
        ></Empty>
      ),
    };
    return (
      <React.Fragment>
        <CustomHeader
          data={data}
          columns={table.columns}
          //Kategori
          category={this.props.categories}
          onCategory={this.onCategory}
          categories={this.state.categories.value}
          //Jadwal
          phase={this.props.phase}
          onPhase={this.onPhase}
          phases={this.state.phase.value}
          //Search
          handleFilter={this.handleFilter}
          value={value}
        />
        <Table
          columns={table.columns}
          dataSource={table.data}
          pagination={false}
          loading={table.isLoading}
          locale={emptyData}
          scroll={{ x: true }}
        />
        {table.total !== 0 && (
          <Pagination
            responsive={true}
            style={{ textAlign: "center", marginTop: "5%", color: "black" }}
            defaultCurrent={1}
            total={table.total}
            showSizeChanger={true}
            onChange={this.onChange}
            pageSize={
              this.state.limit === 0 ? this.state.total : this.state.limit
            }
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} dari ${total} data`
            }
            pageSizeOptions={["10", "20", "50", "100", "semua"]}
          />
        )}
      </React.Fragment>
    );
  }
}

export default ResultTable;
