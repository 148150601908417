import React from "react";
import { Row, Col, Input, Button, Card } from "antd";
import ImageDropzone from "../../../../../components/ImageDropzone";
import { toast } from "react-toastify";
import { SettingService } from "../../../../../services/setting.services";
const { TextArea } = Input;

class Banner extends React.Component {
  state = {
    data: this.props.data,
    banner_title: "",
    banner_description: "",
    image: "",
    body: {
      image: "",
    },
    isLoading: this.props.isLoading,
  };
  dropImage = (files) => {
    let body = this.state.body;
    body.image = files[0];
    this.setState({ body });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      if (this.props.data)
        this.setState({
          isLoading: this.props.isLoading,
          data: this.props.data,
          banner_title: this.props.data.banner_title,
          banner_description: this.props.data.banner_description,
        });
      else
        this.setState({
          isLoading: false,
          data: null,
          title: "",
          description: "",
        });
    }
  }
  handleSubmit = (e) => {
    this.setState({ isLoading: true });
    let body = this.state.body;
    e.preventDefault();
    body["banner_description"] = this.state.banner_description;
    body["banner_title"] = this.state.banner_title;
    SettingService.editSetting(body).then(
      (resp) => {
        this.setState({
          isLoading: false,
        });
        toast.success(resp.message, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
        });
      },
      (error) => {
        this.setState({ description: "", title: "", isLoading: false });
        toast.error(error, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
        });
      }
    );
  };

  render() {
    return (
      <div>
        <>
          <Card
            loading={this.state.isLoading}
            style={{ borderRadius: "5px", height: "100%", overflow: "hidden" }}
          >
            <p className="font-medium-1 text-bold-700"> Banner</p>

            <Row style={{ marginTop: 12 }} gutter={[4]}>
              <Col
                xs={24}
                sm={5}
                md={5}
                lg={5}
                style={{ marginLeft: "2%", marginTop: "2%" }}
              >
                <p className="font-normal"> Foto </p>
              </Col>
              <Col xs={24} sm={18} md={18} lg={18}>
                <ImageDropzone onDropped={this.dropImage} />
              </Col>
            </Row>
            <Row style={{ marginTop: 12 }} gutter={[4]}>
              <Col xs={24} sm={5} md={5} lg={5} style={{ marginLeft: "2%" }}>
                <p className="font-normal"> Teks Judul </p>
              </Col>
              <Col xs={24} sm={18} md={18} lg={18}>
                <Input
                  name="title"
                  type="text"
                  value={this.state.banner_title}
                  onChange={(e) =>
                    this.setState({ banner_title: e.target.value })
                  }
                ></Input>
              </Col>
            </Row>
            <Row style={{ marginTop: 12 }} gutter={[4]}>
              <Col xs={24} sm={5} md={5} lg={5} style={{ marginLeft: "2%" }}>
                <p className="font-normal"> Teks Deskripsi </p>
              </Col>
              <Col xs={24} sm={18} md={18} lg={18}>
                <TextArea
                  name="description"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  value={this.state.banner_description}
                  onChange={(e) =>
                    this.setState({ banner_description: e.target.value })
                  }
                ></TextArea>
              </Col>
            </Row>
            <Button
              loading={this.state.isLoading}
              type="primary"
              style={{ float: "right", marginTop: "2%" }}
              onClick={this.handleSubmit}
            >
              Perbarui
            </Button>
          </Card>
        </>
      </div>
    );
  }
}

export default Banner;
