import React from "react";
import { Card, Button } from "antd";
import "react-toastify/dist/ReactToastify.css";
import Category from "./Category/Category";
import Schedule from "./Schedule/Schedule";
import Banner from "./Banner/Banner";
import Contact from "./Contact/Contact";
import Description from "./Description/Description";
import PaymentSetting from "./Payment/PaymentSetting";
import { SettingService } from "../../../../services/setting.services";
import File from "./File/File";
import ManageModal from "./ManageModal";
import ModalOlimpiade from "./ModalOlimpiade";

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
    this._modalOlimp = React.createRef();
  }
  state = {
    data: [],
    isLoading: false,
  };
  showModal = () => {
    this._modalManage.current.toggleModal();
  };

  showModalOlimp = () => {
    this._modalOlimp.current.toggleModal();
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ isLoading: true });
    SettingService.getSetting().then(
      (resp) => {
        if (resp.status) {
          this.setState({
            data: resp.data,
            total: resp.total,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
  }

  render() {
    return (
      <React.Fragment>
        <Card
          headStyle
          style={{
            backgroundColor: "#e8e8e8",
            minHeight: window.innerHeight,

            padding: "-5%",
            display: "fixed",
          }}
        >
          <div style={{ marginTop: 20 }}>
            <Banner data={this.state.data} isLoading={this.state.isLoading} />
          </div>
          <div style={{ marginTop: 20 }}>
            <div>
              <Description
                data={this.state.data}
                isLoading={this.state.isLoading}
              />
            </div>
          </div>
          <Schedule />

          <Category />

          <div style={{ marginTop: 20 }}>
            <File data={this.state.data} />
          </div>
          <Contact />
          <div style={{ marginTop: 20 }}>
            <div>
              <PaymentSetting
                data={this.state.data}
                isLoading={this.state.isLoading}
              />
            </div>
          </div>

          <Button
            type="primary"
            style={{ marginTop: "2%", float: "right" }}
            onClick={this.showModalOlimp}
          >
            Akhiri Sesi Olimpiade
          </Button>
          <Button
            type="primary"
            style={{ marginRight: "2%", float: "right", marginTop: "2%" }}
            onClick={(e) => this.showModal()}
          >
            Ganti Kata Sandi
          </Button>
        </Card>
        <ManageModal ref={this._modalManage} />
        <ModalOlimpiade ref={this._modalOlimp} />
      </React.Fragment>
    );
  }
}
export default Settings;
