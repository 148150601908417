import React from "react";
import { Modal, Form, Button, Select, Row, Col } from "antd";

import { categoryService } from "../../../../../services/category.services";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { participantService } from "../../../../../services/participant.service";

const { Option } = Select;

function onBlur() {}

function onFocus() {}

function onSearch(val) {}
class ManageModal extends React.Component {
  constructor(props) {
    super(props);
    this.fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    this.fileExtension = ".xlsx";
  }

  state = {
    isOpen: false,
    data: null,
    category: -1,
    categoryName: "",
    categories: [],
    user: [],
  };

  downloadTemplate = () => {
    let checkData = this.state.user.filter(
      (item) => item.registration_number !== null
    );
    let csvData = checkData.map((item) => {
      return {
        Nama: item.name,
        "No Registrasi": item.registration_number,
        Benar: "",
        Salah: "",
        "Tidak Dijawab": "",
        Nilai: "",
        Peringkat: "",
        Keterangan: "",
      };
    });
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: this.fileType });
    FileSaver.saveAs(data, "Template Import Nilai" + this.fileExtension);
  };
  componentDidMount() {
    categoryService.getCategory().then((resp) => {
      let data = resp.data.map((item) => {
        return { name: "categoryId", label: item.name, value: item.id };
      });
      data.unshift({ name: "categoryId", label: "Semua Kategori", value: -1 });
      this.setState({ categories: data });
    });
  }

  handleChange = (e) => {
    const category = e;

    participantService.getParticipant(1, 1000, category, "").then(
      (resp) => {
        if (resp.status) {
          this.setState({
            user: resp.data,
            total: resp.total,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };

  render() {
    return (
      <>
        <Modal
          title={null}
          centered
          visible={this.state.isOpen}
          toggle={this.toggleModal}
          width={500}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Batalkan
            </Button>,
            <Button key="submit" type="primary" onClick={this.downloadTemplate}>
              Unduh Template
            </Button>,
          ]}
        >
          <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                textAlign: "center",
                marginBottom: "10%",
              }}
            >
              Unduh Template{" "}
            </p>

            <Row>
              <Col span={5}>
                <label>
                  Kategori <span style={{ color: "red" }}>*</span>
                </label>
              </Col>
              <Col span={19}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Kategori"
                  optionFilterProp="children"
                  onChange={this.handleChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  value={this.state.category_id}
                >
                  {this.state &&
                    this.state.categories.map((item) => (
                      <Option value={item.value}>{item.label}</Option>
                    ))}
                </Select>
              </Col>
            </Row>
          </Form>
        </Modal>
      </>
    );
  }
}

export default ManageModal;
