import React from "react";
import { jwtDecode } from "jwt-decode";
import {
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  DatePicker,
  Tooltip,
  Switch,
  Card,
  Divider,
} from "antd";
import { toast } from "react-toastify";

import ReactToPrint from "react-to-print";
import { mainConfig } from "../../../../config/mainConfig";
import logo from "../../../../assets/images/logo-olimpiade.png";
import ImageDropzone from "../../../../components/ImageDropzone";
import { participantService } from "../../../../services/participant.service";
import { addressService } from "../../../../services/address.services";
import { userService } from "../../../../services/user.services";
import { paymenttService } from "../../../../services/payment.service";
import "../../../../App.css";
import moment from "moment";
import AddSchoolModal from "../Participants/School/ManageModal";

const { Option } = Select;

function onBlur() {}

function onFocus() {}

function onSearch(val) {}

class ManageModal extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
  }
  state = {
    isOpen: false,
    isLoading: false,
    city: "Semua Kota",
    district: "Semua Kecamatan",
    data: this.props.user,
    name: "",
    nisn: "",
    listBithPlace: [],
    birth_place: "Semua Kota",
    birth_date: "",
    phone_number: "",
    gender: "",
    school_id: -1,
    school: [],
    grade: -1,
    categories: this.props.categories,
    category_id: -1,
    password: "",
    old_password: "",
    address_detail: "",
    address_id: -1,
    listAddress: [],
    address: [],
    profile_image: null,
    offline_approve: false,
    body: {
      profile_image: null,
    },
    user_id: 0,
    payment_status: "",
  };

  handleChange = (type, e) => {
    if (type === "city") {
      let body = this.state;
      body.city = e;

      this.setState({ address: [] });
      const fields = e.split(", ");
      const city = fields[0];
      const province = fields[1];
      const page = 1;
      const limit = 999999999;

      addressService.getAddress(city, province, page, limit).then((resp) => {
        let data = resp.data.map((item) => {
          return { name: "addressId", label: item.district, value: item.id };
        });
        data.unshift({
          name: "addressId",
          label: "Semua Kecamatan",
          value: -1,
        });
        this.setState({ address: data });
      });
    } else if (type === "district") {
      const fields = e.split(",");
      const district = fields[1];
      const address_id = fields[0];

      let body = this.state;
      body.district = district;
      body.address_id = parseInt(address_id);
      this.setState({ body });
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    if (this.state.data) {
      participantService
        .editParticipant(this.state.user_id, {
          nisn: this.state.nisn,
          name: this.state.name,
          // profile_image: this.state.body.profile_image,
          birth_place: this.state.birth_place,
          birth_date: this.state.birth_date,
          phone_number: this.state.phone_number,
          gender: this.state.gender,
          school_id: this.state.school_id,
          grade: this.state.grade,
          category_id: this.state.category_id,
          old_password: this.state.old_password,
          password: this.state.password,
          address_detail: this.state.address_detail,
          address_id: this.state.address_id,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              nisn: "",
              birth_place: "",
              birth_date: "",
              phone_number: "",
              gender: "",
              school_id: -1,
              grade: -1,
              category_id: -1,
              password: "",
              old_password: "",
              address_detail: "",
              address_id: -1,
              profile_image: "",
              offline_approve: false,
              user_id: 0,
              city: "Semua Kota",
              district: "Semua Kecamatan",
            });
            this.props.onUpdated(resp);
          },
          (error) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              nisn: "",
              birth_place: "",
              birth_date: "",
              phone_number: "",
              gender: "",
              school_id: -1,
              grade: -1,
              category_id: -1,
              password: "",
              old_password: "",
              address_detail: "",
              address_id: -1,
              profile_image: "",
              offline_approve: false,
              user_id: 0,
              city: "Semua Kota",
              district: "Semua Kecamatan",
            });
            this.props.onUpdated({
              isUpdated: true,
              status: false,
              message: error,
              data: null,
            });
          }
        );
    } else {
      participantService
        .addParticipant({
          nisn: this.state.nisn,
          name: this.state.name,
          profile_image: this.state.profile_image,
          birth_place: this.state.birth_place,
          birth_date: this.state.birth_date,
          phone_number: this.state.phone_number,
          gender: this.state.gender,
          school_id: this.state.school_id,
          grade: this.state.grade,
          category_id: this.state.category_id,
          password: this.state.password,
          address_detail: this.state.address_detail,
          address_id: this.state.address_id,
          offline_approve: this.state.offline_approve,
        })
        .then(
          (resp) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              nisn: "",
              birth_place: "",
              birth_date: "",
              phone_number: "",
              gender: "",
              school_id: -1,
              user_id: 0,
              grade: -1,
              category_id: -1,
              password: "",
              old_password: "",
              address_detail: "",
              address_id: -1,
              profile_image: "",
              offline_approve: false,
              city: "Semua Kota",
              district: "Semua Kecamatan",
            });
            this.props.onUpdated(resp);
            this.componentDidMount(true);
          },
          (error) => {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              name: "",
              nisn: "",
              birth_place: "",
              birth_date: "",
              phone_number: "",
              gender: "",
              school_id: -1,
              user_id: 0,
              grade: -1,
              category_id: -1,
              password: "",
              old_password: "",
              address_detail: "",
              address_id: -1,
              profile_image: "",
              offline_approve: false,
              city: "Semua Kota",
              district: "Semua Kecamatan",
            });
            this.props.onUpdated({
              isUpdated: true,
              status: false,
              message: error,
              data: null,
            });
            this.componentDidMount(true);
          }
        );
    }
  };
  showModal = () => {
    this._modalManage.current.toggleModal();
  };
  handleLogin = (e) => {
    this.setState({ isLoading: true });
    let userData = localStorage.getItem("USER_DATA");
    userData = JSON.parse(userData);
    const admin_key = userData.admin_key;
    userService
      .login({
        username: this.state.nisn,
        password: admin_key,
      })
      .then(
        (resp) => {
          if (resp.status) {
            this.toggleModal();
            this.setState({
              isLoading: false,
              data: null,
              nisn: "",
              username: "",
              password: "",
            });
            localStorage.clear();
            localStorage.setItem("USER_TOKEN", resp.data);
            const userDecode = jwtDecode(resp.data);
            localStorage.setItem("USER_DATA", JSON.stringify(userDecode));
            this.props.onUpdated(resp);
            setTimeout(function () {
              window.location.assign(
                JSON.parse(localStorage.getItem("USER_DATA")).role === "admin"
                  ? "/profile/admin/ringkasanAdmin"
                  : "/profile/ringkasan"
              );
            }, 3000);
          } else {
            this.props.onUpdated(resp);
          }
        },
        (error) => {
          this.props.onUpdated({
            isUpdated: true,
            status: false,
            message: error,
            data: null,
          });
        }
      );
  };

  dropImage = (files) => {
    let body = this.state;
    body.profile_image = URL.createObjectURL(files[0]);
    this.setState({ profile_image: files[0], body });
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };

  onChange(date, dateString) {
    this.setState({ birth_date: dateString });
  }

  componentDidMount() {
    participantService.getSchool(1, 1000, "").then((resp) => {
      let data = resp.data.map((item) => {
        return { name: "schoolId", label: item.name, value: item.id };
      });
      data.unshift({ name: "schoolId", label: "Semua Sekolah", value: -1 });
      this.setState({ school: data });
    });
    addressService.getListAddress(1, 1000).then((resp) => {
      let data = resp.data.map((item) => {
        return {
          name: "addressId",
          label: item.city + ", " + item.province,
          value: item.id,
        };
      });
      let birthPlace = resp.data.map((item) => {
        return { name: "birthPlace", label: item.city, value: item.city };
      });
      birthPlace.unshift({
        name: "birthPlace",
        label: "Semua Kota",
        value: -1,
      });
      data.unshift({ name: "addressId", label: "Semua Kota", value: -1 });
      this.setState({ listAddress: data, listBithPlace: birthPlace });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user !== this.props.user) {
      if (this.props.user) {
        this.setState({
          isLoading: false,
          data: this.props.user,
          user_id: this.props.user.id,
          nisn: this.props.user.nisn,
          name: this.props.user.name,
          profile_image: this.props.user.profile_image,
          birth_place: this.props.user.birth_place,
          birth_date: this.props.user.birth_date,
          phone_number: this.props.user.phone_number,
          gender: this.props.user.gender,
          school_id: this.props.user.school_id,
          grade: this.props.user.grade,
          category_id: this.props.user.category_id,
          address_detail: this.props.user.address_detail,
          address_id: this.props.user.address_id,
          city: this.props.user.city,
          district: this.props.user.district,
          offline_approve: this.props.offline_approve,
          password: "",
          old_password: "",
        });
        paymenttService.getPaymentsByUser(this.props?.user?.id).then(
          (resp) => {
            if (resp.status) {
              this.setState({
                payment_status: resp.data.payment_status,
              });
            }
          },
          (error) => {
            if (error) this.setState({ payment_status: "" });
          }
        );
      } else if (prevProps.categories !== this.props.categories) {
        this.setState({ categories: this.props.categories });
      } else
        this.setState({
          isLoading: false,
          data: null,
          name: "",
          nisn: "",
          birth_place: "",
          birth_date: "",
          phone_number: "",
          gender: "",
          school_id: -1,
          grade: -1,
          category_id: -1,
          password: "",
          old_password: "",
          address_detail: "",
          address_id: -1,
          profile_image: "",
          offline_approve: false,
          payment_status: "",
          user_id: 0,
          city: "Semua Kota",
          district: "Semua Kecamatan",
        });
    }
  }
  onUpdated = (resp) => {
    if (resp.status) {
      participantService.getSchool(1, 1000, "").then((resp) => {
        let data = resp.data.map((item) => {
          return { name: "schoolId", label: item.name, value: item.id };
        });
        data.unshift({ name: "schoolId", label: "Semua Sekolah", value: -1 });
        this.setState({ school: data });
      });
      toast.success(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
    } else
      toast.error(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
  };

  render() {
    let body = this.state;
    return (
      <Modal
        title={this.state.data ? "Edit Peserta" : "Tambah Peserta"}
        centered
        width={800}
        visible={this.state.isOpen}
        toggle={this.toggleModal}
        onCancel={this.handleCancel}
        maskClosable={false}
        footer={[
          <>
            <ReactToPrint
              pageStyle="
                @page {
                  size: a5 landscape;
                  margin: 0;
                }
                "
              trigger={() => (
                <Tooltip title="Harap cetak menggunakan ukuran kertas A5 dan posisi landscape">
                  <Button
                    type="primary"
                    style={{
                      margin: "10px",
                    }}
                    disabled={this.state.payment_status !== "paid"}
                  >
                    Cetak Kartu Peserta
                  </Button>
                </Tooltip>
              )}
              content={() => this.componentRef}
            />
            <div
              className="print-source"
              ref={(el) => (this.componentRef = el)}
            >
              <React.Fragment>
                <div className="print-source">
                  <Card>
                    <Row align="middle" gutter={[16]}>
                      <Col
                        span={4}
                        style={{ textAlign: "right", marginBottom: 16 }}
                      >
                        <img
                          src={logo}
                          style={{ width: "45%" }}
                          alt="Logo Olimpiade Matsanela"
                        />
                      </Col>
                      <Col span={14}>
                        <p
                          style={{
                            textAlign: "left",
                            fontSize: "9pt",
                            fontWeight: "bold",
                            marginLeft: "3%",
                          }}
                        >
                          KARTU PESERTA OLIMPIADE MIPAS <br />
                          MTs Negeri 1 Lamongan <br />
                          {moment().format("YYYY")}
                        </p>
                      </Col>
                      <Col span={6} style={{ textAlign: "left" }}>
                        {/* <Barcode
                          width={1}
                          height={40}
                          displayValue={false}
                          value={this.state?.data?.registration_number}
                        /> */}
                      </Col>
                    </Row>
                    <Divider
                      style={{
                        borderWidth: "3px",
                        borderColor: "#000000",
                        margin: 0,
                      }}
                    ></Divider>
                    <Row>
                      <Col
                        span={20}
                        style={{
                          textAlign: "left",
                          marginTop: "48px",
                        }}
                      >
                        <Row>
                          <Col span={6}>
                            <label>NISN</label>
                          </Col>
                          <Col span={17}>
                            <Input value={this.state?.data?.nisn} />
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "2%" }}>
                          <Col span={6}>
                            <label>Nama</label>
                          </Col>
                          <Col span={17}>
                            <Input value={this.state?.data?.name} />
                          </Col>
                        </Row>
                        <Row style={{ marginTop: "2%" }}>
                          <Col span={6}>
                            <label>Nomor Peserta</label>
                          </Col>
                          <Col span={17}>
                            <Input
                              value={this.state?.data?.registration_number}
                            />
                          </Col>
                        </Row>
                      </Col>
                      <Col span={3} style={{ marginTop: "4%" }}>
                        <img
                          style={{
                            width: "113.38582677px",
                            height: "113.38582677px",
                          }}
                          src={`${mainConfig.apiUrl}/user/image/${this.state?.data?.id}`}
                          alt="Foto Profil Peserta"
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2%", textAlign: "left" }}>
                      <Col span={5}>
                        <label>Asal Sekolah</label>
                      </Col>
                      <Col span={19}>
                        <Input value={this.state?.data?.school} />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2%", textAlign: "left" }}>
                      <Col span={5}>
                        <label>Kelas</label>
                      </Col>
                      <Col span={19}>
                        <Input value={this.state?.data?.grade} />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2%", textAlign: "left" }}>
                      <Col span={5}>
                        <label>Kategori</label>
                      </Col>
                      <Col span={19}>
                        <Input value={this.state?.data?.category} />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2%", textAlign: "left" }}>
                      <Col span={5}>
                        <label>Alamat</label>
                      </Col>
                      <Col span={19}>
                        <Input
                          value={
                            this.state?.data?.district +
                            ", " +
                            this.state?.data?.city
                          }
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "4%", textAlign: "left" }}>
                      <Col>
                        <p className="font-small-2" padding="0" margin="0">
                          Gunakan username <b>CBT{this.state?.data?.nisn}</b>{" "}
                          dan password{" "}
                          <b>{this.state?.data?.registration_number}</b> untuk
                          login CBT.
                        </p>
                      </Col>
                    </Row>
                  </Card>
                </div>
              </React.Fragment>
            </div>
          </>,
          <Button
            loading={this.state.isLoading}
            key="login"
            type="primary"
            onClick={this.handleLogin}
            disabled={!this.state.data}
            style={{
              margin: "10px",
            }}
          >
            Masuk Sebagai Peserta
          </Button>,
          <Button
            loading={this.state.isLoading}
            key="submit"
            type="primary"
            style={{
              margin: "10px",
            }}
            onClick={this.handleSubmit}
          >
            {this.state.data ? "Edit Peserta" : "Tambah Peserta"}
          </Button>,
        ]}
      >
        <Form labelCol={{ span: 3 }} wrapperCol={{ span: 21 }}>
          <Row style={{ marginLeft: "5%" }}>
            <Col span={6}></Col>
            <Col span={18}>
              <ImageDropzone
                onDropped={this.dropImage}
                item={`user/image/${this.state.user_id}`}
              />
              <br />
              <p
                className="font-small-2"
                style={{ color: "red", marginLeft: "2%" }}
              >
                Ukuran Foto maks 500 KB
              </p>
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%", marginTop: "3%" }}>
            <Col span={6}>
              <label>
                NISN<span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col span={18}>
              <Input
                placeholder="NISN"
                type="text"
                value={this?.state?.nisn}
                id="nisn"
                onChange={(e) => this.setState({ nisn: e.target.value })}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Col>
          </Row>

          <Row style={{ marginLeft: "5%", marginTop: "2%" }}>
            <Col span={6}>
              <label>
                Nama<span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col span={18}>
              <Input
                placeholder="Nama"
                type="text"
                value={this?.state?.name}
                id="name"
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%", marginTop: "2%" }}>
            <Col span={6}>
              <label>
                Tempat, Tanggal Lahir<span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col span={10}>
              <Select
                showSearch
                style={{ width: "98%" }}
                placeholder="Semua Kota"
                optionFilterProp="children"
                onChange={(e) => {
                  this.setState({ birth_place: e });
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                value={this?.state?.birth_place || undefined}
              >
                {body &&
                  body.listBithPlace.map((item) => (
                    <Option value={item.label}>{item.label}</Option>
                  ))}
              </Select>
            </Col>
            <Col span={8}>
              {this.state.data ? (
                <Tooltip title="Isi jika ingin mengganti">
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder="Tanggal Lahir"
                    value={
                      this?.state?.birth_date
                        ? moment(this?.state?.birth_date)
                        : moment()
                    }
                    onChange={(date, dateString) =>
                      this.onChange(date, dateString)
                    }
                  />
                </Tooltip>
              ) : (
                <DatePicker
                  style={{ width: "100%" }}
                  placeholder="Tanggal Lahir"
                  value={
                    this?.state?.birth_date
                      ? moment(this?.state?.birth_date)
                      : moment()
                  }
                  onChange={(date, dateString) =>
                    this.onChange(date, dateString)
                  }
                />
              )}
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%", marginTop: "2%" }}>
            <Col span={6}>
              <label>No. Telepon</label>
            </Col>
            <Col span={18}>
              <Input
                placeholder="Nomor Telepon"
                value={this?.state?.phone_number}
                id="phone_number"
                onChange={(e) =>
                  this.setState({ phone_number: e.target.value })
                }
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%", marginTop: "2%" }}>
            <Col span={6}>
              <label>
                Jenis Kelamin<span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col span={18}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Jenis Kelamin"
                optionFilterProp="children"
                value={this?.state?.gender}
                onChange={(e) => {
                  this.setState({ gender: e });
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
              >
                <Option value="">Jenis Kelamin</Option>
                <Option value="male">Laki-Laki</Option>
                <Option value="female">Perempuan</Option>
              </Select>
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%", marginTop: "2%" }}>
            <Col xs={24} sm={6} md={6} lg={6}>
              <label>
                Asal Sekolah<span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col xs={24} sm={11} md={11} lg={11}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Asal Sekolah"
                optionFilterProp="children"
                onChange={(e) => {
                  this.setState({ school_id: e });
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                value={this?.state?.school_id}
              >
                {body &&
                  body.school.map((item) => (
                    <Option value={item.value}>{item.label}</Option>
                  ))}
              </Select>
            </Col>
            <Col lg={1} md={1}></Col>
            <Col xs={24} sm={6} md={6} lg={6}>
              <Tooltip title="Tambah sekolah jika sekolahmu tidak ada di list">
                <Button
                  style={{ width: "100%" }}
                  onClick={(e) => this.showModal()}
                >
                  + Sekolah
                </Button>
              </Tooltip>
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%", marginTop: "2%" }}>
            <Col span={6}>
              <label>
                Kelas<span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col span={18}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Kelas"
                optionFilterProp="children"
                onChange={(e) => {
                  this.setState({ grade: e });
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                value={this?.state?.grade}
              >
                <Option value={-1}>Semua Kelas</Option>
                <Option value={4}>4</Option>
                <Option value={5}>5</Option>
                <Option value={6}>6</Option>
              </Select>
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%", marginTop: "2%" }}>
            <Col span={6}>
              <label>
                Kategori<span style={{ color: "red" }}> *</span>
              </label>
            </Col>
            <Col span={18}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Kategori"
                optionFilterProp="children"
                onChange={(e) => {
                  this.setState({ category_id: e });
                }}
                value={this?.state?.category_id}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
              >
                {this.props &&
                  this.props.categories.map((item) => (
                    <Option value={item.value}>{item.label}</Option>
                  ))}
              </Select>
            </Col>
          </Row>
          {this.state.data !== null ? (
            <Row style={{ marginLeft: "5%", marginTop: "2%" }}>
              <Col span={6}>
                <label>Password</label>
              </Col>
              {/* <Col span={9}>
                <Tooltip title="Isi jika ingin mengganti password">
                  <Input.Password
                    placeholder="Masukkan Password Lama"
                    name="password"
                    style={{ width: "98%" }}
                    onChange={(e) => {
                      this.setState({ old_password: e.target.value });
                    }}
                    autoComplete="off"
                  />
                </Tooltip>
              </Col> */}
              <Col span={9}>
                <Tooltip title="Isi jika ingin mengganti password">
                  <Input.Password
                    placeholder="Masukkan Password Baru"
                    name="password"
                    style={{ width: "98%" }}
                    onChange={(e) => {
                      this.setState({ password: e.target.value });
                    }}
                    autoComplete="off"
                  />
                </Tooltip>
              </Col>
            </Row>
          ) : (
            <Row style={{ marginLeft: "5%", marginTop: "2%" }}>
              <Col span={6}>
                <label>Password</label>
              </Col>
              <Col span={18}>
                <Input.Password
                  placeholder="Masukkan Password"
                  name="password"
                  onChange={(e) => {
                    this.setState({ password: e.target.value });
                  }}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          )}
          {/* {this.state.data ? ( */}
          <Row style={{ marginLeft: "5%", marginTop: "2%" }}>
            <Col span={6}>
              <span style={{ paddingLeft: "2%" }}>
                {" "}
                Alamat<span style={{ color: "red" }}> *</span>
              </span>
            </Col>

            <Col span={9}>
              <Select
                showSearch
                style={{ width: "98%" }}
                placeholder="Semua Kota"
                optionFilterProp="children"
                onChange={(e) => this.handleChange("city", e)}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                value={this.state.city}
              >
                {body &&
                  body.listAddress.map((item) => (
                    <Option value={item.label}>{item.label}</Option>
                  ))}
              </Select>
            </Col>
            <Col span={9}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Semua Kecamatan"
                optionFilterProp="children"
                onChange={(e) => this.handleChange("district", e)}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                value={this.state.district}
              >
                {body &&
                  body.address.map((item) => (
                    <Option value={item.value + "," + item.label}>
                      {item.label}
                    </Option>
                  ))}
              </Select>
            </Col>
          </Row>

          <Row style={{ marginLeft: "5%", marginTop: "2%" }}>
            <Col span={6}></Col>
            <Col span={18}>
              <Input
                type="text"
                placeholder="Masukkan alamat lengkap"
                name="address"
                value={this?.state?.address_detail}
                onChange={(e) => {
                  this.setState({ address_detail: e.target.value });
                }}
              />
            </Col>
          </Row>
          {!this.state.data && (
            <Row style={{ marginLeft: "5%", marginTop: "2%" }}>
              <Col span={6}>
                <span style={{ paddingLeft: "2%" }}>
                  {" "}
                  Pembayaran Dikonfirmasi
                  <span style={{ color: "red" }}> *</span>
                </span>
              </Col>
              <Col span={18}>
                <Switch
                  onChange={(checked) =>
                    this.setState({ offline_approve: checked })
                  }
                  checkedChildren="Ya"
                  unCheckedChildren="Tidak"
                  checked={this?.state?.offline_approve}
                />
              </Col>
            </Row>
          )}
          <AddSchoolModal ref={this._modalManage} onUpdated={this.onUpdated} />
        </Form>
      </Modal>
    );
  }
}

export default ManageModal;
