import React from "react";
import { Modal, Form, Button } from "antd";

class LogoutModal extends React.Component {

    state = {
        isOpen: false,
        username: "",
        password: "",
        data: null,
        type: "",
    };



    toggleModal = () => {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    };
    handleCancel = () => {
        this.setState({ isOpen: false });
        if (this.state.type !== "") {
            this.setState({ isOpen: false });
        }
    };

    onLogout = () => {
        this.setState({ data: null });
        localStorage.clear();
        window.location.assign(
            "/dashboard"

        );

    };

    render() {
        return (
            <React.Fragment>
                <Modal
                    title={false}
                    centered
                    onCancel={this.handleCancel}
                    visible={this.state.isOpen}
                    toggle={this.toggleModal}
                    footer={[
                        <Button key="back" onClick={this.handleCancel}>
                            Batalkan
                        </Button>,
                        <Button key="submit" type="danger" onClick={this.onLogout}>
                            Ya,yakin
                        </Button>
                    ]}
                    style={{ width: "100%", resize: "none" }}
                >
                    <Form >
                        <p className="font-normal text-bold-700">Tunggu Dulu !!</p>
                        <p className="font-normal-1">Apakah anda yakin keluar?</p>

                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default LogoutModal;
