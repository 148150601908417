import moment from "moment";
import React from "react";
import { Modal, Form, Input, Button, Select } from "antd";
import { toast } from "react-toastify";
import { userService } from "../../services/user.services";
import { categoryService } from "../../services/category.services";
import { scheduleService } from "../../services/schedule.services";
import { jwtDecode } from "jwt-decode";

const { Option } = Select;

function onBlur() {}

function onFocus() {}

function onSearch(val) {}
class RegisterModal extends React.Component {
  state = {
    isOpen: false,
    data: null,
    name: "",
    password: "",
    nisn: 0,
    gender: "",
    category: -1,
    categories: [],
    phase: null,
  };
  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };

  onUpdated = (resp) => {
    if (resp.status) {
      this.toggleModal();
      this.setState({
        isLoading: false,
        data: null,
        nisn: "",
        name: "",
        password: "",
        gender: "",
        category_id: "",
      });
      localStorage.clear();
      localStorage.setItem("USER_TOKEN", resp.data);
      const userDecode = jwtDecode(resp.data);
      localStorage.setItem("USER_DATA", JSON.stringify(userDecode));
      toast.success(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: true,
        autoClose: 2000,
      });
      setTimeout(function () {
        window.location.assign(
          JSON.parse(localStorage.getItem("USER_DATA")).role === "admin"
            ? "/profile/admin/ringkasanAdmin"
            : "/profile/ringkasan"
        );
      }, 3000);
    } else {
      toast.error(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: true,
        autoClose: 2000,
      });
    }
  };

  handleSumbit = (e) => {
    userService
      .register({
        category_id: this.state.category,
        nisn: this.state.nisn,
        password: this.state.password,
        name: this.state.name,
        gender: this.state.gender,
      })
      .then(
        (resp) => {
          this.onUpdated(resp);
        },
        (error) => {
          this.onUpdated({
            isUpdated: false,
            status: false,
            message: error,
            data: null,
          });
        }
      );
  };
  componentDidMount() {
    categoryService.getCategory().then((resp) => {
      let data = resp.data.map((item) => {
        return { name: "categoryId", label: item.name, value: item.id };
      });
      data.unshift({ name: "categoryId", label: "Semua Kategori", value: -1 });
      this.setState({ categories: data });
    });

    scheduleService.getScheduleScore(1, 1, "registration").then((resp) => {
      this.setState({ phase: resp.data[0] });
    });
  }
  render() {
    let data = this.state;
    return (
      <>
        <Modal
          title={null}
          centered
          visible={this.state.isOpen}
          toggle={this.toggleModal}
          footer={null}
          width={500}
          onCancel={this.handleCancel}
        >
          <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            <p
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                textAlign: "center",
                marginBottom: "2%",
              }}
            >
              Hi, Selamat Datang!
            </p>
            <Form.Item
              label="NISN"
              name="nisn"
              style={{ marginTop: "7%" }}
              value={this.state.nisn}
              onChange={(e) => this.setState({ nisn: e.target.value })}
              rules={[
                {
                  required: true,
                  message: "Masukkan NISN!",
                },
              ]}
            >
              <Input placeholder="NISN" type="number" />
            </Form.Item>

            <Form.Item
              label="Nama"
              name="name"
              value={this.state.name}
              onChange={(e) => this.setState({ name: e.target.value })}
              rules={[
                {
                  required: true,
                  message: "Masukkan Nama!",
                },
              ]}
            >
              <Input placeholder="Nama" />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              value={this.state.password}
              onChange={(e) => this.setState({ password: e.target.value })}
              rules={[
                {
                  required: true,
                  message: "Masukkan Password!",
                },
                { min: 6, message: "Masukkan minimal 6 karakter" },
              ]}
            >
              <Input.Password placeholder="Password" />
            </Form.Item>
            <Form.Item
              label="Jenis Kelamin"
              name="gender"
              rules={[
                {
                  required: true,
                  message: "Masukkan Jenis Kelamin!",
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Jenis Kelamin"
                optionFilterProp="children"
                onChange={(e) => {
                  this.setState({ gender: e });
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
              >
                <Option value="male">Laki-Laki</Option>
                <Option value="female">Perempuan</Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="Kategori"
              name="categoory"
              rules={[
                {
                  required: true,
                  message: "Masukkan Kategori!",
                },
              ]}
            >
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Kategori"
                optionFilterProp="children"
                onChange={(e) => {
                  this.setState({ category: e });
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
              >
                {data.categories.map((item) => (
                  <Option value={item.value}>{item.label}</Option>
                ))}
              </Select>
            </Form.Item>
            {/* <ButtonContainer> */}
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={this.handleSumbit}
              disabled={
                this.state.gender === "" ||
                this.state.category === -1 ||
                this.state.name === "" ||
                this.state.nisn === 0 ||
                this.state.password === "" ||
                !moment().isBetween(
                  moment(this.state.phase.start_date),
                  moment(this.state.phase.end_date)
                )
              }
            >
              Register
            </Button>
            {/* </ButtonContainer> */}
          </Form>
        </Modal>
      </>
    );
  }
}

export default RegisterModal;
