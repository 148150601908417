import React from "react";
import { Tabs, Card } from "antd";
import Participant from "./Participants";
import School from "./School/School";

const { TabPane } = Tabs;

function callback(key) {}

class Index extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Card
          headStyle
          style={{
            backgroundColor: "#e8e8e8",
            minHeight: window.innerHeight,

            padding: "-5%",
            display: "fixed",
          }}
        >
          <div style={{ marginTop: 20 }}>
            <Card
              style={{
                borderRadius: "5px",
                height: "100%",
                overflow: "hidden",
              }}
            >
              <Tabs defaultActiveKey="1" onChange={callback}>
                <TabPane tab="Peserta" key="1">
                  <Participant />
                </TabPane>
                <TabPane tab="Sekolah" key="2">
                  <School />
                </TabPane>
              </Tabs>
            </Card>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}
export default Index;
