import React from "react";
import "antd/dist/antd.css";
import { Row, Col, Button } from "antd";
import ManageModal from "./ManageModal";
import { toast } from "react-toastify";
import ParticipantsTable from "./ParticipantsTable";
import { participantService } from "../../../../services/participant.service";
import { categoryService } from "../../../../services/category.services";
import DeleteModal from "../../../../components/DeleteModal";

class Participant extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
    this._modalDelete = React.createRef();
  }

  state = {
    user: null,
    reload: false,
    categories: [],
  };

  toggleModalManage = (user) => {
    if (user) {
      this.setState({ user: user, reload: false });
    } else {
      this.setState({ user: null, reload: false });
    }

    this._modalManage.current.toggleModal();
  };

  edit = (user) => {
    this.toggleModalManage(user);
  };

  toggleModalDelete = () => {
    this._modalDelete.current.toggleModal();
  };

  delete = (user) => {
    this.setState({ user: user, reload: false });
    this.toggleModalDelete();
  };

  deleteParticipant = (id) => {
    participantService.deleteParticipant(id).then(
      (resp) => {
        this.toggleModalDelete();
        toast.success(resp.message, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
        });
        this.setState({ user: null, reload: true });
      },
      (err) => {
        this.toggleModalDelete();
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
        });
      }
    );
  };
  componentDidMount() {
    categoryService.getCategory().then((resp) => {
      let data = resp.data.map((item) => {
        return { name: "categoryId", label: item.name, value: item.id };
      });
      data.unshift({ name: "categoryId", label: "Semua Kategori", value: -1 });
      this.setState({ categories: data });
    });
  }
  onUpdated = (resp) => {
    if (resp.status) {
      this.setState({ payment: null, reload: true });
      toast.success(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
    } else
      toast.error(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
  };
  render() {
    return (
      <React.Fragment>
        <Row style={{ marginTop: 16 }} gutter={[16]}>
          <Col xs={24} sm={24} md={19} lg={19}>
            <p className="font-medium-1 text-bold-700">Daftar Peserta</p>
          </Col>
          <Col xs={24} sm={24} md={5} lg={5}>
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={(e) => this.toggleModalManage(null)}
            >
              Tambah Peserta
            </Button>
          </Col>
        </Row>
        <ParticipantsTable
          reload={this.state.reload}
          edit={this.edit}
          delete={this.delete}
          categories={this.state.categories}
        />

        <ManageModal
          ref={this._modalManage}
          onUpdated={this.onUpdated}
          user={this.state.user}
          categories={this.state.categories}
        />

        <DeleteModal
          ref={this._modalDelete}
          id={this.state.user ? this.state.user.id : -1}
          name={this.state.user ? this.state.user.name : ""}
          onDeleted={this.deleteParticipant}
        />
      </React.Fragment>
    );
  }
}

export default Participant;
