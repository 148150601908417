import React from "react";
import "antd/dist/antd.css";
import { Menu, Dropdown, Space } from "antd";
import { DownOutlined, DeleteOutlined } from "@ant-design/icons";

class ActionSchedule extends React.Component {
  state = {
    schedule: this.props.schedule,
  };
  editSchedule = () => {
    this.props.edit(this.state.schedule);
  };
  deleteSchedule = () => {
    this.props.delete(this.state.schedule);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.schedule !== this.props.schedule) {
      this.setState({ schedule: this.props.schedule });
    }
  }

  render() {
    return (
      <Space wrap>
        <Dropdown.Button
          onClick={(e) => this.editSchedule(e)}
          placement="bottomCenter"
          icon={<DownOutlined />}
          overlay={
            <Menu>
              <Menu.Item
                key="1"
                icon={<DeleteOutlined />}
                onClick={(e) => this.deleteSchedule(e)}
                disabled={
                  this.state.schedule.type === "registration" ? true : false
                }
              >
                Hapus
              </Menu.Item>
            </Menu>
          }
        >
          Lihat Detail
        </Dropdown.Button>
      </Space>
    );
  }
}

export default ActionSchedule;
