import React from "react";
import { Table } from "antd";
import "react-toastify/dist/ReactToastify.css";

class ImportTable extends React.Component {
  state = {
    columns: [
      {
        title: "Nama Kolom",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Keterangan",
        dataIndex: "description",
        key: "description",
      },
    ],
    data: [
      {
        name: "Nomor Peserta",
        description: "Dibuat sistem di template",
      },
      {
        name: "Jawaban Benar",
        description: "Jumlah jawaban benar peserta, isi 0 jika kosong",
      },
      {
        name: "Jawaban Salah",
        description: "Jumlah jawaban salah peserta, isi 0 jika kosong",
      },
      {
        name: "Tidak Dijawab",
        description: "Jumlah soal tidak dijawab peserta, isi 0 jika kosong",
      },
      {
        name: "Nilai",
        description: "Nilai akhir dalam satu tahap olimpiade",
      },
      {
        name: "Peringkat",
        description: "Peringkat peserta",
      },
      {
        name: "Keterangan",
        description:
          "Keterangan lolos/tidak untuk maju ke tahap berikutnya atau diisi yang lain",
      },
    ],
  };

  render() {
    let { data, columns } = this.state;
    let table = {
      data: data,
      total: this.state.total,
      columns: columns,
      isLoading: this.state.isLoading,
    };
    return (
      <React.Fragment>
        <Table
          columns={table.columns}
          dataSource={table.data}
          pagination={false}
          scroll={{ x: true }}
        />
      </React.Fragment>
    );
  }
}
export default ImportTable;
