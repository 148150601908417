import React from 'react';
import 'antd/dist/antd.css';
import { Menu, Dropdown, Space } from 'antd';
import { DownOutlined, DeleteOutlined } from '@ant-design/icons';

class ActionResult extends React.Component {
  state = {
    score: this.props.score
}
  editScore = () => {
    this
        .props
        .edit(this.state.score)
}

deleteScore = () => {
  this
    .props
    .delete(this.state.score)
}
componentDidUpdate(prevProps, prevState) {
  if(prevProps.score !== this.props.score) {
      this.setState({score: this.props.score})
  }
}

  render() {
    return (
      <Space wrap>
        <Dropdown.Button onClick={e => this.editScore(e)} placement="bottomCenter" icon={<DownOutlined />}
          overlay={<Menu>
            <Menu.Item key="1" icon={<DeleteOutlined />} onClick={e => this.deleteScore(e)}>
              Hapus
          </Menu.Item>

          </Menu>}

        >
          Lihat Detail
    </Dropdown.Button>

      </Space>
    )
  }
}

export default ActionResult;