import React from "react";
import { Empty, Table } from "antd";
import "react-toastify/dist/ReactToastify.css";
import { contactService } from "../../../../../services/contact.service";
import ActionContact from "./ActionContact";

class ContactTable extends React.Component {
  state = {
    columns: [
      {
        title: "Nama",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Isi",
        dataIndex: "content",
        key: "content",
      },
      {
        title: "Tindakan",
        dataIndex: "",
        key: "x",
        render: (row) => (
          <ActionContact
            contact={row}
            edit={this.props.edit}
            delete={this.props.delete}
          />
        ),
      },
    ],
    data: [],
  };
  componentDidMount() {
    this.getData(true);
  }

  getData(showLoading = false) {
    this.setState({ isLoading: showLoading });
    contactService.getContact().then(
      (resp) => {
        if (resp.status) {
          this.setState({
            data: resp.data,
            total: resp.total,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
  }
  componentDidUpdate(prevProps, prevState) {
    //page change call and rows count
    if (prevProps.reload !== this.props.reload) {
      this.getData(false);
    }
  }
  render() {
    let { data, columns } = this.state;
    let table = {
      data: data,
      total: this.state.total,
      columns: columns,
      isLoading: this.state.isLoading,
    };
    let emptyData = {
      emptyText: (
        <Empty
          style={{ marginTop: "3%", marginBottom: "3%" }}
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 80,
          }}
          description={<span style={{ color: "black" }}>Tidak ada data</span>}
        ></Empty>
      ),
    };
    return (
      <React.Fragment>
        <Table
          columns={table.columns}
          dataSource={table.data}
          pagination={false}
          locale={emptyData}
          scroll={{ x: true }}
        />
      </React.Fragment>
    );
  }
}
export default ContactTable;
