import React from "react";
import moment from "moment";
import {
  Card,
  Col,
  Row,
  Button,
  Input,
  Select,
  Tooltip,
  DatePicker,
} from "antd";
import ManageModal from "./ManageModal";
import { toast } from "react-toastify";
import ImageDropzone from "../../../../components/ImageDropzone";
import { participantService } from "../../../../services/participant.service";
import { categoryService } from "../../../../services/category.services";
import { addressService } from "../../../../services/address.services";
import InfoModal from "./InfoModal";

const { Option } = Select;

function onBlur() {}

function onFocus() {}

function onSearch(val) {}
class Biodata extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
    this._modalInfo = React.createRef();
  }
  state = {
    isLoading: false,
    visible: false,
    school: [],
    data: null,
    categories: [],
    listAddress: [],
    address: [],
    birth_place: [],
    profile_image: "",
    page: 1,
    limit: 1000,
    value: "",
    user_id: 0,
    progress: 1,
  };
  showModal = () => {
    this._modalManage.current.toggleModal();
  };

  onUpdated = (resp) => {
    if (resp.status) {
      participantService
        .getSchool(this.state.page, this.state.limit, this.state.value)
        .then((resp) => {
          let data = resp.data.map((item) => {
            return { name: "schoolId", label: item.name, value: item.id };
          });
          data.unshift({ name: "schoolId", label: "Semua Sekolah", value: -1 });
          this.setState({ school: data, isLoading: false });
        });
      toast.success(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
    } else
      toast.error(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };
  handleChange = (type, e) => {
    if (type === "city") {
      let data = this.state.data;
      data.city = e;

      this.setState({ address: [] });
      const fields = e.split(", ");
      const city = fields[0];
      const province = fields[1];
      const page = 1;
      const limit = 1000;

      addressService.getAddress(city, province, page, limit).then((resp) => {
        let data = resp.data.map((item) => {
          return { name: "addressId", label: item.district, value: item.id };
        });
        data.unshift({
          name: "addressId",
          label: "Semua Kecamatan",
          value: -1,
        });
        this.setState({ address: data });
      });
    } else if (type === "district") {
      const fields = e.split(",");
      const district = fields[1];
      const address_id = fields[0];

      let data = this.state.data;
      data.district = district;
      this.setState({ data });

      data.address_id = parseInt(address_id);
      this.setState({ data });
    }
  };
  onChange(date, dateString) {
    let { data } = this.state;
    data.birth_date = dateString;
    this.setState({ data });
  }

  dropImage = (files) => {
    let data = this.state.data;
    data.profile_image = URL.createObjectURL(files[0]);
    this.setState({ profile_image: files[0], data });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    let userData = localStorage.getItem("USER_DATA");
    let id = JSON.parse(userData);
    this.setState({ user_id: id.id });

    this.getData(true);
    participantService.getBiodata(id.id).then(
      (resp) => {
        if (resp.status) {
          this.setState({ data: resp.data, isLoading: false });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
    participantService.getProfileImage(id.id).then(
      (resp) => {
        if (resp.status) {
          this.setState({ data: resp.data, isLoading: false });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );

    categoryService.getCategory().then((resp) => {
      let data = resp.data.map((item) => {
        return { name: "categoryId", label: item.name, value: item.id };
      });
      data.unshift({ name: "categoryId", label: "Semua Kategori", value: -1 });
      this.setState({ categories: data, isLoading: false });
    });
    addressService.getListAddress(1, 1000).then((resp) => {
      let data = resp.data.map((item) => {
        return {
          name: "addressId",
          label: item.city + ", " + item.province,
          value: item.id,
        };
      });
      let birthPlace = resp.data.map((item) => {
        return { name: "birthPlace", label: item.city, value: item.city };
      });
      data.unshift({ name: "addressId", label: "Semua Kota", value: -1 });
      this.setState({
        listAddress: data,
        birth_place: birthPlace,
        isLoading: false,
      });
    });

    participantService.getProgress(this.state.user_id).then(
      (resp) => {
        if (resp.status) {
          this.setState({ progress: resp.data.progress, isLoading: false });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
  }

  getData() {
    participantService
      .getSchool(this.state.page, this.state.limit, this.state.value)
      .then((resp) => {
        let data = resp.data.map((item) => {
          return { name: "schoolId", label: item.name, value: item.id };
        });
        data.unshift({ name: "schoolId", label: "Semua Sekolah", value: -1 });
        this.setState({ school: data, isLoading: false });
      });
  }

  handleSubmit = (e) => {
    let userData = localStorage.getItem("USER_DATA");
    let id = JSON.parse(userData);
    e.preventDefault();
    this.setState({ isLoading: true });
    participantService
      .editUser(this.state.data, this.state.profile_image, id.id)
      .then(
        (resp) => {
          toast.success(resp.message, {
            position: toast.POSITION.TOP_CENTER,
            pauseOnFocusLoss: false,
            autoClose: 2000,
          });
          this.setState({ content: "", title: "", isLoading: false });
          if (this.state.progress <= 2) {
            this._modalInfo.current.toggleModal();
          }
        },
        (error) => {
          this.setState({ content: "", title: "", isLoading: false });
          toast.error(error, {
            position: toast.POSITION.TOP_CENTER,
            pauseOnFocusLoss: false,
            autoClose: 2000,
          });
        }
      );
  };
  render() {
    let { data } = this.state;
    let body = this.state;

    return (
      <React.Fragment>
        <Card
          // loading={this.state.isLoading}
          headStyle
          style={{
            backgroundColor: "#e8e8e8",
            minHeight: window.innerHeight,

            padding: "-5%",
          }}
        >
          <div style={{ marginTop: 20 }}>
            <div>
              <Card
                gutter={[16]}
                style={{
                  borderRadius: "5px",
                  height: "100%",
                  overflow: "hidden",
                }}
              >
                <Row gutter={[4]}>
                  <Col
                    style={{ marginTop: 40 }}
                    xs={0}
                    sm={6}
                    md={6}
                    lg={6}
                  ></Col>
                  <Col style={{ marginTop: 40 }}>
                    <ImageDropzone
                      onDropped={this.dropImage}
                      item={`user/image/${this.state.user_id}`}
                    />
                    <br />
                    <p
                      className="font-small-2"
                      style={{ color: "red", marginLeft: "2%" }}
                    >
                      Ukuran Foto maks 500 KB
                    </p>
                  </Col>
                </Row>
                {data && data.registration_number !== null && (
                  <Row style={{ marginTop: 12 }} gutter={[4]}>
                    <Col xs={24} sm={18} md={18} lg={6}>
                      <p className="font-normal">Nomor Peserta</p>
                    </Col>
                    <Col xs={24} sm={18} md={18} lg={18}>
                      <p className="font-normal">
                        {data && data.registration_number}
                      </p>
                    </Col>
                  </Row>
                )}
                <Row style={{ marginTop: 12 }} gutter={[4]}>
                  <Col xs={24} sm={6} md={6} lg={6}>
                    <p className="font-normal">
                      {" "}
                      NISN <span style={{ color: "red" }}> *</span>
                    </p>
                  </Col>
                  <Col xs={24} sm={18} md={18} lg={18}>
                    <Input
                      type="text"
                      placeholder="Masukkan NISN"
                      name="nisn"
                      value={data && data.nisn}
                      onChange={(e) => {
                        data.nisn = e.target.value;
                        this.setState({ data });
                      }}
                      disabled={
                        this.state.data?.registration_number ? true : false
                      }
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: 12 }} gutter={[4]}>
                  <Col xs={24} sm={6} md={6} lg={6}>
                    <p className="font-normal">
                      {" "}
                      Nama <span style={{ color: "red" }}> *</span>{" "}
                    </p>
                  </Col>
                  <Col xs={24} sm={18} md={18} lg={18}>
                    <Input
                      style={{ width: "100%" }}
                      type="text"
                      placeholder="Masukkan Nama Lengkap"
                      name="firstName"
                      value={data && data.name}
                      onChange={(e) => {
                        data.name = e.target.value;
                        this.setState({ data });
                      }}
                    />
                  </Col>
                </Row>
                <Row gutter={[16]}>
                  <Col style={{ marginTop: 12 }} xs={24} sm={6} md={6} lg={6}>
                    <p className="font-normal">
                      Tempat, Tanggal Lahir
                      <span style={{ color: "red" }}> *</span>
                    </p>
                  </Col>
                  <Col
                    style={{ marginTop: 12 }}
                    xs={24}
                    sm={10}
                    md={10}
                    lg={10}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Semua Kota"
                      optionFilterProp="children"
                      onChange={(e) => {
                        data.birth_place = e;
                        this.setState({ data });
                      }}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onSearch={onSearch}
                      value={this.state.data && this.state.data.birth_place}
                    >
                      {body &&
                        body.birth_place.map((item) => (
                          <Option value={item.label}>{item.label}</Option>
                        ))}
                    </Select>
                  </Col>
                  <Col style={{ marginTop: 12 }} xs={24} sm={8} md={8} lg={8}>
                    <Tooltip title="isi jika ingin mengubah">
                      <DatePicker
                        style={{ width: "100%" }}
                        placeholder="Tanggal Lahir"
                        value={data?.birth_date ? moment(data.birth_date) : ""}
                        onChange={(date, dateString) =>
                          this.onChange(date, dateString)
                        }
                      ></DatePicker>
                    </Tooltip>
                  </Col>
                </Row>
                <Row style={{ marginTop: 12 }} gutter={[4]}>
                  <Col xs={24} sm={6} md={6} lg={6}>
                    <p className="font-normal">No. Telepon </p>
                  </Col>
                  <Col xs={24} sm={18} md={18} lg={18}>
                    <Input
                      placeholder="Masukkan No Telepon"
                      name="phone"
                      value={data && data.phone_number}
                      onChange={(e) => {
                        data.phone_number = e.target.value;
                        this.setState({ data });
                      }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Col>
                </Row>
                <Row style={{ marginTop: 12 }} gutter={[4]}>
                  <Col xs={24} sm={6} md={6} lg={6}>
                    <p className="font-normal">
                      {" "}
                      Jenis Kelamin <span style={{ color: "red" }}>
                        {" "}
                        *
                      </span>{" "}
                    </p>
                  </Col>
                  <Col xs={24} sm={18} md={18} lg={18}>
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Jenis Kelamin"
                      optionFilterProp="children"
                      onChange={(e) => {
                        data.gender = e;
                        this.setState({ data });
                      }}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onSearch={onSearch}
                      value={this.state.data && this.state.data.gender}
                    >
                      <Option value="male">Laki-Laki</Option>
                      <Option value="female">Perempuan</Option>
                    </Select>
                  </Col>
                </Row>
                <Row gutter={[16]}>
                  <Col style={{ marginTop: 12 }} xs={24} sm={6} md={6} lg={6}>
                    <p className="font-normal">
                      Asal Sekolah <span style={{ color: "red" }}> *</span>
                    </p>
                  </Col>
                  <Col
                    style={{ marginTop: 12 }}
                    xs={24}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Asal Sekolah"
                      optionFilterProp="children"
                      onChange={(e) => {
                        data.school_id = e;
                        this.setState({ data });
                      }}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onSearch={onSearch}
                      value={this.state.data && this.state.data.school_id}
                    >
                      {body &&
                        body.school.map((item) => (
                          <Option value={item.value}>{item.label}</Option>
                        ))}
                    </Select>
                  </Col>
                  <Col style={{ marginTop: 12 }} xs={24} sm={6} md={6} lg={6}>
                    <Tooltip title="Tambah sekolah jika sekolahmu tidak ada di list">
                      <Button
                        style={{ width: "100%" }}
                        onClick={(e) => this.showModal()}
                      >
                        + Sekolah
                      </Button>
                    </Tooltip>
                  </Col>
                </Row>
                <Row style={{ marginTop: 12 }} gutter={[4]}>
                  <Col xs={24} sm={6} md={6} lg={6}>
                    <p className="font-normal">
                      {" "}
                      Kelas <span style={{ color: "red" }}> *</span>{" "}
                    </p>
                  </Col>
                  <Col xs={24} sm={18} md={18} lg={18}>
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Kelas"
                      optionFilterProp="children"
                      onChange={(e) => {
                        data.grade = e;
                        this.setState({ data });
                      }}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onSearch={onSearch}
                      value={this.state.data && this.state.data.grade}
                    >
                      <Option value={4}>4</Option>
                      <Option value={5}>5</Option>
                      <Option value={6}>6</Option>
                    </Select>
                  </Col>
                </Row>
                <Row style={{ marginTop: 12 }} gutter={[4]}>
                  <Col xs={24} sm={6} md={6} lg={6}>
                    <p className="font-normal">
                      {" "}
                      Kategori <span style={{ color: "red" }}> *</span>{" "}
                    </p>
                  </Col>
                  <Col xs={24} sm={18} md={18} lg={18}>
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Kategori"
                      optionFilterProp="children"
                      onChange={(e) => {
                        data.category_id = e;
                        this.setState({ data });
                      }}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onSearch={onSearch}
                      value={this.state.data && this.state.data.category_id}
                      disabled={
                        this.state.data?.registration_number ? true : false
                      }
                    >
                      {body &&
                        body.categories.map((item) => (
                          <Option value={item.value}>{item.label}</Option>
                        ))}
                    </Select>
                  </Col>
                </Row>

                <Row gutter={[16]}>
                  <Col style={{ marginTop: 12 }} xs={24} sm={6} md={6} lg={6}>
                    <p className="font-normal"> Password</p>
                  </Col>
                  <Col style={{ marginTop: 12 }} xs={24} sm={9} md={9} lg={9}>
                    <Tooltip title="Isi jika ingin mengganti password">
                      <Input.Password
                        placeholder="Masukkan Password Lama (Jika Ingin Mengganti)"
                        name="old_password"
                        onChange={(e) => {
                          data.old_password = e.target.value;
                          this.setState({ data });
                        }}
                      ></Input.Password>{" "}
                    </Tooltip>
                  </Col>
                  <Col style={{ marginTop: 12 }} xs={24} sm={9} md={9} lg={9}>
                    <Tooltip title="Isi jika ingin mengganti password">
                      <Input.Password
                        placeholder="Masukkan Password Baru (Jika Ingin Mengganti)"
                        name="password"
                        value={data && data.password}
                        onChange={(e) => {
                          data.password = e.target.value;
                          this.setState({ data });
                        }}
                      />
                    </Tooltip>
                  </Col>
                </Row>
                <Row gutter={[16]}>
                  <Col style={{ marginTop: 12 }} xs={24} sm={6} md={6} lg={6}>
                    <p className="font-normal">
                      {" "}
                      Alamat <span style={{ color: "red" }}> *</span>{" "}
                    </p>
                  </Col>

                  <Col style={{ marginTop: 12 }} xs={24} sm={9} md={9} lg={9}>
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Semua Kota"
                      optionFilterProp="children"
                      onChange={(e) => this.handleChange("city", e)}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onSearch={onSearch}
                      value={data && data.city}
                    >
                      {body &&
                        body.listAddress.map((item) => (
                          <Option value={item.label}>{item.label}</Option>
                        ))}
                    </Select>
                  </Col>
                  <Col style={{ marginTop: 12 }} xs={24} sm={9} md={9} lg={9}>
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Semua Kecamatan"
                      optionFilterProp="children"
                      onChange={(e) => this.handleChange("district", e)}
                      onFocus={onFocus}
                      onBlur={onBlur}
                      onSearch={onSearch}
                      value={data && data.district}
                    >
                      {body &&
                        body.address.map((item) => (
                          <Option value={item.value + "," + item.label}>
                            {item.label}
                          </Option>
                        ))}
                    </Select>
                  </Col>
                </Row>
                <Row style={{ marginTop: 12 }} gutter={[4]}>
                  <Col xs={0} sm={6} md={6} lg={6}></Col>
                  <Col xs={24} sm={18} md={18} lg={18}>
                    <Input
                      type="text"
                      placeholder="Masukkan alamat lengkap"
                      name="address"
                      value={data && data.address_detail}
                      onChange={(e) => {
                        data.address_detail = e.target.value;
                        this.setState({ data });
                      }}
                    />
                  </Col>
                </Row>
                <div style={{ float: "right", marginTop: "4%" }}>
                  <Button
                    loading={this.state.isLoading}
                    type="primary"
                    style={{ float: "right" }}
                    onClick={this.handleSubmit}
                    disabled={
                      data
                        ? data.name === null ||
                          data.nisn === null ||
                          data.birth_place === null ||
                          data.birth_date === null ||
                          data.gender === null ||
                          data.school_id === null ||
                          data.grade === null ||
                          data.category_id === null ||
                          data.address_id === null ||
                          data.address_detail === null
                        : ""
                    }
                  >
                    Perbarui
                  </Button>
                </div>
              </Card>
            </div>
          </div>
        </Card>
        <ManageModal ref={this._modalManage} onUpdated={this.onUpdated} />
        <InfoModal ref={this._modalInfo} />
      </React.Fragment>
    );
  }
}
export default Biodata;
