import React from "react";
import { Modal, Form, Button } from "antd";
import { Link } from "react-router-dom";

class InfoModal extends React.Component {

    state = {
        isOpen: false,
        data: null,
       
    };



    toggleModal = () => {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    };
    handleCancel = () => {
        this.setState({ isOpen: false });
        if (this.state.type !== "") {
            this.setState({ isOpen: false });
        }
    };


    render() {
        return (
            <React.Fragment>
                <Modal
                    title="Informasi"
                    centered
                    onCancel={this.handleCancel}
                    visible={this.state.isOpen}
                    toggle={this.toggleModal}
                    footer={[
                        <Button key="back" style={{marginRight: '2%'}} onClick={this.handleCancel}>
                            Batalkan
                        </Button>,
                        <Link to="/profile/payment">
                        <Button key="submit" type="primary">
                            Ya,lanjut
                        </Button>
                      </Link>
                        
                    ]}
                    style={{ width: "100%", resize: "none" }}
                >
                    <Form >
                        <p>Apakah kamu ingin melanjutkan pembayaran?</p>

                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}

export default InfoModal;
