import React from "react";
import "antd/dist/antd.css";
import { Table, Empty } from "antd";
import { scoreService } from "../../../../../services/score.service";
import ActionStatus from "./ActionStatus";

class StatusTable extends React.Component {
  state = {
    columns: [
      {
        title: "Tanggal Import",
        dataIndex: "import_date",
        key: "impoort_date",
      },
      {
        title: "Total Data",
        dataIndex: "total_data",
        key: "total_data",
        responsive: ["md"],
      },
      {
        title: "Data Error",
        dataIndex: "error",
        key: "error",
      },
      {
        title: "Tindakan",
        dataIndex: "",
        key: "x",
        render: (row) => <ActionStatus status={row} edit={this.props.edit} />,
      },
    ],
    data: [],
    page: 1,
    limit: 10,
    total: 0,
    isLoading: true,
  };
  componentDidMount() {
    this.getData(true);
  }

  getData(showLoading = false) {
    this.setState({ isLoading: showLoading });
    scoreService.getStatusImport(this.state.page, this.state.limit).then(
      (resp) => {
        if (resp.status) {
          this.setState({
            data: resp.data,
            total: resp.total,
            isLoading: false,
          });
        }
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
  }
  onChange = (page, pageSize) => {
    this.setState({ page: page, limit: pageSize });
  };

  componentDidUpdate(prevProps, prevState) {
    //page change call and rows count
    if (
      prevProps.reload !== this.props.reload ||
      prevState.page !== this.state.page ||
      prevState.limit !== this.state.limit
    ) {
      this.getData(false);
    }
  }

  render() {
    let { data, columns } = this.state;
    let table = {
      data: data,
      total: this.state.total,
      columns: columns,
      isLoading: this.state.isLoading,
    };
    let emptyData = {
      emptyText: (
        <Empty
          style={{ marginTop: "3%", marginBottom: "3%" }}
          image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
          imageStyle={{
            height: 80,
          }}
          description={<span style={{ color: "black" }}>Tidak ada data</span>}
        ></Empty>
      ),
    };
    return (
      <React.Fragment>
        <Table
          columns={table.columns}
          dataSource={table.data}
          pagination={false}
          loading={table.isLoading}
          locale={emptyData}
          scroll={{ x: true }}
        />
      </React.Fragment>
    );
  }
}

export default StatusTable;
