import React from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import Dashboard from "./views/Dashboard/Index";
import { Layout } from "antd";
import AppHeader from "./components/header";
import Sidebar from "./components/Sidebar";
import { useLocation, Redirect } from "react-router-dom";
const {  Content } = Layout;

function App() {
  const location = useLocation();
  if (
    (location.pathname !== "/score" &&
      location.pathname !== "/dashboard" &&
      location.pathname !== "/register" &&
      !localStorage.getItem("USER_DATA")) ||
    (localStorage.getItem("USER_DATA") && location.pathname === "/")
  ) {
    return <Redirect to="/dashboard" />;
  } else if (
    localStorage.getItem("USER_DATA") &&
    (location.pathname === "/profile" || location.pathname === "/profile/admin")
  ) {
    if (JSON.parse(localStorage.getItem("USER_DATA")).role === "admin") {
      return <Redirect to="/profile/admin/ringkasanAdmin" />;
    } else {
      return <Redirect to="/profile/ringkasan" />;
    }
  } else {
    return (
      <React.Fragment>
        <Layout className="mainLayout">
          <AppHeader />
          <Content>
            {location.pathname === "/score" ||
            location.pathname === "/dashboard" ||
            location.pathname === "/register" ? (
              <Dashboard />
            ) : (
              <Sidebar />
            )}
          </Content>
          <ToastContainer />
        </Layout>
      </React.Fragment>
    );
  }
}

export default App;
