import React from "react";
import StatusTable from "./StatusTable";
import ManageModal from "./ManageModal";
import { scoreService } from "../../../../../services/score.service";
import { toast } from "react-toastify";

class Status extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
  }

  state = {
    reload: false,
    status: null,
  };

  toggleModalManage = () => {
    this._modalManage.current.toggleModal();
  };

  edit = (status) => {
    this.setState({ status: status, reload: false });
    this.toggleModalManage();
  };

  viewStatus = (import_date) => {
    scoreService
      .viewStatusImport({
        import_date: import_date,
      })
      .then(
        (resp) => {
          this.setState({ isLoading: false });
          this.toggleModal();
          toast.success(resp.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2500,
          });
        },
        (err) => {
          this.setState({ isLoading: false });
          toast.error(err, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
        }
      );
  };
  render() {
    return (
      <React.Fragment>
        <div style={{ marginTop: "3%" }}>
          <StatusTable reload={this.state.reload} edit={this.edit} />
        </div>

        <ManageModal status={this.state.status} ref={this._modalManage} />
      </React.Fragment>
    );
  }
}
export default Status;
