import { mainConfig } from '../config/mainConfig';
import { authHeader, handleResponse} from './auth.header';

export const contactService = {
   getContact,
   addContact,
   editContact,
   deleteContact
};

function getContact() {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/contact`, reqOpt).then(handleResponse)
}
function addContact(data){
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${mainConfig.apiUrl}/contact`, reqOpt).then(handleResponse)
}
function editContact(id, data){
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${mainConfig.apiUrl}/contact/${id}`, reqOpt).then(handleResponse)
}
function deleteContact(id){
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/contact/${id}`, reqOpt).then(handleResponse)
}

