// import { authHeader } from '../_helpers';
import { mainConfig } from '../config/mainConfig';
import { authHeader, handleResponse} from './auth.header';

export const scheduleService = {
   getSchedule,
   getScheduleScore,
   addSchedule,
   editSchedule,
   deleteSchedule
};

function getSchedule(page, limit) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/phase?page=${page}&per_page=${limit}`, reqOpt).then(handleResponse)
}
function getScheduleScore(page, limit, type) {
    const reqOpt = {
        method: 'GET',
        headers: authHeader()   
    }

    return fetch(`${mainConfig.apiUrl}/phase?page=${page}&per_page=${limit}&type=${type}`, reqOpt).then(handleResponse)
}
function addSchedule(data){
    const reqOpt = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${mainConfig.apiUrl}/phase`, reqOpt).then(handleResponse)
}
function editSchedule(id, data){
    const reqOpt = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(data)
    }

    return fetch(`${mainConfig.apiUrl}/phase/${id}`, reqOpt).then(handleResponse)
}
function deleteSchedule(id){
    const reqOpt = {
        method: 'DELETE',
        headers: authHeader()
    }

    return fetch(`${mainConfig.apiUrl}/phase/${id}`, reqOpt).then(handleResponse)
}

