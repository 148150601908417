import React from "react";
import { Modal, Form, Input, Button } from "antd";
import { toast } from "react-toastify";
import { SettingService } from "../../../../services/setting.services";

class ManageModal extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
  }
  state = {
    isOpen: false,
    old_password: "",
    password: "",
    isLoading: false,
  };

  showModal = (type) => {
    this.setState({ type: type });
    this._modalManage.current.toggleModal();
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
    if (this.state.type !== "") {
      this.setState({ isOpen: false });
    }
  };
  onUpdated = (resp) => {
    if (resp.status) {
      this.setState({ data: null, reload: true });
      toast.success(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
    } else
      toast.error(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
  };

  handleSubmit = (e) => {
    this.setState({ isLoading: true });
    SettingService.editSetting({
      old_password: this.state.old_password,
      password: this.state.password,
    }).then(
      (resp) => {
        this.toggleModal();
        this.setState({
          isLoading: false,
          data: null,
          old_password: "",
          password: "",
        });
        this.onUpdated(resp);
      },
      (error) => {
        this.toggleModal();
        this.setState({
          isLoading: false,
          data: null,
          old_password: "",
          password: "",
        });
        this.onUpdated({
          isUpdated: false,
          status: false,
          message: error,
          data: null,
        });
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          title="Ganti Kata Sandi "
          centered
          visible={this.state.isOpen}
          toggle={this.toggleModal}
          footer={null}
          style={{ width: "100%", resize: "none" }}
          onCancel={this.handleCancel}
        >
          <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
            <Form.Item
              label="Password Lama"
              name="old_password"
              onChange={(e) => this.setState({ old_password: e.target.value })}
              rules={[
                {
                  required: true,
                  message: "Masukkan Password Lama!",
                },
              ]}
            >
              <Input.Password placeholder="Ketik Password Lama" />
            </Form.Item>

            <Form.Item
              label="Password Baru"
              name="password"
              onChange={(e) => this.setState({ password: e.target.value })}
              rules={[
                {
                  required: true,
                  message: "Masukkan Password!",
                },
              ]}
            >
              <Input.Password placeholder="Ketik Password Baru" />
            </Form.Item>

            <Button
              loading={this.state.isLoading}
              type="primary"
              style={{ width: "100%" }}
              onClick={(e) => this.handleSubmit()}
            >
              Ganti Password
            </Button>
          </Form>
        </Modal>

      </React.Fragment>
    );
  }
}

export default ManageModal;
