import React from "react";
import CardDashboard from "../../views/Dashboard/CardDashboard";
import Profile from "../../views/Profile/Profile";
import Ringkasan from "../../views/Profile/User/Ringkasan/Ringkasan";
import RingkasanAdmin from "../../views/Profile/Admin/RingkasanAdmin";
import Pengumuman from "../../views/Profile/User/Pengumuman";
import Announcement from "../../views/Profile/Admin/Announcement/Annaouncement";
import Participants from "../../views/Profile/Admin/Participants/Index";
import PaymentAdmin from "../../views/Profile/Admin/Payment/Payment";
import Biodata from "../../views/Profile/User/Biodata/Biodata";
import Payment from "../../views/Profile/User/Payment/Payment";
import Result from "../../views/Profile/User/Result";
import ResultAdmin from "../../views/Profile/Admin/Result/Index";
import Settings from "../../views/Profile/Admin/Settings/Settings";
import Score from "../../views/Score/Index";
import Register from "../../views/RegisterPage/Index";
import {
  AppstoreOutlined,
  NotificationOutlined,
  UserOutlined,
  DollarOutlined,
  OrderedListOutlined,
  SettingOutlined,
} from "@ant-design/icons";

const AppRoute = () => {
  return [
    {
      path: "/register",
      link: "/register",
      type: "register",
      as: "Navigation Register",
      name: "Register",
      component: Register,
    },
    {
      path: "/dashboard",
      link: "/dashboard",
      type: "home",
      as: "Navigation Home",
      name: "Beranda",
      menu: "Navbar",
      key: "1",
      component: CardDashboard,
    },
    {
      path: "/score",
      link: "/score",
      type: "score",
      as: "Navigation Score",
      name: "Papan Nilai",
      menu: "Navbar",
      key: "2",
      component: Score,
    },
    {
      path: "/profile",
      link: "/profile",
      type: "profile",
      as: "Navigation Profile",
      name: "Profil",
      menu: "Navbar",
      component: Profile,
    },
    {
      path: "/profile/ringkasan",
      link: "/profile/ringkasan",
      type: "ringkasan",
      as: "Navigation Ringkasan",
      name: "Ringkasan",
      menu: "Sidebar",
      role: "user",
      icon: <AppstoreOutlined />,
      component: Ringkasan,
    },

    {
      path: "/profile/pengumuman",
      link: "/profile/pengumuman",
      type: "pengumuman",
      as: "Navigation Pengumuman",
      name: "Pengumuman",
      menu: "Sidebar",
      role: "user",
      icon: <NotificationOutlined />,
      component: Pengumuman,
    },

    {
      path: "/profile/biodata",
      link: "/profile/biodata",
      type: "Biodata",
      as: "Navigation Biodata",
      name: "Biodata",
      menu: "Sidebar",
      role: "user",
      icon: <UserOutlined />,
      component: Biodata,
    },
    {
      path: "/profile/payment",
      link: "/profile/payment",
      type: "payment",
      as: "Navigation Payment",
      name: "Pembayaran",
      menu: "Sidebar",
      role: "user",
      icon: <DollarOutlined />,
      component: Payment,
    },
    {
      path: "/profile/result",
      link: "/profile/result",
      type: "result",
      as: "Navigation Result",
      name: "Hasil Olimpiade",
      menu: "Sidebar",
      role: "user",
      icon: <OrderedListOutlined />,
      component: Result,
    },
    {
      path: "/profile/biodata",
      link: "/profile/biodata",
      type: "Biodata",
      as: "Navigation Biodata",
      name: "Biodata",
      role: "admin",
      icon: <UserOutlined />,
      component: Biodata,
    },
    {
      path: "/profile/admin/ringkasanAdmin",
      link: "/profile/admin/ringkasanAdmin",
      type: "ringkasan",
      as: "Navigation Ringkasan",
      name: "Ringkasan",
      menu: "Sidebar",
      role: "admin",
      icon: <AppstoreOutlined />,
      component: RingkasanAdmin,
    },
    {
      path: "/profile/admin/pengumumanAdmin",
      link: "/profile/admin/pengumumanAdmin",
      type: "pengumuman",
      as: "Navigation Pengumuman",
      name: "Pengumuman",
      menu: "Sidebar",
      role: "admin",
      icon: <NotificationOutlined />,
      component: Announcement,
    },
    {
      path: "/profile/admin/participants",
      link: "/profile/admin/participants",
      type: "logout",
      as: "Navigation Logout",
      name: "Peserta",
      menu: "Sidebar",
      icon: <UserOutlined />,
      role: "admin",
      component: Participants,
    },
    {
      path: "/profile/admin/paymentAdmin",
      link: "/profile/admin/paymentAdmin",
      type: "logout",
      as: "Navigation Pembayaran",
      name: "Pembayaran",
      menu: "Sidebar",
      role: "admin",
      icon: <DollarOutlined />,
      component: PaymentAdmin,
    },

    {
      path: "/profile/admin/hasilOlimpiade",
      link: "/profile/admin/hasilOlimpiade",
      type: "pengumuman",
      as: "Navigation Pengumuman",
      name: "Hasil Olimpiade",
      menu: "Sidebar",
      role: "admin",
      icon: <OrderedListOutlined />,
      component: ResultAdmin,
    },
    {
      path: "/profile/admin/settings",
      link: "/profile/admin/settings",
      type: "pengumuman",
      as: "Navigation Pengumuman",
      name: "Pengaturan",
      menu: "Sidebar",
      role: "admin",
      icon: <SettingOutlined />,
      component: Settings,
    },
  ];
};

export default AppRoute();
