import React from "react";
import "antd/dist/antd.css";
import "./index.css";
import "../font.css";
import { Layout } from "antd";
import AppRoute from "../../config/router/router";
import { Route, Switch } from "react-router-dom";
const { Content } = Layout;

class Dashboard extends React.Component {
  render() {
    return (
      <Layout>
        {/* {window.location.href.slice(21) === "/home" ? <Sidebar/> : ""} */}

        <Content>
          <Switch>
            {AppRoute.filter(
              (it) => it.menu === "Navbar" || it.name === "Register"
            ).map((item, i) => {
              return (
                <Route exact path={item.path} component={item.component} />
              );
            })}
          </Switch>
          {/* <CardDashboard/> */}
        </Content>
      </Layout>
    );
  }
}

export default Dashboard;
