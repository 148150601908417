import React from "react";
import "antd/dist/antd.css";
import { Row, Col, Select, Modal, Form, Button } from "antd";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import ImportTable from "./ImportTable";
import { categoryService } from "../../../../../services/category.services";
import { scheduleService } from "../../../../../services/schedule.services";
import BasicDropzone from "../../../../../components/BasicDropzone";
import { scoreService } from "../../../../../services/score.service";
import ManageModal from "./ManageModal";
const { Option } = Select;

function onBlur() {}

function onFocus() {}

function onSearch(val) {}
class Import extends React.Component {
  constructor(props) {
    super(props);
    this.fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    this.fileExtension = ".xlsx";
    this._modalManage = React.createRef();
  }

  state = {
    active: "1",
    score: [],
    isLoading: false,
    categories: [],
    phase: [],
    phaseId: -1,
    categoryId: -1,
  };

  showModal = () => {
    this._modalManage.current.toggleModal();
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };
  handleSubmit = (e) => {
    this.setState({ isLoading: true });
    scoreService
      .importScore({
        category_id: this.state.categoryId,
        phase_id: this.state.phaseId,
        score: this.state.score,
      })
      .then(
        (resp) => {
          this.setState({ isLoading: false });
          this.toggleModal();
          toast.success(resp.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2500,
          });
        },
        (err) => {
          this.setState({ isLoading: false });
          toast.error(err, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
        }
      );
  };

  componentDidMount() {
    categoryService.getCategory().then((resp) => {
      let data = resp.data.map((item) => {
        return { name: "categoryId", label: item.name, value: item.id };
      });
      data.unshift({ name: "categoryId", label: "Semua Kategori", value: -1 });
      this.setState({ categories: data });
    });
    scheduleService.getScheduleScore(1, 1000, "competition").then(
      (resp) => {
        let data = resp.data.map((item) => {
          return { name: "phaseId", label: item.name, value: item.id };
        });
        data.unshift({ name: "phaseId", label: "Semua Jadwal", value: -1 });
        this.setState({ phase: data });
      },
      (error) => {
        this.setState({ isLoading: false });
      }
    );
  }
  onDropped = (files) => {
    let fileReader = new FileReader();
    fileReader.readAsBinaryString(files[0]);
    fileReader.onloadend = (e) => {
      let data = e.target.result;
      let workbook = XLSX.read(data, {
        type: "binary",
      });

      let scoreJSON = workbook.SheetNames.map((item) => {
        return XLSX.utils.sheet_to_row_object_array(workbook.Sheets[item]);
      });

      scoreJSON[0].map((item) => {
        delete item.Nama;
        let Heading = [
          [
            "registration_number",
            "answer_right",
            "answer_wrong",
            "not_answered",
            "score",
            "rank",
            "description",
          ],
        ];
        const ws = XLSX.utils.book_new();
        XLSX.utils.sheet_add_aoa(ws, Heading);
        let c = XLSX.utils.sheet_add_json(ws, [item], {
          origin: "A2",
          skipHeader: true,
        });
        let score = XLSX.utils.sheet_to_row_object_array(c);
        this.state.score.push(score[0]);

        return this.state.products;
      });

      let score = this.state.score;
      this.setState({ score });
    };

    fileReader.onerror = (err) => {
      this.setState({ score: [] });
    };
  };

  render() {
    let data = this.state;
    return (
      <React.Fragment>
        <Row gutter={[16]} style={{ textAlign: "right" }}>
          <Col style={{ marginTop: 16 }} xs={0} sm={8} md={12} lg={16}></Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={8} md={6} lg={4}>
            <Button
              type="primary"
              style={{ float: "right", width: "100%" }}
              onClick={this.toggleModal}
            >
              Impor Dari Excel
            </Button>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={8} md={6} lg={4}>
            <Button
              type="primary"
              style={{ float: "right", width: "100%" }}
              onClick={this.showModal}
            >
              Unduh Template
            </Button>
          </Col>
        </Row>
        <ImportTable />
        <Modal
          title="Impor Nilai"
          centered
          visible={this.state.isOpen}
          toggle={this.toggleModal}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              Batalkan
            </Button>,
            <Button
              loading={this.state.isLoading}
              key="submit"
              type="primary"
              onClick={(e) => this.handleSubmit(e)}
            >
              Impor Nilai
            </Button>,
          ]}
          style={{ width: "100%", resize: "none" }}
        >
          <Form>
            <Row gutter={[12]}>
              <Col xs={24} sm={5} md={5} lg={5}>
                <label>
                  Kategori <span style={{ color: "red" }}> *</span>
                </label>
              </Col>
              <Col xs={24} sm={18} md={18} lg={18}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Semua Kategori"
                  optionFilterProp="children"
                  onChange={(e) => {
                    this.setState({ categoryId: e });
                  }}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  value={this.state.categoryId}
                >
                  {data.categories.map((item) => (
                    <Option value={item.value}>{item.label}</Option>
                  ))}
                </Select>
              </Col>
            </Row>

            <Row style={{ marginTop: "5%" }}>
              <Col xs={24} sm={5} md={5} lg={5}>
                <label>
                  Tahap <span style={{ color: "red" }}> *</span>
                </label>
              </Col>
              <Col xs={24} sm={18} md={18} lg={18}>
                <Select
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Semua Tahap"
                  optionFilterProp="children"
                  onChange={(e) => {
                    this.setState({ phaseId: e });
                  }}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  value={this.state.phaseId}
                >
                  {data.phase.map((item) => (
                    <Option value={item.value}>{item.label}</Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row style={{ marginTop: "5%", marginLeft: "5%" }}>
              <Col xs={24} sm={5} md={5} lg={5}></Col>
              <Col xs={24} sm={18} md={18} lg={18}>
                <BasicDropzone onDropped={this.onDropped} />
              </Col>
            </Row>
          </Form>
        </Modal>
        <ManageModal ref={this._modalManage} />
      </React.Fragment>
    );
  }
}

export default Import;
