import React from 'react';
import 'antd/dist/antd.css';
import { Menu, Dropdown, Space } from 'antd';
import { DownOutlined, DeleteOutlined } from '@ant-design/icons';

class ActionContact extends React.Component {
  state = {
    contact: this.props.contact
}
  editContact = () => {
    this
        .props
        .edit(this.state.contact)
}

deleteContact = () => {
  this
    .props
    .delete(this.state.contact)
}

componentDidUpdate(prevProps, prevState) {
  if(prevProps.contact !== this.props.contact) {
      this.setState({contact: this.props.contact})
  }
}

  render() {
    return (
      <Space wrap>
      <Dropdown.Button onClick={e => this.editContact(e)} placement="bottomCenter" icon={<DownOutlined />}
        overlay={<Menu>
          <Menu.Item key="1" icon={<DeleteOutlined />} onClick={e => this.deleteContact(e)}>
            Hapus
        </Menu.Item>

        </Menu>}

      >
        Lihat Detail
  </Dropdown.Button>

    </Space>
    )
  }
}

export default ActionContact;