import { mainConfig } from "../../../../config/mainConfig";
import React from "react";
import {
  Modal,
  Form,
  Button,
  Row,
  Col,
  Card,
  Tooltip,
  Input,
  Image,
} from "antd";
import { paymenttService } from "../../../../services/payment.service";
import { participantService } from "../../../../services/participant.service";
import { CheckCircleOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
class ManageModal extends React.Component {
  state = {
    data: this.props.payment,
    isOpen: false,
    isLoading: false,
    pay_date: "",
    noRef: "",
    user_name: "",
    nisn: "",
    name: "",
    payment_status: "",
    user_id: 0,
    dataUser: [],
  };

  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };
  handleConfirm = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    paymenttService
      .updatePaymentStatus(this.state.data.id, {
        is_confirmed: true,
      })
      .then(
        (resp) => {
          this.toggleModal();
          this.setState({
            isLoading: false,
            data: null,
          });
          this.props.onUpdated(resp);
        },
        (error) => {
          this.toggleModal();
          this.setState({
            isLoading: false,
            data: null,
          });
          this.props.onUpdated({
            isUpdated: true,
            status: false,
            message: error,
            data: null,
          });
        }
      );
  };

  handleCanceled = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    paymenttService
      .updatePaymentStatus(this.state.data.id, {
        is_confirmed: false,
      })
      .then(
        (resp) => {
          this.toggleModal();
          this.setState({
            isLoading: false,
            data: null,
          });
          this.props.onUpdated(resp);
        },
        (error) => {
          this.toggleModal();
          this.setState({
            isLoading: false,
            data: null,
          });
          this.props.onUpdated({
            isUpdated: true,
            status: false,
            message: error,
            data: null,
          });
        }
      );
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.payment !== this.props.payment) {
      this.getData(false);
      if (this.props.payment)
        this.setState({
          isLoading: false,
          id: this.props.payment.id,
          data: this.props.payment,
          pay_date: this.props.payment.pay_date,
          name: this.props.payment.name,
          user_name: this.props.payment.user_name,
          noRef: this.props.payment.ref_no,
          nisn: this.props.payment.nisn,
          payment_status: this.props.payment.payment_status,
          user_id: this.props.payment.user_id,
          offline_approve: this.props.payment.offline_approve
        });
      else
        this.setState({
          isLoading: false,
          data: null,
          name: "",
          content: "",
        });
    }
  }

  getData() {
    window.scrollTo(0, 0);

    participantService
      .getUser(this.props.payment && this.props.payment.user_id)
      .then(
        (resp) => {
          if (resp.status) {
            this.setState({
              dataUser: resp.data,
              total: resp.total,
              isLoading: false,
            });
          }
        },
        (error) => {
          this.setState({ isLoading: false });
        }
      );
  }

  render() {
    return (
      <Modal
        title="Konfirmasi Pembayaran"
        centered
        visible={this.state.isOpen}
        toggle={this.toggleModal}
        onCancel={this.handleCancel}
        width={700}
        footer={[
          <>
            <Row gutter={[4, 16]}>
              <Col xs={24} sm={8} md={8} lg={8}>
                <Button
                  type="danger"
                  key="back"
                  onClick={this.handleCanceled}
                  loading={this.state.isLoading}
                  style={{ width: "100%" }}
                >
                  Batalkan Pembayaran
                </Button>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8}>
                <Button
                  key="submit"
                  type="primary"
                  disabled={this.state.payment_status === "paid"}
                  onClick={this.handleConfirm}
                  style={{ width: "100%" }}
                >
                  Konfirmasi Pembayaran
                </Button>
              </Col>
              <Col xs={24} sm={8} md={8} lg={8}>
                <ReactToPrint
                  pageStyle="
                    @page {
                      size: a5 landscape;
                      margin: 0;
                    }
                    "
                  trigger={() => (
                    <Tooltip title="Harap cetak menggunakan ukuran kertas A5 dan posisi potrait">
                      <Button
                        type="primary"
                        style={{
                          width: "100%",
                        }}
                        disabled={this.state.offline_approve}
                      >
                        Cetak Bukti Pembayaran
                      </Button>
                    </Tooltip>
                  )}
                  content={() => this.componentRef}
                />
                <div
                  className="print-source"
                  ref={(el) => (this.componentRef = el)}
                >
                  <Card>
                    <Row style={{ textAlign: "left" }}>
                      <Col span={6}>
                        <label>NISN</label>
                      </Col>
                      <Col span={18}>
                        <Input value={this.state && this.state.nisn} />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "4px", textAlign: "left" }}>
                      <Col span={6}>
                        <label>Nama</label>
                      </Col>
                      <Col span={18}>
                        <Input value={this.state && this.state.user_name} />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "4px", textAlign: "left" }}>
                      <Col span={6}>
                        <label>Tanggal Pembayaran</label>
                      </Col>
                      <Col span={18}>
                        <Input value={this.state && this.state.pay_date} />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "4px", textAlign: "left" }}>
                      <Col span={6}>
                        <label>Nama Pengirim</label>
                      </Col>
                      <Col span={18}>
                        <Input value={this.state && this.state.name} />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "4px", textAlign: "left" }}>
                      <Col span={6}>
                        <label>Nomor Referensi</label>
                      </Col>
                      <Col span={18}>
                        <Input value={this.state && this.state.noRef} />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "4px", textAlign: "left" }}>
                      <Col span={6}>
                        <label>Bukti</label>
                      </Col>
                      <Col span={18}>
                      <img
                          style={{
                            width: "325px",
                            height: "325px",
                          }}
                          src={`${mainConfig.apiUrl}/payment/image/${this.state.data?.id}`}
                          alt="Bukti Pembayaran"
                        />
                      </Col>
                    </Row>
                  </Card>
                </div>
              </Col>
            </Row>
          </>,
        ]}
      >
        <Row gutter={[16]}>
          <Col style={{ marginTop: 16 }} xs={24} sm={8} md={8} lg={8}>
            <Card
              className="font-normal"
              style={{
                color:
                  this.state.payment_status === "due" ||
                  !this.state.payment_status
                    ? "#ec4646"
                    : "black",
                borderColor:
                  this.state.payment_status === "due" ||
                  !this.state.payment_status
                    ? "#ec4646"
                    : "",
                height: "100%",
                width: "100%",
                overflow: "hidden",
                textAlign: "center",
                paddingTop: 0,
              }}
            >
              Belum Dibayar
            </Card>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={8} md={8} lg={8}>
            <Card
              className="font-normal"
              style={{
                borderColor:
                  this.state.payment_status === "waiting" ? "#ffcc29" : "",
                color:
                  this.state.payment_status === "waiting" ? "#ffcc29" : "black",
                height: "100%",
                width: "100%",
                overflow: "hidden",
                textAlign: "center",
                marginTop: 0,
              }}
            >
              Menunggu Konfirmasi
            </Card>
          </Col>
          <Col style={{ marginTop: 16 }} xs={24} sm={8} md={8} lg={8}>
            <Card
              className="font-normal"
              style={{
                borderColor:
                  this.state.payment_status === "paid" ? "#00af91" : "",
                color:
                  this.state.payment_status === "paid" ? "#00af91" : "black",
                height: "100%",
                width: "100%",
                overflow: "hidden",
                textAlign: "center",
              }}
            >
              Terbayar
              <CheckCircleOutlined
                style={{ marginLeft: "6%", verticalAlign: "middle" }}
              />
            </Card>
          </Col>
        </Row>

        <Form
          labelCol={{ span: 3 }}
          wrapperCol={{ span: 21 }}
          style={{ marginTop: "5%" }}
        >
          <Row style={{ marginLeft: "5%" }}>
            <Col span={9}>
              <p className="font-normal">Nama</p>
            </Col>
            <Col span={15}>
              <p className="font-normal">{this.state.user_name}</p>
            </Col>
          </Row>

          <Row style={{ marginLeft: "5%" }}>
            <Col span={9}>
              <p className="font-normal">NISN</p>
            </Col>
            <Col span={15}>
              <p className="font-normal">{this.state.nisn}</p>
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%" }}>
            <Col span={9}>
              <p className="font-normal">Tanggal Pembayaran</p>
            </Col>
            <Col span={15}>
              <p className="font-normal">{this.state.pay_date}</p>
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%" }}>
            <Col span={9}>
              <p className="font-normal">Nama Pengirim</p>
            </Col>
            <Col span={15}>
              <p className="font-normal">{this.state.name}</p>
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%" }}>
            <Col span={9}>
              <p className="font-normal">Nomor Referensi</p>
            </Col>
            <Col span={15}>
              <p className="font-normal">{this.state.noRef}</p>
            </Col>
          </Row>
          <Row style={{ marginLeft: "5%", marginBottom: "3%" }}>
            <Col span={9}>
              <p className="font-normal"> Bukti Transfer </p>
            </Col>

            {this.state.offline_approve ? (
                <Col>
                Diverifikasi Offline
                </Col>
              ) : (
                <Col span={15}>
                <Image
                  src={`${mainConfig.apiUrl}/payment/image/${this.state.id}`}
                  className="dz-img"
                  style={{ width: 160, height: 160, padding: "0.8rem" }}
                  alt="Bukti Pembayaran"
                />
              </Col>
              )
            }
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default ManageModal;
