import React from "react";
import "antd/dist/antd.css";
import ManageModal from "./ManageModal";
import { toast } from "react-toastify";
import DeleteModal from "../../../../components/DeleteModal";
import ResultTable from "./ResultTable";
import { categoryService } from "../../../../services/category.services";
import { scheduleService } from "../../../../services/schedule.services";
import { scoreService } from "../../../../services/score.service";
import { participantService } from "../../../../services/participant.service";

class Result extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
    this._modalDelete = React.createRef();
  }

  state = {
    score: null,
    reload: false,
    categories: [],
    phase: [],
    school: [],
  };

  toggleModalManage = (score) => {
    if (score) {
      this.setState({ score: score, reload: false });
    } else {
      this.setState({ score: null, reload: false });
    }

    this._modalManage.current.toggleModal();
  };

  toggleModalDelete = () => {
    this._modalDelete.current.toggleModal();
  };

  edit = (score) => {
    this.toggleModalManage(score);
  };

  delete = (score) => {
    this.setState({ score: score, reload: false });
    this.toggleModalDelete();
  };

  onUpdated = (resp) => {
    if (resp.status) {
      this.setState({ contact: null, reload: true });
      toast.success(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
    } else
      toast.error(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
  };
  deleteScore = (id) => {
    scoreService.deleteScore(id).then(
      (resp) => {
        this.toggleModalDelete();
        toast.success(resp.message, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
        });
        this.setState({ score: null, reload: true });
      },
      (err) => {
        this.toggleModalDelete();
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
        });
      }
    );
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    categoryService.getCategory().then((resp) => {
      let data = resp.data.map((item) => {
        return { name: "categoryId", label: item.name, value: item.id };
      });
      data.unshift({ name: "categoryId", label: "Semua Kategori", value: -1 });
      this.setState({ categories: data });
    });
    scheduleService.getScheduleScore(1, 1000, "competition").then((resp) => {
      let data = resp.data.map((item) => {
        return { name: "phaseId", label: item.name, value: item.id };
      });
      data.unshift({ name: "phaseId", label: "Semua Jadwal", value: -1 });
      this.setState({ phase: data });
    });
    participantService.getSchool(1, 1000, "").then((resp) => {
      let data = resp.data.map((item) => {
        return { name: "schoolId", label: item.name, value: item.id };
      });
      data.unshift({ name: "schoolId", label: "Semua Sekolah", value: -1 });
      this.setState({ school: data });
    });
  }
  render() {
    return (
      <React.Fragment>
        <ResultTable
          edit={this.edit}
          reload={this.state.reload}
          delete={this.delete}
          categories={this.state.categories}
          phase={this.state.phase}
        />

        <ManageModal
          score={this.state.score}
          ref={this._modalManage}
          onUpdated={this.onUpdated}
        />
        <DeleteModal
          ref={this._modalDelete}
          id={this.state.score ? this.state.score.id : -1}
          name={this.state.score ? this.state.score.name : ""}
          onDeleted={this.deleteScore}
        />
      </React.Fragment>
    );
  }
}

export default Result;
