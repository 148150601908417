import React from "react";
import { Modal, Form, Input, Button } from "antd";
import styled from "styled-components";
import { participantService } from "../../../../services/participant.service";

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #20bf55;
    border-color: #20bf55;
  }
`;

class ManageModal extends React.Component {
  state = {
    isOpen: false,
    name: "",
    data: null,
  };
  toggleModal = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  handleCancel = () => {
    this.setState({ isOpen: false });
  };

  handleSubmit = (e) => {
    participantService
      .addSchool({
        name: this.state.name,
      })
      .then(
        (resp) => {
          debugger;
          this.toggleModal();
          this.setState({
            isLoading: false,
            data: null,
            name: "",
          });
          this.props.onUpdated(resp);
        },
        (error) => {
          this.toggleModal();
          this.setState({
            isLoading: false,
            data: null,
            name: "",
          });
          this.props.onUpdated({
            isUpdated: false,
            status: false,
            message: error,
            data: null,
          });
        }
      );
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      if (this.props.school)
        this.setState({
          isLoading: false,
          data: this.props.data,
          name: this.props.data.name,
        });
      else
        this.setState({
          isLoading: false,
          data: null,
          name: "",
        });
    }
  }
  render() {
    return (
      <Modal
        title="Tambah Sekolah"
        centered
        visible={this.state.isOpen}
        toggle={this.toggleModal}
        footer={null}
        style={{ width: "100%", resize: "none" }}
        onCancel={this.handleCancel}
      >
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
          <Form.Item
            label="Nama Sekolah"
            name="name"
            value={this.state.name}
            onChange={(e) => this.setState({ name: e.target.value })}
            rules={[
              {
                required: true,
                message: "Masukkan Nama Sekolah!",
              },
            ]}
          >
            <Input
              value={this.state.name}
              placeholder="Nama Sekolah"
              name="name"
            />
          </Form.Item>

          <ButtonContainer>
            <Button
              type="primary"
              style={{ width: "100%" }}
              onClick={this.handleSubmit}
            >
              Tambah Sekolah
            </Button>
          </ButtonContainer>
        </Form>
      </Modal>
    );
  }
}

export default ManageModal;
