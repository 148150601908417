import React from "react";
import "antd/dist/antd.css";
import { Card, Row, Col, Button } from "antd";
import { toast } from "react-toastify";
import AnnouncementTable from "./AnnouncementTable";
import DeleteModal from "../../../../components/DeleteModal";
import ManageModal from "./ManageModal";
import { announcementService } from "../../../../services/announcement.service";

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this._modalManage = React.createRef();
    this._modalDelete = React.createRef();
  }
  state = {
    reload: false,
    announcement: null,
  };
  toggleModalManage = (announcement) => {
    if (announcement) {
      this.setState({ announcement: announcement, reload: false });
    } else {
      this.setState({ announcement: null, reload: false });
    }

    this._modalManage.current.toggleModal();
  };
  onUpdated = (resp) => {
    if (resp.status) {
      this.setState({ contact: null, reload: true });
      toast.success(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
    } else
      toast.error(resp.message, {
        position: toast.POSITION.TOP_CENTER,
        pauseOnFocusLoss: false,
        autoClose: 2000,
      });
  };
  toggleModalDelete = () => {
    this._modalDelete.current.toggleModal();
  };

  delete = (announcement) => {
    this.setState({ announcement: announcement, reload: false });
    this.toggleModalDelete();
  };
  edit = (announcement) => {
    this.toggleModalManage(announcement);
  };

  deleteAnnouncement = (id) => {
    announcementService.deleteAnnouncement(id).then(
      (resp) => {
        this.toggleModalDelete();
        toast.success(resp.message, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
        });
        this.setState({ announcement: null, reload: true });
      },
      (err) => {
        this.toggleModalDelete();
        toast.error(err, {
          position: toast.POSITION.TOP_CENTER,
          pauseOnFocusLoss: false,
          autoClose: 2000,
        });
      }
    );
  };
  render() {
    return (
      <React.Fragment>
        <Card
          headStyle
          style={{
            backgroundColor: "#e8e8e8",
            minHeight: window.innerHeight,

            padding: "-5%",
            display: "fixed",
          }}
        >
          <div style={{ marginTop: 10 }}>
            <Card
              style={{
                borderRadius: "5px",
                height: "100%",
                overflow: "hidden",
                padding: "1%",
              }}
            >
              <Row gutter={[12]} style={{ marginTop: 12, marginBottom: "1%" }}>
                <Col xs={24} sm={24} md={18} lg={19}>
                  <p className="font-medium-1 text-bold-700">Pengumuman</p>
                </Col>
                <Col xs={24} sm={24} md={6} lg={5}>
                  <Button
                    type="primary"
                    onClick={(e) => this.toggleModalManage(null)}
                    style={{ width: "100%" }}
                  >
                    Tambah Pengumuman
                  </Button>
                </Col>
              </Row>
              <AnnouncementTable
                edit={this.edit}
                reload={this.state.reload}
                delete={this.delete}
              />
            </Card>
          </div>
        </Card>
        <ManageModal
          announcement={this.state.announcement}
          ref={this._modalManage}
          onUpdated={this.onUpdated}
        />
        <DeleteModal
          ref={this._modalDelete}
          id={this.state.announcement ? this.state.announcement.id : -1}
          name={this.state.announcement ? this.state.announcement.title : ""}
          onDeleted={this.deleteAnnouncement}
        />
      </React.Fragment>
    );
  }
}

export default Payment;
